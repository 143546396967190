// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
var mapFlipped = /* #__PURE__ */ Data_Functor.mapFlipped(Data_Functor.functorArray);
var renderLineEnding = /* #__PURE__ */ Halogen_HTML_Core.text("\x0a");
var classString = function (string) {
    return Halogen_HTML_Properties.classes(mapFlipped(Data_String_Common.split(" ")(string))(function (s) {
        return s;
    }));
};
var fontAwesome = function (glyphName) {
    return Halogen_HTML_Elements.i([ classString("fas " + glyphName) ])([  ]);
};
var optionalHtml = function (html) {
    return Halogen_HTML_Elements.span([ classString("largeViewport") ])([ html ]);
};
var optionalText = function (text) {
    return Halogen_HTML_Elements.span([ classString("largeViewport") ])([ Halogen_HTML_Core.text(text) ]);
};
var alternativeHtml = function (longHtml) {
    return function (shortAlt) {
        return [ Halogen_HTML_Elements.span([ classString("largeViewport") ])([ longHtml ]), Halogen_HTML_Elements.span([ classString("smallViewport") ])([ shortAlt ]) ];
    };
};
export {
    scrollBy
} from "./foreign.js";
export {
    classString,
    fontAwesome,
    optionalText,
    optionalHtml,
    alternativeHtml,
    renderLineEnding
};
