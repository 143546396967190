// Generated by purs version 0.15.7
import * as Data_Lens_Barlow_Generic from "../Data.Lens.Barlow.Generic/index.js";
import * as Data_Lens_Iso_Newtype from "../Data.Lens.Iso.Newtype/index.js";
import * as Data_Lens_Prism_Either from "../Data.Lens.Prism.Either/index.js";
import * as Data_Lens_Prism_Maybe from "../Data.Lens.Prism.Maybe/index.js";
import * as Data_Lens_Record from "../Data.Lens.Record/index.js";
import * as Data_Lens_Traversal from "../Data.Lens.Traversal/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var _Newtype = /* #__PURE__ */ Data_Lens_Iso_Newtype["_Newtype"]()();
var constructBarlowTConsRight = function (dictChoice) {
    var _Right = Data_Lens_Prism_Either["_Right"](dictChoice);
    return {
        constructBarlow: function (v) {
            return _Right;
        }
    };
};
var constructBarlowTConsRecor = function (dictIsSymbol) {
    var prop = Data_Lens_Record.prop(dictIsSymbol)()();
    return function () {
        return function () {
            return function (dictStrong) {
                return {
                    constructBarlow: function (v) {
                        return prop(Type_Proxy["Proxy"].value)(dictStrong);
                    }
                };
            };
        };
    };
};
var constructBarlowTConsQuest = function (dictChoice) {
    var _Just = Data_Lens_Prism_Maybe["_Just"](dictChoice);
    return {
        constructBarlow: function (v) {
            return _Just;
        }
    };
};
var constructBarlowTConsPlusT = function (dictWander) {
    return function (dictTraversable) {
        var traversed = Data_Lens_Traversal.traversed(dictTraversable)(dictWander);
        return {
            constructBarlow: function (v) {
                return traversed;
            }
        };
    };
};
var constructBarlowTConsLeftA = function (dictChoice) {
    var _Left = Data_Lens_Prism_Either["_Left"](dictChoice);
    return {
        constructBarlow: function (v) {
            return _Left;
        }
    };
};
var constructBarlowGenericTCo = function (dictProfunctor) {
    var _Argument = Data_Lens_Barlow_Generic["_Argument"](dictProfunctor);
    return {
        constructBarlowGeneric: function (v) {
            return _Argument;
        }
    };
};
var constructBarlowGenericTCo1 = function (dictStrong) {
    var _ProductLeft = Data_Lens_Barlow_Generic["_ProductLeft"](dictStrong);
    var _Argument = Data_Lens_Barlow_Generic["_Argument"](dictStrong.Profunctor0());
    return {
        constructBarlowGeneric: function (v) {
            return function ($198) {
                return _ProductLeft(_Argument($198));
            };
        }
    };
};
var constructBarlowGenericTCo2 = function (dictChoice) {
    var _SumRight = Data_Lens_Barlow_Generic["_SumRight"](dictChoice);
    var Profunctor0 = dictChoice.Profunctor0();
    var _Constructor = Data_Lens_Barlow_Generic["_Constructor"](Profunctor0);
    var _Argument = Data_Lens_Barlow_Generic["_Argument"](Profunctor0);
    return {
        constructBarlowGeneric: function (v) {
            return function ($199) {
                return _SumRight(_Constructor(_Argument($199)));
            };
        }
    };
};
var constructBarlowGenericTCo3 = function (dictChoice) {
    var _SumRight = Data_Lens_Barlow_Generic["_SumRight"](dictChoice);
    var _Constructor = Data_Lens_Barlow_Generic["_Constructor"](dictChoice.Profunctor0());
    return function (dictStrong) {
        var _NoArguments = Data_Lens_Barlow_Generic["_NoArguments"](dictStrong);
        return {
            constructBarlowGeneric: function (v) {
                return function ($200) {
                    return _SumRight(_Constructor(_NoArguments($200)));
                };
            }
        };
    };
};
var constructBarlowGenericTCo4 = function (dictChoice) {
    var _SumLeft = Data_Lens_Barlow_Generic["_SumLeft"](dictChoice);
    var Profunctor0 = dictChoice.Profunctor0();
    var _Constructor = Data_Lens_Barlow_Generic["_Constructor"](Profunctor0);
    var _Argument = Data_Lens_Barlow_Generic["_Argument"](Profunctor0);
    return {
        constructBarlowGeneric: function (v) {
            return function ($201) {
                return _SumLeft(_Constructor(_Argument($201)));
            };
        }
    };
};
var constructBarlowGenericTCo5 = function (dictChoice) {
    var _SumLeft = Data_Lens_Barlow_Generic["_SumLeft"](dictChoice);
    var _Constructor = Data_Lens_Barlow_Generic["_Constructor"](dictChoice.Profunctor0());
    return function (dictStrong) {
        var _NoArguments = Data_Lens_Barlow_Generic["_NoArguments"](dictStrong);
        return {
            constructBarlowGeneric: function (v) {
                return function ($202) {
                    return _SumLeft(_Constructor(_NoArguments($202)));
                };
            }
        };
    };
};
var constructBarlowGenericTCo6 = function (dictProfunctor) {
    var _Constructor = Data_Lens_Barlow_Generic["_Constructor"](dictProfunctor);
    var _Argument = Data_Lens_Barlow_Generic["_Argument"](dictProfunctor);
    return {
        constructBarlowGeneric: function (v) {
            return function ($203) {
                return _Constructor(_Argument($203));
            };
        }
    };
};
var constructBarlowGenericTCo7 = function (dictStrong) {
    var _Constructor = Data_Lens_Barlow_Generic["_Constructor"](dictStrong.Profunctor0());
    var _NoArguments = Data_Lens_Barlow_Generic["_NoArguments"](dictStrong);
    return {
        constructBarlowGeneric: function (v) {
            return function ($204) {
                return _Constructor(_NoArguments($204));
            };
        }
    };
};
var constructBarlowNilExclamationMark = function (dictProfunctor) {
    var _Newtype1 = _Newtype(dictProfunctor);
    return function () {
        return {
            constructBarlow: function (v) {
                return _Newtype1;
            }
        };
    };
};
var constructBarlowGeneric = function (dict) {
    return dict.constructBarlowGeneric;
};
var constructBarlow1 = function (dictGeneric) {
    var _ToGeneric = Data_Lens_Barlow_Generic["_ToGeneric"](dictGeneric);
    return function (dictConstructBarlowGeneric) {
        var constructBarlowGeneric1 = constructBarlowGeneric(dictConstructBarlowGeneric);
        return function (dictProfunctor) {
            var _ToGeneric1 = _ToGeneric(dictProfunctor);
            return {
                constructBarlow: function (v) {
                    var $205 = constructBarlowGeneric1(Type_Proxy["Proxy"].value);
                    return function ($206) {
                        return _ToGeneric1($205($206));
                    };
                }
            };
        };
    };
};
var constructBarlowGenericTCo8 = function (dictConstructBarlowGeneric) {
    var constructBarlowGeneric1 = constructBarlowGeneric(dictConstructBarlowGeneric);
    return function (dictProfunctor) {
        var _Constructor = Data_Lens_Barlow_Generic["_Constructor"](dictProfunctor);
        return {
            constructBarlowGeneric: function (v) {
                var $207 = constructBarlowGeneric1(Type_Proxy["Proxy"].value);
                return function ($208) {
                    return _Constructor($207($208));
                };
            }
        };
    };
};
var constructBarlowGenericTCo9 = function (dictConstructBarlowGeneric) {
    var constructBarlowGeneric1 = constructBarlowGeneric(dictConstructBarlowGeneric);
    return function (dictChoice) {
        var _SumLeft = Data_Lens_Barlow_Generic["_SumLeft"](dictChoice);
        var _Constructor = Data_Lens_Barlow_Generic["_Constructor"](dictChoice.Profunctor0());
        return {
            constructBarlowGeneric: function (v) {
                var $209 = constructBarlowGeneric1(Type_Proxy["Proxy"].value);
                return function ($210) {
                    return _SumLeft(_Constructor($209($210)));
                };
            }
        };
    };
};
var constructBarlowGenericTCo10 = function (dictConstructBarlowGeneric) {
    var constructBarlowGeneric1 = constructBarlowGeneric(dictConstructBarlowGeneric);
    return function (dictChoice) {
        var _SumRight = Data_Lens_Barlow_Generic["_SumRight"](dictChoice);
        return {
            constructBarlowGeneric: function (v) {
                var $211 = constructBarlowGeneric1(Type_Proxy["Proxy"].value);
                return function ($212) {
                    return _SumRight($211($212));
                };
            }
        };
    };
};
var constructBarlowGenericTCo11 = function (dictConstructBarlowGeneric) {
    var constructBarlowGeneric1 = constructBarlowGeneric(dictConstructBarlowGeneric);
    return function (dictStrong) {
        var _ProductRight = Data_Lens_Barlow_Generic["_ProductRight"](dictStrong);
        return {
            constructBarlowGeneric: function (v) {
                var $213 = constructBarlowGeneric1(Type_Proxy["Proxy"].value);
                return function ($214) {
                    return _ProductRight($213($214));
                };
            }
        };
    };
};
var constructBarlow = function (dict) {
    return dict.constructBarlow;
};
var constructBarlowGenericTCo12 = function (dictConstructBarlow) {
    var constructBarlow2 = constructBarlow(dictConstructBarlow);
    return function (dictProfunctor) {
        var _Constructor = Data_Lens_Barlow_Generic["_Constructor"](dictProfunctor);
        var _Argument = Data_Lens_Barlow_Generic["_Argument"](dictProfunctor);
        return {
            constructBarlowGeneric: function (v) {
                var $215 = constructBarlow2(Type_Proxy["Proxy"].value);
                return function ($216) {
                    return _Constructor(_Argument($215($216)));
                };
            }
        };
    };
};
var constructBarlowGenericTCo13 = function (dictConstructBarlow) {
    var constructBarlow2 = constructBarlow(dictConstructBarlow);
    return function (dictChoice) {
        var _SumLeft = Data_Lens_Barlow_Generic["_SumLeft"](dictChoice);
        var Profunctor0 = dictChoice.Profunctor0();
        var _Constructor = Data_Lens_Barlow_Generic["_Constructor"](Profunctor0);
        var _Argument = Data_Lens_Barlow_Generic["_Argument"](Profunctor0);
        return {
            constructBarlowGeneric: function (v) {
                var $217 = constructBarlow2(Type_Proxy["Proxy"].value);
                return function ($218) {
                    return _SumLeft(_Constructor(_Argument($217($218))));
                };
            }
        };
    };
};
var constructBarlowGenericTCo14 = function (dictConstructBarlow) {
    var constructBarlow2 = constructBarlow(dictConstructBarlow);
    return function (dictChoice) {
        var _SumRight = Data_Lens_Barlow_Generic["_SumRight"](dictChoice);
        var Profunctor0 = dictChoice.Profunctor0();
        var _Constructor = Data_Lens_Barlow_Generic["_Constructor"](Profunctor0);
        var _Argument = Data_Lens_Barlow_Generic["_Argument"](Profunctor0);
        return {
            constructBarlowGeneric: function (v) {
                var $219 = constructBarlow2(Type_Proxy["Proxy"].value);
                return function ($220) {
                    return _SumRight(_Constructor(_Argument($219($220))));
                };
            }
        };
    };
};
var constructBarlowGenericTCo15 = function (dictConstructBarlow) {
    var constructBarlow2 = constructBarlow(dictConstructBarlow);
    return function (dictStrong) {
        var _ProductLeft = Data_Lens_Barlow_Generic["_ProductLeft"](dictStrong);
        var _Argument = Data_Lens_Barlow_Generic["_Argument"](dictStrong.Profunctor0());
        return {
            constructBarlowGeneric: function (v) {
                var $221 = constructBarlow2(Type_Proxy["Proxy"].value);
                return function ($222) {
                    return _ProductLeft(_Argument($221($222)));
                };
            }
        };
    };
};
var constructBarlowGenericTCo16 = function (dictConstructBarlow) {
    var constructBarlow2 = constructBarlow(dictConstructBarlow);
    return function (dictProfunctor) {
        var _Argument = Data_Lens_Barlow_Generic["_Argument"](dictProfunctor);
        return {
            constructBarlowGeneric: function (v) {
                var $223 = constructBarlow2(Type_Proxy["Proxy"].value);
                return function ($224) {
                    return _Argument($223($224));
                };
            }
        };
    };
};
var constructBarlowTConsExcla = function (dictConstructBarlow) {
    var constructBarlow2 = constructBarlow(dictConstructBarlow);
    return function () {
        return function (dictProfunctor) {
            var _Newtype1 = _Newtype(dictProfunctor);
            return {
                constructBarlow: function (v) {
                    var $225 = constructBarlow2(Type_Proxy["Proxy"].value);
                    return function ($226) {
                        return _Newtype1($225($226));
                    };
                }
            };
        };
    };
};
var constructBarlowTConsLeftA1 = function (dictConstructBarlow) {
    var constructBarlow2 = constructBarlow(dictConstructBarlow);
    return function (dictChoice) {
        var _Left = Data_Lens_Prism_Either["_Left"](dictChoice);
        return {
            constructBarlow: function (v) {
                var $227 = constructBarlow2(Type_Proxy["Proxy"].value);
                return function ($228) {
                    return _Left($227($228));
                };
            }
        };
    };
};
var constructBarlowTConsPlus = function (dictConstructBarlow) {
    var constructBarlow2 = constructBarlow(dictConstructBarlow);
    return function (dictWander) {
        return function (dictTraversable) {
            var traversed = Data_Lens_Traversal.traversed(dictTraversable)(dictWander);
            return {
                constructBarlow: function (v) {
                    var $229 = constructBarlow2(Type_Proxy["Proxy"].value);
                    return function ($230) {
                        return traversed($229($230));
                    };
                }
            };
        };
    };
};
var constructBarlowTConsQuest1 = function (dictConstructBarlow) {
    var constructBarlow2 = constructBarlow(dictConstructBarlow);
    return function (dictChoice) {
        var _Just = Data_Lens_Prism_Maybe["_Just"](dictChoice);
        return {
            constructBarlow: function (v) {
                var $231 = constructBarlow2(Type_Proxy["Proxy"].value);
                return function ($232) {
                    return _Just($231($232));
                };
            }
        };
    };
};
var constructBarlowTConsRecor1 = function (dictIsSymbol) {
    var prop = Data_Lens_Record.prop(dictIsSymbol)()();
    return function (dictConstructBarlow) {
        var constructBarlow2 = constructBarlow(dictConstructBarlow);
        return function () {
            return function () {
                return function (dictStrong) {
                    return {
                        constructBarlow: function (v) {
                            var $233 = prop(Type_Proxy["Proxy"].value)(dictStrong);
                            var $234 = constructBarlow2(Type_Proxy["Proxy"].value);
                            return function ($235) {
                                return $233($234($235));
                            };
                        }
                    };
                };
            };
        };
    };
};
var constructBarlowTConsRight1 = function (dictConstructBarlow) {
    var constructBarlow2 = constructBarlow(dictConstructBarlow);
    return function (dictChoice) {
        var _Right = Data_Lens_Prism_Either["_Right"](dictChoice);
        return {
            constructBarlow: function (v) {
                var $236 = constructBarlow2(Type_Proxy["Proxy"].value);
                return function ($237) {
                    return _Right($236($237));
                };
            }
        };
    };
};
export {
    constructBarlow,
    constructBarlowGeneric,
    constructBarlowGenericTCo7,
    constructBarlowGenericTCo6,
    constructBarlowGenericTCo12,
    constructBarlowGenericTCo8,
    constructBarlowGenericTCo5,
    constructBarlowGenericTCo4,
    constructBarlowGenericTCo13,
    constructBarlowGenericTCo9,
    constructBarlowGenericTCo3,
    constructBarlowGenericTCo2,
    constructBarlowGenericTCo14,
    constructBarlowGenericTCo10,
    constructBarlowGenericTCo1,
    constructBarlowGenericTCo15,
    constructBarlowGenericTCo,
    constructBarlowGenericTCo16,
    constructBarlowGenericTCo11,
    constructBarlowTConsQuest,
    constructBarlowTConsRight,
    constructBarlowTConsLeftA,
    constructBarlowTConsPlusT,
    constructBarlowNilExclamationMark,
    constructBarlowTConsRecor,
    constructBarlowTConsQuest1,
    constructBarlowTConsRight1,
    constructBarlowTConsLeftA1,
    constructBarlowTConsPlus,
    constructBarlowTConsExcla,
    constructBarlowTConsRecor1,
    constructBarlow1
};
