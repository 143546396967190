// Generated by purs version 0.15.7
import * as Data_Lens_Barlow_Construction from "../Data.Lens.Barlow.Construction/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var key = /* #__PURE__ */ (function () {
    return Type_Proxy["Proxy"].value;
})();
var barlowInstance = function () {
    return function (dictConstructBarlow) {
        var constructBarlow = Data_Lens_Barlow_Construction.constructBarlow(dictConstructBarlow);
        return {
            barlow: function (v) {
                return constructBarlow(Type_Proxy["Proxy"].value);
            }
        };
    };
};
var barlow = function (dict) {
    return dict.barlow;
};
export {
    barlow,
    key,
    barlowInstance
};
