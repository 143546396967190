// Generated by purs version 0.15.7
import * as Data_Bounded_Generic from "../Data.Bounded.Generic/index.js";
import * as Data_Enum from "../Data.Enum/index.js";
import * as Data_Enum_Generic from "../Data.Enum.Generic/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Lens_Barlow from "../Data.Lens.Barlow/index.js";
import * as Data_Lens_Barlow_Construction from "../Data.Lens.Barlow.Construction/index.js";
import * as Data_Lens_Prism from "../Data.Lens.Prism/index.js";
import * as Data_List from "../Data.List/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Show_Generic from "../Data.Show.Generic/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_String_Utils from "../Data.String.Utils/index.js";
import * as Utils from "../Utils/index.js";
var append = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupString);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var genericShowConstructor = /* #__PURE__ */ Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments);
var foldr = /* #__PURE__ */ Data_Foldable.foldr(Data_List_Types.foldableList);
var map = /* #__PURE__ */ Data_Functor.map(Data_List_Types.functorList);
var mapFlipped = /* #__PURE__ */ Data_Functor.mapFlipped(Data_Maybe.functorMaybe);
var compare = /* #__PURE__ */ Data_Ord.compare(Data_Ord.ordInt);
var genericEnumConstructor = /* #__PURE__ */ Data_Enum_Generic.genericEnumConstructor(Data_Enum_Generic.genericEnumNoArguments);
var genericEnumSum = /* #__PURE__ */ Data_Enum_Generic.genericEnumSum(genericEnumConstructor)(/* #__PURE__ */ Data_Bounded_Generic.genericTopConstructor(Data_Bounded_Generic.genericTopNoArguments));
var genericBottomConstructor = /* #__PURE__ */ Data_Bounded_Generic.genericBottomConstructor(Data_Bounded_Generic.genericBottomNoArguments);
var genericBottomSum = /* #__PURE__ */ Data_Bounded_Generic.genericBottomSum(genericBottomConstructor);
var genericEnumSum1 = /* #__PURE__ */ genericEnumSum(/* #__PURE__ */ genericEnumSum(/* #__PURE__ */ genericEnumSum(/* #__PURE__ */ genericEnumSum(/* #__PURE__ */ genericEnumSum(/* #__PURE__ */ genericEnumSum(genericEnumConstructor)(genericBottomConstructor))(genericBottomSum))(genericBottomSum))(genericBottomSum))(genericBottomSum))(genericBottomSum);
var fromJust = /* #__PURE__ */ Data_Maybe.fromJust();
var barlowInstance = /* #__PURE__ */ Data_Lens_Barlow.barlowInstance();
var constructBarlowTConsRecor = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "type";
    }
})()();
var constructBarlowTConsRecor1 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "spaceSuffix";
    }
})()();
var constructBarlowTConsRecor2 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "root";
    }
})()();
var letterIsSymbol = {
    reflectSymbol: function () {
        return "letter";
    }
};
var constructBarlowTConsRecor11 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor1(letterIsSymbol);
var constructBarlowTConsRecor3 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "primitive";
    }
})()();
var constructBarlowTConsRecor4 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "mods";
    }
})()();
var constructBarlowTConsRecor5 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "mod";
    }
})()();
var constructBarlowTConsRecor6 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "lowercase";
    }
})()();
var constructBarlowTConsRecor7 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor(letterIsSymbol)()();
var constructBarlowTConsRecor8 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "elem";
    }
})()();
var constructBarlowTConsRecor9 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "bass";
    }
})()();
var Transposition = function (x) {
    return x;
};
var Title = /* #__PURE__ */ (function () {
    function Title(value0) {
        this.value0 = value0;
    };
    Title.create = function (value0) {
        return new Title(value0);
    };
    return Title;
})();
var TitleOther = /* #__PURE__ */ (function () {
    function TitleOther(value0) {
        this.value0 = value0;
    };
    TitleOther.create = function (value0) {
        return new TitleOther(value0);
    };
    return TitleOther;
})();
var Spaced = function (x) {
    return x;
};
var A = /* #__PURE__ */ (function () {
    function A() {

    };
    A.value = new A();
    return A;
})();
var B = /* #__PURE__ */ (function () {
    function B() {

    };
    B.value = new B();
    return B;
})();
var C = /* #__PURE__ */ (function () {
    function C() {

    };
    C.value = new C();
    return C;
})();
var D = /* #__PURE__ */ (function () {
    function D() {

    };
    D.value = new D();
    return D;
})();
var E = /* #__PURE__ */ (function () {
    function E() {

    };
    E.value = new E();
    return E;
})();
var F = /* #__PURE__ */ (function () {
    function F() {

    };
    F.value = new F();
    return F;
})();
var G = /* #__PURE__ */ (function () {
    function G() {

    };
    G.value = new G();
    return G;
})();
var NoteLetter = function (x) {
    return x;
};
var Note = function (x) {
    return x;
};
var Prefix = /* #__PURE__ */ (function () {
    function Prefix(value0) {
        this.value0 = value0;
    };
    Prefix.create = function (value0) {
        return new Prefix(value0);
    };
    return Prefix;
})();
var Tuning = /* #__PURE__ */ (function () {
    function Tuning(value0) {
        this.value0 = value0;
    };
    Tuning.create = function (value0) {
        return new Tuning(value0);
    };
    return Tuning;
})();
var TimelineConnection = /* #__PURE__ */ (function () {
    function TimelineConnection(value0) {
        this.value0 = value0;
    };
    TimelineConnection.create = function (value0) {
        return new TimelineConnection(value0);
    };
    return TimelineConnection;
})();
var TimelineSep = /* #__PURE__ */ (function () {
    function TimelineSep(value0) {
        this.value0 = value0;
    };
    TimelineSep.create = function (value0) {
        return new TimelineSep(value0);
    };
    return TimelineSep;
})();
var TimelineSpace = /* #__PURE__ */ (function () {
    function TimelineSpace(value0) {
        this.value0 = value0;
    };
    TimelineSpace.create = function (value0) {
        return new TimelineSpace(value0);
    };
    return TimelineSpace;
})();
var Fret = /* #__PURE__ */ (function () {
    function Fret(value0) {
        this.value0 = value0;
    };
    Fret.create = function (value0) {
        return new Fret(value0);
    };
    return Fret;
})();
var Special = /* #__PURE__ */ (function () {
    function Special(value0) {
        this.value0 = value0;
    };
    Special.create = function (value0) {
        return new Special(value0);
    };
    return Special;
})();
var Suffix = /* #__PURE__ */ (function () {
    function Suffix(value0) {
        this.value0 = value0;
    };
    Suffix.create = function (value0) {
        return new Suffix(value0);
    };
    return Suffix;
})();
var Header = /* #__PURE__ */ (function () {
    function Header(value0) {
        this.value0 = value0;
    };
    Header.create = function (value0) {
        return new Header(value0);
    };
    return Header;
})();
var HeaderSuffix = /* #__PURE__ */ (function () {
    function HeaderSuffix(value0) {
        this.value0 = value0;
    };
    HeaderSuffix.create = function (value0) {
        return new HeaderSuffix(value0);
    };
    return HeaderSuffix;
})();
var ChordMod = function (x) {
    return x;
};
var ChordFret = /* #__PURE__ */ (function () {
    function ChordFret(value0) {
        this.value0 = value0;
    };
    ChordFret.create = function (value0) {
        return new ChordFret(value0);
    };
    return ChordFret;
})();
var ChordSpecial = /* #__PURE__ */ (function () {
    function ChordSpecial(value0) {
        this.value0 = value0;
    };
    ChordSpecial.create = function (value0) {
        return new ChordSpecial(value0);
    };
    return ChordSpecial;
})();
var Chord = function (x) {
    return x;
};
var ChordLineChord = /* #__PURE__ */ (function () {
    function ChordLineChord(value0) {
        this.value0 = value0;
    };
    ChordLineChord.create = function (value0) {
        return new ChordLineChord(value0);
    };
    return ChordLineChord;
})();
var ChordComment = /* #__PURE__ */ (function () {
    function ChordComment(value0) {
        this.value0 = value0;
    };
    ChordComment.create = function (value0) {
        return new ChordComment(value0);
    };
    return ChordComment;
})();
var Text = /* #__PURE__ */ (function () {
    function Text(value0) {
        this.value0 = value0;
    };
    Text.create = function (value0) {
        return new Text(value0);
    };
    return Text;
})();
var Spaces = /* #__PURE__ */ (function () {
    function Spaces(value0) {
        this.value0 = value0;
    };
    Spaces.create = function (value0) {
        return new Spaces(value0);
    };
    return Spaces;
})();
var TextLineChord = /* #__PURE__ */ (function () {
    function TextLineChord(value0) {
        this.value0 = value0;
    };
    TextLineChord.create = function (value0) {
        return new TextLineChord(value0);
    };
    return TextLineChord;
})();
var ChordLegend = /* #__PURE__ */ (function () {
    function ChordLegend(value0) {
        this.value0 = value0;
    };
    ChordLegend.create = function (value0) {
        return new ChordLegend(value0);
    };
    return ChordLegend;
})();
var TitleLine = /* #__PURE__ */ (function () {
    function TitleLine(value0) {
        this.value0 = value0;
    };
    TitleLine.create = function (value0) {
        return new TitleLine(value0);
    };
    return TitleLine;
})();
var HeaderLine = /* #__PURE__ */ (function () {
    function HeaderLine(value0) {
        this.value0 = value0;
    };
    HeaderLine.create = function (value0) {
        return new HeaderLine(value0);
    };
    return HeaderLine;
})();
var TablatureLine = /* #__PURE__ */ (function () {
    function TablatureLine(value0) {
        this.value0 = value0;
    };
    TablatureLine.create = function (value0) {
        return new TablatureLine(value0);
    };
    return TablatureLine;
})();
var ChordLine = /* #__PURE__ */ (function () {
    function ChordLine(value0) {
        this.value0 = value0;
    };
    ChordLine.create = function (value0) {
        return new ChordLine(value0);
    };
    return ChordLine;
})();
var TextLine = /* #__PURE__ */ (function () {
    function TextLine(value0) {
        this.value0 = value0;
    };
    TextLine.create = function (value0) {
        return new TextLine(value0);
    };
    return TextLine;
})();
var showTransposition = {
    show: function (v) {
        var $380 = v >= 0;
        if ($380) {
            return "+" + show(v);
        };
        return show(v);
    }
};
var showTitleLineElem = {
    show: function (v) {
        if (v instanceof Title) {
            return v.value0;
        };
        if (v instanceof TitleOther) {
            return v.value0;
        };
        throw new Error("Failed pattern match at TablatureDocument (line 301, column 1 - line 303, column 36): " + [ v.constructor.name ]);
    }
};
var show1 = /* #__PURE__ */ Data_Show.show(/* #__PURE__ */ Data_List_Types.showList(showTitleLineElem));
var showHeaderLineElem = {
    show: function (v) {
        if (v instanceof Header) {
            return v.value0;
        };
        if (v instanceof HeaderSuffix) {
            return v.value0;
        };
        throw new Error("Failed pattern match at TablatureDocument (line 297, column 1 - line 299, column 38): " + [ v.constructor.name ]);
    }
};
var show2 = /* #__PURE__ */ Data_Show.show(/* #__PURE__ */ Data_List_Types.showList(showHeaderLineElem));
var showChordMod = {
    show: function (v) {
        return v.pre + (v.interval + v.post);
    }
};
var printSpaced = function (dictPrint) {
    var print5 = Utils.print(dictPrint);
    return {
        print: function (v) {
            return print5(v.elem) + Data_Maybe.fromMaybe("")(Data_String_Utils.repeat(v.spaceSuffix)(" "));
        }
    };
};
var printChordMod = {
    print: function (v) {
        return v.pre + (v.interval + v.post);
    }
};
var newtypeSpaced_ = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeNote_ = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeNoteLetter_ = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeChord_ = {
    Coercible0: function () {
        return undefined;
    }
};
var genericTitleLineElem_ = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new Title(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return new TitleOther(x.value0);
        };
        throw new Error("Failed pattern match at TablatureDocument (line 70, column 1 - line 70, column 40): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Title) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof TitleOther) {
            return new Data_Generic_Rep.Inr(x.value0);
        };
        throw new Error("Failed pattern match at TablatureDocument (line 70, column 1 - line 70, column 40): " + [ x.constructor.name ]);
    }
};
var genericTablatureDocumentL = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new TitleLine(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new HeaderLine(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new TablatureLine(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new ChordLine(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
            return new TextLine(x.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at TablatureDocument (line 29, column 1 - line 29, column 48): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof TitleLine) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof HeaderLine) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof TablatureLine) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof ChordLine) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof TextLine) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0))));
        };
        throw new Error("Failed pattern match at TablatureDocument (line 29, column 1 - line 29, column 48): " + [ x.constructor.name ]);
    }
};
var constructBarlow1 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlow1(genericTablatureDocumentL);
var genericNoteLetterPrimitiv = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return A.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return B.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return C.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return D.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return E.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return F.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr))))) {
            return G.value;
        };
        throw new Error("Failed pattern match at TablatureDocument (line 209, column 1 - line 209, column 46): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof A) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof B) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof C) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof D) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
        };
        if (x instanceof E) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
        };
        if (x instanceof F) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))));
        };
        if (x instanceof G) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))))));
        };
        throw new Error("Failed pattern match at TablatureDocument (line 209, column 1 - line 209, column 46): " + [ x.constructor.name ]);
    }
};
var genericSucc = /* #__PURE__ */ Data_Enum_Generic.genericSucc(genericNoteLetterPrimitiv)(genericEnumSum1);
var genericPred = /* #__PURE__ */ Data_Enum_Generic.genericPred(genericNoteLetterPrimitiv)(genericEnumSum1);
var printNoteLetterPrimitive = {
    print: /* #__PURE__ */ Data_Show_Generic.genericShow(genericNoteLetterPrimitiv)(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "A";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "B";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "C";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "D";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "E";
        }
    }))(/* #__PURE__ */ Data_Show_Generic.genericShowSum(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "F";
        }
    }))(/* #__PURE__ */ genericShowConstructor({
        reflectSymbol: function () {
            return "G";
        }
    }))))))))
};
var print = /* #__PURE__ */ Utils.print(printNoteLetterPrimitive);
var printNoteLetter = {
    print: function (v) {
        var uppercase = print(v.primitive);
        if (v.lowercase) {
            return Data_String_Common.toLower(uppercase);
        };
        return uppercase;
    }
};
var print1 = /* #__PURE__ */ Utils.print(printNoteLetter);
var printNote = {
    print: function (v) {
        return print1(v.letter) + v.mod;
    }
};
var print2 = /* #__PURE__ */ Utils.print(printNote);
var print3 = /* #__PURE__ */ Utils.print(/* #__PURE__ */ printSpaced(printNote));
var printChord = {
    print: function (v) {
        return print2(v.root) + (v.type + (foldr(append)("")(map(function (v1) {
            return v1.pre + (v1.interval + v1.post);
        })(v.mods)) + Data_Maybe.fromMaybe("")(mapFlipped(v.bass)(print2))));
    }
};
var print4 = /* #__PURE__ */ Utils.print(/* #__PURE__ */ printSpaced(printChord));
var showChordLineElem = {
    show: function (v) {
        if (v instanceof ChordLineChord) {
            return print4(v.value0);
        };
        if (v instanceof ChordComment) {
            return v.value0;
        };
        throw new Error("Failed pattern match at TablatureDocument (line 290, column 1 - line 292, column 38): " + [ v.constructor.name ]);
    }
};
var show3 = /* #__PURE__ */ Data_Show.show(/* #__PURE__ */ Data_List_Types.showList(showChordLineElem));
var showTextLineElem = {
    show: function (v) {
        if (v instanceof Text) {
            return v.value0;
        };
        if (v instanceof Spaces) {
            return v.value0;
        };
        if (v instanceof TextLineChord) {
            return print4(v.value0);
        };
        if (v instanceof ChordLegend) {
            return "legend";
        };
        throw new Error("Failed pattern match at TablatureDocument (line 284, column 1 - line 288, column 34): " + [ v.constructor.name ]);
    }
};
var show4 = /* #__PURE__ */ Data_Show.show(/* #__PURE__ */ Data_List_Types.showList(showTextLineElem));
var printTablatureLineElem = {
    print: function (v) {
        if (v instanceof Prefix) {
            return v.value0;
        };
        if (v instanceof Tuning) {
            return print3(v.value0);
        };
        if (v instanceof TimelineSep) {
            return v.value0;
        };
        if (v instanceof TimelineConnection) {
            return v.value0;
        };
        if (v instanceof TimelineSpace) {
            return v.value0;
        };
        if (v instanceof Fret) {
            return v.value0;
        };
        if (v instanceof Special) {
            return v.value0;
        };
        if (v instanceof Suffix) {
            return v.value0;
        };
        throw new Error("Failed pattern match at TablatureDocument (line 274, column 1 - line 282, column 33): " + [ v.constructor.name ]);
    }
};
var showTablatureLineElem = {
    show: /* #__PURE__ */ Utils.print(printTablatureLineElem)
};
var show5 = /* #__PURE__ */ Data_Show.show(/* #__PURE__ */ Data_List_Types.showList(showTablatureLineElem));
var showTablatureDocumentLine = {
    show: function (v) {
        if (v instanceof TitleLine) {
            return "Title: " + show1(v.value0);
        };
        if (v instanceof TablatureLine) {
            return "Tab: " + show5(v.value0);
        };
        if (v instanceof TextLine) {
            return "Text: " + show4(v.value0);
        };
        if (v instanceof ChordLine) {
            return "Chords: " + show3(v.value0);
        };
        if (v instanceof HeaderLine) {
            return "Header: " + show2(v.value0);
        };
        throw new Error("Failed pattern match at TablatureDocument (line 264, column 1 - line 269, column 53): " + [ v.constructor.name ]);
    }
};
var eqTransposition = {
    eq: function (v) {
        return function (v1) {
            return v === v1;
        };
    }
};
var ordTransposition = {
    compare: function (v) {
        return function (v1) {
            return compare(v)(v1);
        };
    },
    Eq0: function () {
        return eqTransposition;
    }
};
var eqNoteLetterPrimitive = {
    eq: function (x) {
        return function (y) {
            if (x instanceof A && y instanceof A) {
                return true;
            };
            if (x instanceof B && y instanceof B) {
                return true;
            };
            if (x instanceof C && y instanceof C) {
                return true;
            };
            if (x instanceof D && y instanceof D) {
                return true;
            };
            if (x instanceof E && y instanceof E) {
                return true;
            };
            if (x instanceof F && y instanceof F) {
                return true;
            };
            if (x instanceof G && y instanceof G) {
                return true;
            };
            return false;
        };
    }
};
var eq3 = /* #__PURE__ */ Data_Eq.eq(eqNoteLetterPrimitive);
var ordNoteLetterPrimitive = {
    compare: function (x) {
        return function (y) {
            if (x instanceof A && y instanceof A) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof A) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof A) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof B && y instanceof B) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof B) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof B) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof C && y instanceof C) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof C) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof C) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof D && y instanceof D) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof D) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof D) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof E && y instanceof E) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof E) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof E) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof F && y instanceof F) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof F) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof F) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof G && y instanceof G) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at TablatureDocument (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqNoteLetterPrimitive;
    }
};
var compare1 = /* #__PURE__ */ Data_Ord.compare(ordNoteLetterPrimitive);
var eqNoteLetter = {
    eq: function (x) {
        return function (y) {
            return x.lowercase === y.lowercase && eq3(x.primitive)(y.primitive);
        };
    }
};
var eq4 = /* #__PURE__ */ Data_Eq.eq(eqNoteLetter);
var ordNoteLetter = {
    compare: function (v) {
        return function (v1) {
            return compare1(v.primitive)(v1.primitive);
        };
    },
    Eq0: function () {
        return eqNoteLetter;
    }
};
var eqNote = {
    eq: function (x) {
        return function (y) {
            return eq4(x.letter)(y.letter) && x.mod === y.mod;
        };
    }
};
var eq5 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_Maybe.eqMaybe(eqNote));
var eq6 = /* #__PURE__ */ Data_Eq.eq(eqNote);
var eqChordMod = {
    eq: function (x) {
        return function (y) {
            return x.interval === y.interval && x.post === y.post && x.pre === y.pre;
        };
    }
};
var eq7 = /* #__PURE__ */ Data_Eq.eq(/* #__PURE__ */ Data_List_Types.eqList(eqChordMod));
var eqChord = {
    eq: function (x) {
        return function (y) {
            return eq5(x.bass)(y.bass) && eq7(x.mods)(y.mods) && eq6(x.root)(y.root) && x.type === y.type;
        };
    }
};
var enumNoteLetterPrimitive = {
    succ: function (v) {
        if (v instanceof G) {
            return new Data_Maybe.Just(A.value);
        };
        return genericSucc(v);
    },
    pred: function (v) {
        if (v instanceof A) {
            return new Data_Maybe.Just(G.value);
        };
        return genericPred(v);
    },
    Ord0: function () {
        return ordNoteLetterPrimitive;
    }
};
var succ = /* #__PURE__ */ Data_Enum.succ(enumNoteLetterPrimitive);
var pred = /* #__PURE__ */ Data_Enum.pred(enumNoteLetterPrimitive);
var cyclicEnumNoteLetterPrimi = {
    "succ'": function (x) {
        return fromJust(succ(x));
    },
    "pred'": function (x) {
        return fromJust(pred(x));
    },
    Enum0: function () {
        return enumNoteLetterPrimitive;
    }
};
var succTransposition = function (v) {
    return v + 1 | 0;
};
var predTransposition = function (v) {
    return v - 1 | 0;
};
var identityTransposition = 0;
var getTitle = function (tablatureDocument) {
    var isTitleLine = function (v) {
        if (v instanceof TitleLine) {
            return true;
        };
        return false;
    };
    var isTitle = function (v) {
        if (v instanceof Title) {
            return true;
        };
        return false;
    };
    var findElement = function (p) {
        return function (l) {
            var v = Data_List.findIndex(p)(l);
            if (v instanceof Data_Maybe.Nothing) {
                return Data_Maybe.Nothing.value;
            };
            if (v instanceof Data_Maybe.Just) {
                var v1 = Data_List.index(l)(v.value0);
                if (v1 instanceof Data_Maybe.Nothing) {
                    return Data_Maybe.Nothing.value;
                };
                if (v1 instanceof Data_Maybe.Just) {
                    return new Data_Maybe.Just(v1.value0);
                };
                throw new Error("Failed pattern match at TablatureDocument (line 50, column 21 - line 52, column 31): " + [ v1.constructor.name ]);
            };
            throw new Error("Failed pattern match at TablatureDocument (line 48, column 5 - line 52, column 31): " + [ v.constructor.name ]);
        };
    };
    var v = findElement(isTitleLine)(tablatureDocument);
    if (v instanceof Data_Maybe.Just && v.value0 instanceof TitleLine) {
        var v1 = findElement(isTitle)(v.value0.value0);
        if (v1 instanceof Data_Maybe.Just && v1.value0 instanceof Title) {
            return new Data_Maybe.Just(v1.value0.value0);
        };
        return Data_Maybe.Nothing.value;
    };
    return Data_Maybe.Nothing.value;
};
var fromString = function (v) {
    if (v === "A") {
        return new Data_Maybe.Just(A.value);
    };
    if (v === "B") {
        return new Data_Maybe.Just(B.value);
    };
    if (v === "C") {
        return new Data_Maybe.Just(C.value);
    };
    if (v === "D") {
        return new Data_Maybe.Just(D.value);
    };
    if (v === "E") {
        return new Data_Maybe.Just(E.value);
    };
    if (v === "F") {
        return new Data_Maybe.Just(F.value);
    };
    if (v === "G") {
        return new Data_Maybe.Just(G.value);
    };
    return Data_Maybe.Nothing.value;
};
var _type = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var _spaceSuffix = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor1(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var _root = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor2(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var _primitive = function (dictStrong) {
    var Profunctor0 = dictStrong.Profunctor0();
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor11(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor3(dictStrong))()(Profunctor0))()()(dictStrong))()(Profunctor0)))(Data_Lens_Barlow.key);
};
var _mods = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor4(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var _mod = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor5(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var _lowercase = function (dictStrong) {
    var Profunctor0 = dictStrong.Profunctor0();
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor11(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor6(dictStrong))()(Profunctor0))()()(dictStrong))()(Profunctor0)))(Data_Lens_Barlow.key);
};
var _letter = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor7(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var _elem = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor8(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var _bass = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor9(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var _Tuning = function (dictChoice) {
    return Data_Lens_Prism["prism$prime"](Tuning.create)(function (v) {
        if (v instanceof Tuning) {
            return new Data_Maybe.Just(v.value0);
        };
        return Data_Maybe.Nothing.value;
    })(dictChoice);
};
var _TitleLine = function (dictChoice) {
    return Data_Lens_Barlow.barlow(barlowInstance(constructBarlow1(Data_Lens_Barlow_Construction.constructBarlowGenericTCo4(dictChoice))(dictChoice.Profunctor0())))(Data_Lens_Barlow.key);
};
var _Title = function (dictConstructBarlow) {
    return Data_Lens_Barlow.barlow(barlowInstance(dictConstructBarlow))(Data_Lens_Barlow.key);
};
var _TextLineChord = function (dictChoice) {
    return Data_Lens_Prism["prism$prime"](TextLineChord.create)(function (v) {
        if (v instanceof TextLineChord) {
            return new Data_Maybe.Just(v.value0);
        };
        return Data_Maybe.Nothing.value;
    })(dictChoice);
};
var _TextLine = function (dictChoice) {
    return Data_Lens_Barlow.barlow(barlowInstance(constructBarlow1(Data_Lens_Barlow_Construction.constructBarlowGenericTCo10(Data_Lens_Barlow_Construction.constructBarlowGenericTCo10(Data_Lens_Barlow_Construction.constructBarlowGenericTCo10(Data_Lens_Barlow_Construction.constructBarlowGenericTCo2(dictChoice))(dictChoice))(dictChoice))(dictChoice))(dictChoice.Profunctor0())))(Data_Lens_Barlow.key);
};
var _TablatureLine = function (dictChoice) {
    return Data_Lens_Barlow.barlow(barlowInstance(constructBarlow1(Data_Lens_Barlow_Construction.constructBarlowGenericTCo10(Data_Lens_Barlow_Construction.constructBarlowGenericTCo10(Data_Lens_Barlow_Construction.constructBarlowGenericTCo4(dictChoice))(dictChoice))(dictChoice))(dictChoice.Profunctor0())))(Data_Lens_Barlow.key);
};
var _ChordLineChord = function (dictChoice) {
    return Data_Lens_Prism["prism$prime"](ChordLineChord.create)(function (v) {
        if (v instanceof ChordLineChord) {
            return new Data_Maybe.Just(v.value0);
        };
        return Data_Maybe.Nothing.value;
    })(dictChoice);
};
var _ChordLine = function (dictChoice) {
    return Data_Lens_Barlow.barlow(barlowInstance(constructBarlow1(Data_Lens_Barlow_Construction.constructBarlowGenericTCo10(Data_Lens_Barlow_Construction.constructBarlowGenericTCo10(Data_Lens_Barlow_Construction.constructBarlowGenericTCo10(Data_Lens_Barlow_Construction.constructBarlowGenericTCo4(dictChoice))(dictChoice))(dictChoice))(dictChoice))(dictChoice.Profunctor0())))(Data_Lens_Barlow.key);
};
export {
    TitleLine,
    HeaderLine,
    TablatureLine,
    ChordLine,
    TextLine,
    getTitle,
    _TitleLine,
    _TablatureLine,
    _ChordLine,
    _TextLine,
    Title,
    TitleOther,
    _Title,
    Header,
    HeaderSuffix,
    Prefix,
    Tuning,
    TimelineConnection,
    TimelineSep,
    TimelineSpace,
    Fret,
    Special,
    Suffix,
    _Tuning,
    Text,
    Spaces,
    TextLineChord,
    ChordLegend,
    _TextLineChord,
    ChordFret,
    ChordSpecial,
    ChordLineChord,
    ChordComment,
    _ChordLineChord,
    Spaced,
    _elem,
    _spaceSuffix,
    Chord,
    _root,
    _type,
    _bass,
    _mods,
    ChordMod,
    Note,
    _letter,
    _mod,
    NoteLetter,
    _primitive,
    _lowercase,
    A,
    B,
    C,
    D,
    E,
    F,
    G,
    fromString,
    Transposition,
    identityTransposition,
    succTransposition,
    predTransposition,
    genericTablatureDocumentL,
    genericTitleLineElem_,
    newtypeSpaced_,
    printSpaced,
    newtypeChord_,
    eqChord,
    printChord,
    printNote,
    printChordMod,
    eqChordMod,
    newtypeNote_,
    eqNote,
    newtypeNoteLetter_,
    eqNoteLetterPrimitive,
    ordNoteLetterPrimitive,
    genericNoteLetterPrimitiv,
    enumNoteLetterPrimitive,
    cyclicEnumNoteLetterPrimi,
    printNoteLetterPrimitive,
    eqNoteLetter,
    ordNoteLetter,
    printNoteLetter,
    showTransposition,
    eqTransposition,
    ordTransposition,
    showTablatureDocumentLine,
    showTablatureLineElem,
    printTablatureLineElem,
    showTextLineElem,
    showChordLineElem,
    showChordMod,
    showHeaderLineElem,
    showTitleLineElem
};
