// Generated by purs version 0.15.7
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Control_Monad_Maybe_Trans from "../Control.Monad.Maybe.Trans/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_String_Regex from "../Data.String.Regex/index.js";
import * as Data_String_Regex_Flags from "../Data.String.Regex.Flags/index.js";
import * as Data_String_Regex_Unsafe from "../Data.String.Regex.Unsafe/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
var traverse = /* #__PURE__ */ Data_Traversable.traverse(Data_Traversable.traversableArray);
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var mempty = /* #__PURE__ */ Data_Monoid.mempty(Data_Monoid.monoidArray);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit);
var unsafeTestRegex = function (patternString) {
    return function (text) {
        return Data_String_Regex.test(Data_String_Regex_Unsafe.unsafeRegex(patternString)(Data_String_Regex_Flags.noFlags))(text);
    };
};
var succ$prime = function (dict) {
    return dict["succ'"];
};
var print = function (dict) {
    return dict.print;
};
var pred$prime = function (dict) {
    return dict["pred'"];
};
var mapMaybeT = function (dictMonad) {
    var bind = Control_Bind.bind(dictMonad.Bind1());
    var Applicative0 = dictMonad.Applicative0();
    var traverse1 = traverse(Applicative0);
    var pure = Control_Applicative.pure(Applicative0);
    return function (f) {
        return function (array) {
            return bind(traverse1(function ($59) {
                return Control_Monad_Maybe_Trans.runMaybeT(f($59));
            })(array))(function (maybes) {
                return bind(pure(Data_Array.mapMaybe(identity)(maybes)))(function (values) {
                    return pure(values);
                });
            });
        };
    };
};
var mapMaybeM = function (dictMonad) {
    var bind = Control_Bind.bind(dictMonad.Bind1());
    var Applicative0 = dictMonad.Applicative0();
    var traverse1 = traverse(Applicative0);
    var pure = Control_Applicative.pure(Applicative0);
    return function (f) {
        return function (array) {
            return bind(traverse1(f)(array))(function (maybes) {
                return bind(pure(Data_Array.mapMaybe(identity)(maybes)))(function (values) {
                    return pure(values);
                });
            });
        };
    };
};
var hoistMaybeT$prime = function (dictMonad) {
    var bind = Control_Bind.bind(dictMonad.Bind1());
    var pure = Control_Applicative.pure(dictMonad.Applicative0());
    return function (maybeT) {
        return bind(Control_Monad_Maybe_Trans.runMaybeT(maybeT))(function (maybeA) {
            return pure(new Data_Tuple.Tuple(maybeA, mempty));
        });
    };
};
var hoistMaybe$prime = function (dictMonad) {
    var pure = Control_Applicative.pure(dictMonad.Applicative0());
    return function (maybe) {
        return pure(new Data_Tuple.Tuple(maybe, mempty));
    };
};
var foreachM = function (dictMonadState) {
    var Monad0 = dictMonadState.Monad0();
    var pure = Control_Applicative.pure(Monad0.Applicative0());
    var discard1 = discard(Monad0.Bind1());
    return function (v) {
        return function (v1) {
            if (v instanceof Data_List_Types.Nil) {
                return pure(Data_Unit.unit);
            };
            if (v instanceof Data_List_Types.Cons) {
                return discard1(v1(v.value0))(function () {
                    return foreachM(dictMonadState)(v.value1)(v1);
                });
            };
            throw new Error("Failed pattern match at Utils (line 47, column 1 - line 47, column 78): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var foreach$prime = function ($copy_v) {
    return function ($copy_v1) {
        return function ($copy_v2) {
            var $tco_var_v = $copy_v;
            var $tco_var_v1 = $copy_v1;
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(v, v1, v2) {
                if (v1 instanceof Data_List_Types.Nil) {
                    $tco_done = true;
                    return v;
                };
                if (v1 instanceof Data_List_Types.Cons) {
                    var nextState = v2(v)(v1.value0);
                    $tco_var_v = nextState;
                    $tco_var_v1 = v1.value1;
                    $copy_v2 = v2;
                    return;
                };
                throw new Error("Failed pattern match at Utils (line 41, column 1 - line 41, column 58): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
            };
            while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_v, $tco_var_v1, $copy_v2);
            };
            return $tco_result;
        };
    };
};
var foreach = function (v) {
    return function (v1) {
        return function (v2) {
            if (v1 instanceof Data_List_Types.Nil) {
                return Data_List_Types.Nil.value;
            };
            if (v1 instanceof Data_List_Types.Cons) {
                var next = v2(v)(v1.value0);
                return new Data_List_Types.Cons(Data_Tuple.snd(next), foreach(Data_Tuple.fst(next))(v1.value1)(v2));
            };
            throw new Error("Failed pattern match at Utils (line 35, column 1 - line 35, column 72): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
        };
    };
};
var applyUntilIdempotent = function (dictEq) {
    var eq = Data_Eq.eq(dictEq);
    return function (f) {
        return function (x) {
            var result = f(x);
            var $58 = eq(result)(x);
            if ($58) {
                return result;
            };
            return applyUntilIdempotent(dictEq)(f)(result);
        };
    };
};
export {
    pred$prime,
    print,
    succ$prime,
    applyUntilIdempotent,
    unsafeTestRegex,
    foreach,
    foreach$prime,
    foreachM,
    mapMaybeM,
    mapMaybeT,
    hoistMaybe$prime,
    hoistMaybeT$prime
};
