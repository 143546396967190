// Generated by purs version 0.15.7
import * as Data_Lens_Iso from "../Data.Lens.Iso/index.js";
var coerced = /* #__PURE__ */ Data_Lens_Iso.coerced()();
var _Newtype = function () {
    return function () {
        return function (dictProfunctor) {
            return coerced(dictProfunctor);
        };
    };
};
var _Newtype1 = /* #__PURE__ */ _Newtype()();
var unto = function () {
    return function (v) {
        return function (dictProfunctor) {
            return _Newtype1(dictProfunctor);
        };
    };
};
export {
    _Newtype,
    unto
};
