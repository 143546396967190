// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_CodePoints from "../Data.String.CodePoints/index.js";
import * as Data_String_CodeUnits from "../Data.String.CodeUnits/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_String_Regex from "../Data.String.Regex/index.js";
import * as Data_String_Regex_Flags from "../Data.String.Regex.Flags/index.js";
import * as Data_String_Regex_Unsafe from "../Data.String.Regex.Unsafe/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var NFC = /* #__PURE__ */ (function () {
    function NFC() {

    };
    NFC.value = new NFC();
    return NFC;
})();
var NFD = /* #__PURE__ */ (function () {
    function NFD() {

    };
    NFD.value = new NFD();
    return NFD;
})();
var NFKC = /* #__PURE__ */ (function () {
    function NFKC() {

    };
    NFKC.value = new NFKC();
    return NFKC;
})();
var NFKD = /* #__PURE__ */ (function () {
    function NFKD() {

    };
    NFKD.value = new NFKD();
    return NFKD;
})();
var words = function (s) {
    return $foreign.wordsImpl(s);
};
var unsafeRepeat = function (n) {
    return function (s) {
        return $foreign.unsafeRepeatImpl(n, s);
    };
};
var unsafeCodePointAt$prime = function (n) {
    return function (s) {
        return $foreign.unsafeCodePointAtPrimeImpl(n, s);
    };
};
var unsafeCodePointAt = function (n) {
    return function (s) {
        return $foreign.unsafeCodePointAtImpl(n, s);
    };
};
var trimStart = function (s) {
    return s.trimStart(Data_Unit.unit);
};
var trimEnd = function (s) {
    return s.trimEnd(Data_Unit.unit);
};
var toCharArray = function (s) {
    return $foreign.toCharArrayImpl(s);
};
var stripDiacritics = function (s) {
    return $foreign.stripDiacriticsImpl(s);
};
var stripChars = function (chars) {
    return function (s) {
        return $foreign.stripCharsImpl(chars, s);
    };
};
var startsWith$prime = function (searchString) {
    return function (position) {
        return function (s) {
            return $foreign.startsWithPrimeImpl(searchString, position, s);
        };
    };
};
var startsWith = function (searchString) {
    return function (s) {
        return $foreign.startsWithImpl(searchString, s);
    };
};
var showNormalizationForm = {
    show: function (v) {
        if (v instanceof NFC) {
            return "NFC";
        };
        if (v instanceof NFD) {
            return "NFD";
        };
        if (v instanceof NFKC) {
            return "NFKC";
        };
        if (v instanceof NFKD) {
            return "NFKD";
        };
        throw new Error("Failed pattern match at Data.String.Utils (line 264, column 1 - line 268, column 21): " + [ v.constructor.name ]);
    }
};
var show = /* #__PURE__ */ Data_Show.show(showNormalizationForm);
var repeat = function (n) {
    return function (s) {
        return $foreign.repeatImpl(Data_Maybe.Just.create, Data_Maybe.Nothing.value, n, s);
    };
};
var padStart$prime = function (n) {
    return function (s) {
        return $foreign.padStartPrimeImpl(n, s);
    };
};
var padStart = function (n) {
    return function (s) {
        var numberOfCodeUnits = Data_String_CodeUnits.length(s);
        var numberOfCodePoints = Data_String_CodePoints.length(s);
        return padStart$prime((n + numberOfCodeUnits | 0) - numberOfCodePoints | 0)(s);
    };
};
var padEnd$prime = function (n) {
    return function (s) {
        return $foreign.padEndPrimeImpl(n, s);
    };
};
var padEnd = function (n) {
    return function (s) {
        var numberOfCodeUnits = Data_String_CodeUnits.length(s);
        var numberOfCodePoints = Data_String_CodePoints.length(s);
        return padEnd$prime((n + numberOfCodeUnits | 0) - numberOfCodePoints | 0)(s);
    };
};
var normalize$prime = function (nf) {
    return function (s) {
        return $foreign.normalizePrimeImpl(show(nf), s);
    };
};
var normalize = function (s) {
    return $foreign.normalizeImpl(s);
};
var lines = function (s) {
    return $foreign.linesImpl(s);
};
var stripMarginWith = function (delimiter) {
    var go = function (line) {
        var trimmed = trimStart(line);
        var $7 = startsWith(delimiter)(trimmed);
        if ($7) {
            return Data_String_CodePoints.drop(Data_String_CodePoints.length(delimiter))(trimmed);
        };
        return line;
    };
    var $8 = Data_String_Common.joinWith("\x0a");
    var $9 = map(go);
    return function ($10) {
        return $8($9(lines(Data_String_Common.trim($10))));
    };
};
var stripMargin = /* #__PURE__ */ stripMarginWith("|");
var length = function () {
    return function (s) {
        return $foreign.lengthImpl(s);
    };
};
var includes$prime = function (needle) {
    return function (position) {
        return function (haystack) {
            return $foreign.includesPrimeImpl(needle, position, haystack);
        };
    };
};
var includes = function (searchString) {
    return function (s) {
        return $foreign.includesImpl(searchString, s);
    };
};
var fromCharArray = function (arr) {
    return $foreign.fromCharArrayImpl(arr);
};
var mapChars = function (f) {
    var $11 = map(f);
    return function ($12) {
        return fromCharArray($11(toCharArray($12)));
    };
};
var filter = function (p) {
    var $13 = Data_Array.filter(p);
    return function ($14) {
        return fromCharArray($13(toCharArray($14)));
    };
};
var escapeRegex = function (s) {
    return $foreign.escapeRegexImpl(s);
};
var replaceAll = function () {
    var mkRegex = function (str) {
        return Data_String_Regex_Unsafe.unsafeRegex(escapeRegex(str))(Data_String_Regex_Flags.global);
    };
    return function ($15) {
        return Data_String_Regex.replace(mkRegex($15));
    };
};
var endsWith$prime = function (searchString) {
    return function (position) {
        return function (s) {
            return $foreign.endsWithPrimeImpl(searchString, position, s);
        };
    };
};
var endsWith = function (searchString) {
    return function (s) {
        return $foreign.endsWithImpl(searchString, s);
    };
};
var codePointAt$prime = function (n) {
    return function (s) {
        return $foreign.codePointAtPrimeImpl(Data_Maybe.Just.create, Data_Maybe.Nothing.value, n, s);
    };
};
var codePointAt = function () {
    return function (n) {
        return function (s) {
            return $foreign.codePointAtImpl(Data_Maybe.Just.create, Data_Maybe.Nothing.value, n, s);
        };
    };
};
var charAt = function (n) {
    return function (str) {
        return Data_Array.index(toCharArray(str))(n);
    };
};
export {
    NFC,
    NFD,
    NFKC,
    NFKD,
    charAt,
    codePointAt,
    codePointAt$prime,
    endsWith,
    endsWith$prime,
    escapeRegex,
    filter,
    fromCharArray,
    includes,
    includes$prime,
    length,
    lines,
    mapChars,
    normalize,
    normalize$prime,
    padEnd,
    padEnd$prime,
    padStart,
    padStart$prime,
    repeat,
    replaceAll,
    startsWith,
    startsWith$prime,
    stripChars,
    stripDiacritics,
    stripMargin,
    stripMarginWith,
    toCharArray,
    trimEnd,
    trimStart,
    unsafeCodePointAt,
    unsafeCodePointAt$prime,
    unsafeRepeat,
    words,
    showNormalizationForm
};
