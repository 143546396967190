// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
var showUnit = {
    show: function (v) {
        return "unit";
    }
};
export {
    unit
} from "./foreign.js";
export {
    showUnit
};
