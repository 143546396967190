// Generated by purs version 0.15.7
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Maybe_Trans from "../Control.Monad.Maybe.Trans/index.js";
import * as CorsProxy from "../CorsProxy/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as Foreign from "../Foreign/index.js";
import * as JSURI from "../JSURI/index.js";
import * as LZString from "../LZString/index.js";
import * as LocationString from "../LocationString/index.js";
import * as SimpleJSON from "../SimpleJSON/index.js";
var readRecord = /* #__PURE__ */ SimpleJSON.readRecord();
var readFieldsCons = /* #__PURE__ */ SimpleJSON.readFieldsCons({
    reflectSymbol: function () {
        return "url";
    }
});
var readJSON = /* #__PURE__ */ SimpleJSON.readJSON(/* #__PURE__ */ readRecord(/* #__PURE__ */ readFieldsCons(/* #__PURE__ */ readRecord(/* #__PURE__ */ SimpleJSON.readFieldsCons({
    reflectSymbol: function () {
        return "shortLink";
    }
})(SimpleJSON.readString)(SimpleJSON.readFieldsNil)()()))(SimpleJSON.readFieldsNil)()()));
var readJSON1 = /* #__PURE__ */ SimpleJSON.readJSON(/* #__PURE__ */ readRecord(/* #__PURE__ */ readFieldsCons(/* #__PURE__ */ readRecord(/* #__PURE__ */ SimpleJSON.readFieldsCons({
    reflectSymbol: function () {
        return "status";
    }
})(SimpleJSON.readInt)(SimpleJSON.readFieldsNil)()()))(SimpleJSON.readFieldsNil)()()));
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var show1 = /* #__PURE__ */ Data_Show.show(/* #__PURE__ */ Data_List_Types.showNonEmptyList(Foreign.showForeignError));
var createCustomShortUrl = function (dictMonadAff) {
    var MonadEffect0 = dictMonadAff.MonadEffect0();
    var Monad0 = MonadEffect0.Monad0();
    var bind = Control_Bind.bind(Control_Monad_Maybe_Trans.bindMaybeT(Monad0));
    var liftEffect = Effect_Class.liftEffect(Control_Monad_Maybe_Trans.monadEffectMaybe(MonadEffect0));
    var pure = Control_Applicative.pure(Control_Monad_Maybe_Trans.applicativeMaybeT(Monad0));
    var applySecond = Control_Apply.applySecond(Control_Monad_Maybe_Trans.applyMaybeT(Monad0));
    var pure1 = Control_Applicative.pure(Monad0.Applicative0());
    return function (shortUrl) {
        return bind(liftEffect(LocationString.getLocationBaseString))(function (base) {
            var v = LZString.compressToEncodedURIComponent(shortUrl);
            if (v instanceof Data_Maybe.Just) {
                return pure(base + ("?u#" + v.value0));
            };
            return applySecond(liftEffect(Effect_Console.error("Could not compress shortlink URI")))(pure1(Data_Maybe.Nothing.value));
        });
    };
};
var createShortUrl = function (dictMonadAff) {
    var MonadEffect0 = dictMonadAff.MonadEffect0();
    var Monad0 = MonadEffect0.Monad0();
    var bindMaybeT = Control_Monad_Maybe_Trans.bindMaybeT(Monad0);
    var bind = Control_Bind.bind(bindMaybeT);
    var pure = Control_Applicative.pure(Monad0.Applicative0());
    var fetchThroughProxy = CorsProxy.fetchThroughProxy(dictMonadAff);
    var createCustomShortUrl1 = createCustomShortUrl(dictMonadAff);
    var discard1 = discard(bindMaybeT);
    var liftEffect = Effect_Class.liftEffect(Control_Monad_Maybe_Trans.monadEffectMaybe(MonadEffect0));
    return function (url) {
        return bind(pure(JSURI["encodeURIComponent"](url)))(function (encodedUrl) {
            return bind(fetchThroughProxy("https://cutt.ly/api/api.php?key=d27fec420c4daade4093757967023d6b70bc7&short=" + encodedUrl))(function (result) {
                var v = readJSON(result);
                if (v instanceof Data_Either.Right) {
                    return createCustomShortUrl1(v.value0.url.shortLink);
                };
                if (v instanceof Data_Either.Left) {
                    var v1 = readJSON1(result);
                    if (v1 instanceof Data_Either.Right) {
                        return discard1(liftEffect(Effect_Console.error("Received error status from cuttly API: " + show(v1.value0.url.status))))(function () {
                            return pure(Data_Maybe.Nothing.value);
                        });
                    };
                    if (v1 instanceof Data_Either.Left) {
                        return discard1(liftEffect(Effect_Console.error("Can't parse response JSON: " + (show1(v1.value0) + (". Response: " + result)))))(function () {
                            return pure(Data_Maybe.Nothing.value);
                        });
                    };
                    throw new Error("Failed pattern match at CuttlyUrlShortener (line 29, column 7 - line 35, column 32): " + [ v1.constructor.name ]);
                };
                throw new Error("Failed pattern match at CuttlyUrlShortener (line 26, column 3 - line 35, column 32): " + [ v.constructor.name ]);
            });
        });
    };
};
export {
    createShortUrl,
    createCustomShortUrl
};
