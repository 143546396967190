import * as Affjax from "../Affjax/index.js";
import * as Affjax_ResponseFormat from "../Affjax.ResponseFormat/index.js";
import * as Affjax_Web from "../Affjax.Web/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as JSURI from "../JSURI/index.js";
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var fetchThroughProxy = function (dictMonadAff) {
    var MonadEffect0 = dictMonadAff.MonadEffect0();
    var Monad0 = MonadEffect0.Monad0();
    var Bind1 = Monad0.Bind1();
    var discard2 = discard(Bind1);
    var liftEffect = Effect_Class.liftEffect(MonadEffect0);
    var pure1 = Control_Applicative.pure(Monad0.Applicative0());
    var bind = Control_Bind.bind(Bind1);
    var liftAff = Effect_Aff_Class.liftAff(dictMonadAff);
    return function (url) {
        var v = JSURI["encodeURIComponent"](url);
        if (v instanceof Data_Maybe.Nothing) {
            return discard2(liftEffect(Effect_Console.error("Could not encode " + url)))(function () {
                return pure1(Data_Maybe.Nothing.value);
            });
        };
        if (v instanceof Data_Maybe.Just) {
            var corsUrl = "https://api.codetabs.com/v1/proxy?quest=" + v.value0;
            return bind(liftAff(Affjax_Web.get(Affjax_ResponseFormat.string)(corsUrl)))(function (response) {
                return liftEffect((function () {
                    if (response instanceof Data_Either.Left) {
                        return function __do() {
                            Effect_Console.error("CORS Proxy response failed to decode: " + Affjax.printError(response.value0))();
                            return Data_Maybe.Nothing.value;
                        };
                    };
                    if (response instanceof Data_Either.Right) {
                        return pure(new Data_Maybe.Just(response.value0.body));
                    };
                    throw new Error("Failed pattern match at CorsProxy (line 25, column 18 - line 29, column 48): " + [ response.constructor.name ]);
                })());
            });
        };
        throw new Error("Failed pattern match at CorsProxy (line 18, column 3 - line 29, column 48): " + [ v.constructor.name ]);
    };
};
export {
    fetchThroughProxy
};
