// Generated by purs version 0.15.7
import * as AppActions from "../AppActions/index.js";
import * as AppState from "../AppState/index.js";
import * as AutoscrollSpeed from "../AutoscrollSpeed/index.js";
import * as Cache from "../Cache/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Lens_Getter from "../Data.Lens.Getter/index.js";
import * as Data_Lens_Internal_Forget from "../Data.Lens.Internal.Forget/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Number_Format from "../Data.Number.Format/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as Effect_Timer from "../Effect.Timer/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as Halogen_HTML_Events from "../Halogen.HTML.Events/index.js";
import * as Halogen_HTML_Properties from "../Halogen.HTML.Properties/index.js";
import * as Halogen_Query from "../Halogen.Query/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as HalogenUtils from "../HalogenUtils/index.js";
import * as TablatureDocument from "../TablatureDocument/index.js";
import * as TablatureRenderer from "../TablatureRenderer/index.js";
import * as TablatureRewriter from "../TablatureRewriter/index.js";
import * as Utils from "../Utils/index.js";
import * as Web_DOM_Element from "../Web.DOM.Element/index.js";
import * as Web_HTML_HTMLElement from "../Web.HTML.HTMLElement/index.js";
import * as Web_HTML_HTMLInputElement from "../Web.HTML.HTMLInputElement/index.js";
import * as Web_HTML_HTMLTextAreaElement from "../Web.HTML.HTMLTextAreaElement/index.js";
import * as Web_UIEvent_KeyboardEvent from "../Web.UIEvent.KeyboardEvent/index.js";
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit);
var _mode = /* #__PURE__ */ AppState["_mode"](Data_Lens_Internal_Forget.strongForget);
var show = /* #__PURE__ */ Data_Show.show(TablatureDocument.showTransposition);
var eq = /* #__PURE__ */ Data_Eq.eq(TablatureRewriter.eqNoteOrientation);
var print = /* #__PURE__ */ Utils.print(AutoscrollSpeed.printAutoscrollSpeed);
var _autoscrollSpeed = /* #__PURE__ */ AppState["_autoscrollSpeed"](Data_Lens_Internal_Forget.strongForget);
var fromFoldable = /* #__PURE__ */ Data_Array.fromFoldable(Data_List_Types.foldableList);
var mapFlipped = /* #__PURE__ */ Data_Functor.mapFlipped(Data_Maybe.functorMaybe);
var _searchResults = /* #__PURE__ */ AppState["_searchResults"](Data_Lens_Internal_Forget.strongForget);
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupArray);
var mapFlipped1 = /* #__PURE__ */ Data_Functor.mapFlipped(Data_Functor.functorArray);
var _loading = /* #__PURE__ */ AppState["_loading"](Data_Lens_Internal_Forget.strongForget);
var notEq = /* #__PURE__ */ Data_Eq.notEq(AppState.eqMode);
var notEq1 = /* #__PURE__ */ Data_Eq.notEq(AppState.eqActiveMenu);
var _activeMenu = /* #__PURE__ */ AppState["_activeMenu"](Data_Lens_Internal_Forget.strongForget);
var eq2 = /* #__PURE__ */ Data_Eq.eq(AppState.eqMode);
var _autoscroll = /* #__PURE__ */ AppState["_autoscroll"](Data_Lens_Internal_Forget.strongForget);
var mapFlipped2 = /* #__PURE__ */ Data_Functor.mapFlipped(Halogen_Query_HalogenM.functorHalogenM);
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Maybe.bindMaybe);
var bind1 = /* #__PURE__ */ Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM);
var applySecond = /* #__PURE__ */ Control_Apply.applySecond(Effect.applyEffect);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var viewState = /* #__PURE__ */ AppState.viewState(Halogen_Query_HalogenM.monadStateHalogenM);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM);
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var setState = /* #__PURE__ */ AppState.setState(Halogen_Query_HalogenM.monadStateHalogenM);
var stopAutoscroll = function (dictMonadEffect) {
    var Monad0 = dictMonadEffect.Monad0();
    var Bind1 = Monad0.Bind1();
    var bind2 = Control_Bind.bind(Bind1);
    var pure2 = Control_Applicative.pure(Monad0.Applicative0());
    var discard1 = discard(Bind1);
    var liftEffect = Effect_Class.liftEffect(dictMonadEffect);
    return function (dictMonadState) {
        var setState1 = AppState.setState(dictMonadState);
        return bind2(AppState.viewState(dictMonadState)(function (dictStrong) {
            return AppState["_autoscrollTimer"](dictStrong);
        }))(function (autoscrollTimer) {
            if (autoscrollTimer instanceof Data_Maybe.Nothing) {
                return pure2(Data_Unit.unit);
            };
            if (autoscrollTimer instanceof Data_Maybe.Just) {
                return discard1(liftEffect(Effect_Timer.clearInterval(autoscrollTimer.value0)))(function () {
                    return setState1(function (dictStrong) {
                        return AppState["_autoscrollTimer"](dictStrong);
                    })(Data_Maybe.Nothing.value);
                });
            };
            throw new Error("Failed pattern match at AppHtml (line 426, column 3 - line 430, column 40): " + [ autoscrollTimer.constructor.name ]);
        });
    };
};
var startAutoscrollOnElement = function (dictMonadEffect) {
    var bind2 = Control_Bind.bind((dictMonadEffect.Monad0()).Bind1());
    var liftEffect = Effect_Class.liftEffect(dictMonadEffect);
    return function (dictMonadState) {
        var viewState1 = AppState.viewState(dictMonadState);
        var setState1 = AppState.setState(dictMonadState);
        return function (elem) {
            return bind2(viewState1(function (dictStrong) {
                return AppState["_autoscrollSpeed"](dictStrong);
            }))(function (autoscrollSpeed) {
                return bind2(liftEffect(Effect_Timer.setInterval(AutoscrollSpeed.speedToIntervalMs(autoscrollSpeed))(HalogenUtils.scrollBy(0)(AutoscrollSpeed.speedToIntervalPixelDelta(autoscrollSpeed))(elem))))(function (intervalId) {
                    return setState1(function (dictStrong) {
                        return AppState["_autoscrollTimer"](dictStrong);
                    })(new Data_Maybe.Just(intervalId));
                });
            });
        };
    };
};
var refTablatureViewer = "tablatureViewer";
var refTablatureSearchInput = "tablatureSearchInput";
var refTablatureEditor = "tablatureEditor";
var render = function (state) {
    var toggleButtonTitle = (function () {
        var v = Data_Lens_Getter.view(_mode)(state);
        if (v instanceof AppState.EditMode) {
            return "Save tablature";
        };
        return "Edit tablature";
    })();
    var renderViewMenu = Halogen_HTML_Elements.div([ HalogenUtils.classString("dropdown-menu") ])([ Halogen_HTML_Elements.div([ HalogenUtils.classString("dropdown-item") ])([ Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title("Toggle normalization for tabs on or off"), Halogen_HTML_Events.onClick(function (v) {
        return AppActions.ToggleTabNormalization.value;
    }) ])([ (function () {
        var $120 = Cache.peek(AppState["_tabNormalizationEnabled"])(state);
        if ($120) {
            return HalogenUtils.fontAwesome("fa-toggle-on");
        };
        return HalogenUtils.fontAwesome("fa-toggle-off");
    })() ]), Halogen_HTML_Elements.div_([ Halogen_HTML_Core.text("Normalize tabs") ]) ]), Halogen_HTML_Elements.div([ HalogenUtils.classString("dropdown-item") ])([ (function () {
        var $121 = Cache.peek(AppState["_ignoreDozenalization"])(state);
        if ($121) {
            return Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title("Tablature is already dozenal"), HalogenUtils.classString("disabled") ])([ HalogenUtils.fontAwesome("fa-toggle-off") ]);
        };
        return Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title("Toggle decimal to dozenal conversion for tabs on or off"), Halogen_HTML_Events.onClick(function (v) {
            return AppActions.ToggleTabDozenalization.value;
        }) ])([ (function () {
            var $122 = Cache.peek(AppState["_tabDozenalizationEnabled"])(state);
            if ($122) {
                return HalogenUtils.fontAwesome("fa-toggle-on");
            };
            return HalogenUtils.fontAwesome("fa-toggle-off");
        })() ]);
    })(), Halogen_HTML_Elements.div_([ Halogen_HTML_Core.text("Dozenalize tabs") ]) ]), Halogen_HTML_Elements.div([ HalogenUtils.classString("dropdown-item") ])([ Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title("Toggle normalization for chords on or off"), Halogen_HTML_Events.onClick(function (v) {
        return AppActions.ToggleChordNormalization.value;
    }) ])([ (function () {
        var $123 = Cache.peek(AppState["_chordNormalizationEnabled"])(state);
        if ($123) {
            return HalogenUtils.fontAwesome("fa-toggle-on");
        };
        return HalogenUtils.fontAwesome("fa-toggle-off");
    })() ]), Halogen_HTML_Elements.div_([ Halogen_HTML_Core.text("Normalize chords") ]) ]), Halogen_HTML_Elements.div([ HalogenUtils.classString("dropdown-item") ])([ (function () {
        var $124 = Cache.peek(AppState["_ignoreDozenalization"])(state);
        if ($124) {
            return Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title("Tablature is already dozenal"), HalogenUtils.classString("disabled") ])([ HalogenUtils.fontAwesome("fa-toggle-off") ]);
        };
        return Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title("Toggle decimal to dozenal conversion for chords on or off"), Halogen_HTML_Events.onClick(function (v) {
            return AppActions.ToggleChordDozenalization.value;
        }) ])([ (function () {
            var $125 = Cache.peek(AppState["_chordDozenalizationEnabled"])(state);
            if ($125) {
                return HalogenUtils.fontAwesome("fa-toggle-on");
            };
            return HalogenUtils.fontAwesome("fa-toggle-off");
        })() ]);
    })(), Halogen_HTML_Elements.div_([ Halogen_HTML_Core.text(" Dozenalize chords") ]) ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.title("Transpose the tablature"), HalogenUtils.classString("dropdown-item") ])([ Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title("Transpose down"), Halogen_HTML_Events.onClick(function (v) {
        return AppActions.DecreaseTransposition.value;
    }) ])([ HalogenUtils.fontAwesome("fa-caret-down") ]), Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title("Transpose up"), Halogen_HTML_Events.onClick(function (v) {
        return AppActions.IncreaseTransposition.value;
    }) ])([ HalogenUtils.fontAwesome("fa-caret-up") ]), Halogen_HTML_Elements.div_([ Halogen_HTML_Core.text("Transpose " + show((Cache.peek(AppState["_urlParams"])(state)).transposition)) ]) ]), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.title("Preferred note orientation"), HalogenUtils.classString("dropdown-item") ])((function () {
        var noteOrientation = (Cache.peek(AppState["_urlParams"])(state)).noteOrientation;
        return [ Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title("default"), Halogen_HTML_Events.onClick(function (v) {
            return AppActions.DefaultNoteOrientation.value;
        }), (function () {
            var $126 = eq(noteOrientation)(TablatureRewriter.Default.value);
            if ($126) {
                return HalogenUtils.classString("selected");
            };
            return HalogenUtils.classString("");
        })() ])([ Halogen_HTML_Core.text("default") ]), Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title("Prefer flat"), Halogen_HTML_Events.onClick(function (v) {
            return AppActions.FlatNoteOrientation.value;
        }), (function () {
            var $127 = eq(noteOrientation)(TablatureRewriter.Flat.value);
            if ($127) {
                return HalogenUtils.classString("selected");
            };
            return HalogenUtils.classString("");
        })() ])([ Halogen_HTML_Core.text("flat") ]), Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title("Prefer sharp"), Halogen_HTML_Events.onClick(function (v) {
            return AppActions.SharpNoteOrientation.value;
        }), (function () {
            var $128 = eq(noteOrientation)(TablatureRewriter.Sharp.value);
            if ($128) {
                return HalogenUtils.classString("selected");
            };
            return HalogenUtils.classString("");
        })() ])([ Halogen_HTML_Core.text("sharp") ]), Halogen_HTML_Elements.div_([ Halogen_HTML_Core.text("Note orientation") ]) ];
    })()), Halogen_HTML_Elements.div([ Halogen_HTML_Properties.title("Change the autoscroll speed"), HalogenUtils.classString("dropdown-item") ])([ Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title("Decrease the autoscroll speed"), Halogen_HTML_Events.onClick(function (v) {
        return AppActions.DecreaseAutoscrollSpeed.value;
    }) ])([ HalogenUtils.fontAwesome("fa-backward") ]), Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title("Increase the autoscroll speed"), Halogen_HTML_Events.onClick(function (v) {
        return AppActions.IncreaseAutoscrollSpeed.value;
    }) ])([ HalogenUtils.fontAwesome("fa-forward") ]), Halogen_HTML_Elements.div_([ Halogen_HTML_Core.text("Autoscroll speed " + print(Data_Lens_Getter.view(_autoscrollSpeed)(state))) ]) ]) ]);
    var renderTitle = Halogen_HTML_Elements.div([ HalogenUtils.classString("title largeViewport") ])([ Halogen_HTML_Elements.a([ Halogen_HTML_Properties.href("https://github.com/tablature-viewer/tablature-viewer.github.io"), Halogen_HTML_Properties.target("_blank") ])([ Halogen_HTML_Elements.h1_([ Halogen_HTML_Core.text("Tablature Viewer") ]) ]) ]);
    var renderTablature = fromFoldable(TablatureRenderer.renderTablatureDocument(Cache.peek(AppState["_rewriteResult"])(state)));
    var renderSearchResult = function (searchResult) {
        return Halogen_HTML_Elements.tr([ Halogen_HTML_Events.onClick(function (v) {
            return new AppActions.ImportFromUrl(searchResult.url);
        }), Halogen_HTML_Events.onKeyDown(function (e) {
            var $129 = Web_UIEvent_KeyboardEvent.key(e) === "Enter" || Web_UIEvent_KeyboardEvent.key(e) === " ";
            if ($129) {
                return new AppActions.ImportFromUrl(searchResult.url);
            };
            return AppActions.NoAction.value;
        }), Halogen_HTML_Properties.tabIndex(0) ])([ Halogen_HTML_Elements.td([  ])([ Halogen_HTML_Core.text(searchResult.name) ]), Halogen_HTML_Elements.td([  ])([ Halogen_HTML_Core.text(searchResult.artist) ]), Halogen_HTML_Elements.td([  ])([ Halogen_HTML_Core.text(Data_Maybe.fromMaybe("")(mapFlipped(searchResult.rating)(Data_Number_Format.toStringWith(Data_Number_Format.precision(3))))) ]), Halogen_HTML_Elements.td([  ])([ Halogen_HTML_Core.text(Data_Maybe.fromMaybe("")(searchResult.contentType)) ]) ]);
    };
    var renderSearchResults = Halogen_HTML_Elements.div([ HalogenUtils.classString("searchResults") ])([ (function () {
        var v = Data_Lens_Getter.view(_searchResults)(state);
        if (v instanceof Data_Maybe.Nothing) {
            return Halogen_HTML_Elements.span([  ])([ Halogen_HTML_Core.text("Type something in the search bar") ]);
        };
        if (v instanceof Data_Maybe.Just && v.value0.length === 0) {
            return Halogen_HTML_Elements.span([  ])([ Halogen_HTML_Core.text("No search results") ]);
        };
        if (v instanceof Data_Maybe.Just) {
            return Halogen_HTML_Elements.table([  ])(append1([ Halogen_HTML_Elements.th([  ])([ Halogen_HTML_Core.text("Song") ]), Halogen_HTML_Elements.th([  ])([ Halogen_HTML_Core.text("Artist") ]), Halogen_HTML_Elements.th([  ])([ Halogen_HTML_Core.text("Rating") ]), Halogen_HTML_Elements.th([  ])([ Halogen_HTML_Core.text("Type") ]) ])(mapFlipped1(v.value0)(renderSearchResult)));
        };
        throw new Error("Failed pattern match at AppHtml (line 98, column 7 - line 107, column 12): " + [ v.constructor.name ]);
    })() ]);
    var renderSearchBar = Halogen_HTML_Elements.input([ Halogen_HTML_Properties.ref(refTablatureSearchInput), Halogen_HTML_Events.onValueChange(AppActions.SearchInput.create), HalogenUtils.classString("searchBar") ]);
    var renderLoadingIcon = Halogen_HTML_Elements.div([ HalogenUtils.classString("loadingIcon lds-ellipsis") ])((function () {
        var $133 = Data_Lens_Getter.view(_loading)(state);
        if ($133) {
            return [ Halogen_HTML_Elements.div_([  ]), Halogen_HTML_Elements.div_([  ]), Halogen_HTML_Elements.div_([  ]), Halogen_HTML_Elements.div_([  ]) ];
        };
        return [  ];
    })());
    var renderFileMenu = Halogen_HTML_Elements.div([ HalogenUtils.classString("dropdown-menu") ])([ Halogen_HTML_Elements.div([ HalogenUtils.classString((function () {
        var $134 = notEq(Data_Lens_Getter.view(_mode)(state))(AppState.ViewMode.value);
        if ($134) {
            return "hidden";
        };
        return "dropdown-item";
    })()) ])([ Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title(toggleButtonTitle), Halogen_HTML_Events.onClick(function (v) {
        return AppActions.ToggleEditMode.value;
    }) ])([ HalogenUtils.fontAwesome("fa-edit") ]), Halogen_HTML_Elements.div_([ Halogen_HTML_Core.text("Edit tablature") ]) ]), Halogen_HTML_Elements.div([ HalogenUtils.classString("dropdown-item") ])([ Halogen_HTML_Elements.a([ Halogen_HTML_Properties.href("./"), Halogen_HTML_Properties.target("_blank"), Halogen_HTML_Properties.tabIndex(-1 | 0) ])([ Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title("Open an empty tablature in a new browser tab") ])([ HalogenUtils.fontAwesome("fa-plus") ]) ]), Halogen_HTML_Elements.div_([ Halogen_HTML_Core.text("New tablature") ]) ]), Halogen_HTML_Elements.div([ HalogenUtils.classString("dropdown-item") ])([ Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title("Create a short link to the tablature for sharing with other people"), Halogen_HTML_Events.onClick(function (v) {
        return AppActions.CreateShortUrl.value;
    }) ])([ HalogenUtils.fontAwesome("fa-share") ]), Halogen_HTML_Elements.div_([ Halogen_HTML_Core.text("Share tablature") ]) ]) ]);
    var renderControls = Halogen_HTML_Elements.div([ HalogenUtils.classString("controls") ])([ Halogen_HTML_Elements.div([ HalogenUtils.classString((function () {
        var $135 = notEq(Data_Lens_Getter.view(_mode)(state))(AppState.ViewMode.value);
        if ($135) {
            return "hidden";
        };
        return "dropdown-container";
    })()) ])([ Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title("File"), Halogen_HTML_Events.onClick(function (v) {
        return AppActions.ClickFileMenu.value;
    }), HalogenUtils.classString("header-button dropdown-header"), (function () {
        var $136 = notEq1(Data_Lens_Getter.view(_activeMenu)(state))(AppState.FileMenu.value);
        if ($136) {
            return HalogenUtils.classString("header-button dropdown-header");
        };
        return HalogenUtils.classString("header-button dropdown-header selected");
    })() ])([ HalogenUtils.fontAwesome("fa-file"), HalogenUtils.optionalText(" File") ]), (function () {
        var $137 = notEq1(Data_Lens_Getter.view(_activeMenu)(state))(AppState.FileMenu.value);
        if ($137) {
            return Halogen_HTML_Elements.div([  ])([  ]);
        };
        return renderFileMenu;
    })() ]), Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title(toggleButtonTitle), Halogen_HTML_Events.onClick(function (v) {
        return AppActions.ToggleEditMode.value;
    }), HalogenUtils.classString((function () {
        var $138 = notEq(Data_Lens_Getter.view(_mode)(state))(AppState.EditMode.value);
        if ($138) {
            return "hidden";
        };
        return "header-button";
    })()) ])([ HalogenUtils.fontAwesome("fa-save"), HalogenUtils.optionalText(" Save") ]), Halogen_HTML_Elements.div([ HalogenUtils.classString((function () {
        var $139 = notEq(Data_Lens_Getter.view(_mode)(state))(AppState.ViewMode.value);
        if ($139) {
            return "hidden";
        };
        return "dropdown-container";
    })()) ])([ Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title("View"), Halogen_HTML_Events.onClick(function (v) {
        return AppActions.ClickSettingsMenu.value;
    }), (function () {
        var $140 = notEq1(Data_Lens_Getter.view(_activeMenu)(state))(AppState.SettingsMenu.value);
        if ($140) {
            return HalogenUtils.classString("header-button dropdown-header");
        };
        return HalogenUtils.classString("header-button dropdown-header selected");
    })() ])([ HalogenUtils.fontAwesome("fa-wrench"), HalogenUtils.optionalText(" View") ]), (function () {
        var $141 = notEq1(Data_Lens_Getter.view(_activeMenu)(state))(AppState.SettingsMenu.value);
        if ($141) {
            return Halogen_HTML_Elements.div([  ])([  ]);
        };
        return renderViewMenu;
    })() ]), Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title("Toggle search mode"), Halogen_HTML_Events.onClick(function (v) {
        return AppActions.ToggleSearch.value;
    }), HalogenUtils.classString("header-button") ])([ (function () {
        var $142 = eq2(Data_Lens_Getter.view(_mode)(state))(AppState.SearchMode.value);
        if ($142) {
            return HalogenUtils.fontAwesome("fa-chevron-left");
        };
        return HalogenUtils.fontAwesome("fa-search");
    })(), (function () {
        var $143 = eq2(Data_Lens_Getter.view(_mode)(state))(AppState.SearchMode.value);
        if ($143) {
            return HalogenUtils.optionalText(" Exit Search");
        };
        return HalogenUtils.optionalText(" Search");
    })() ]), Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title("Toggle autoscrolling"), Halogen_HTML_Events.onClick(function (v) {
        return AppActions.ToggleAutoscroll.value;
    }), HalogenUtils.classString((function () {
        var $144 = notEq(Data_Lens_Getter.view(_mode)(state))(AppState.ViewMode.value);
        if ($144) {
            return "hidden";
        };
        return "header-button";
    })()) ])((function () {
        var $145 = Data_Lens_Getter.view(_autoscroll)(state);
        if ($145) {
            return [ HalogenUtils.fontAwesome("fa-stop"), HalogenUtils.optionalText(" Autoscroll") ];
        };
        return [ HalogenUtils.fontAwesome("fa-play"), HalogenUtils.optionalText(" Autoscroll") ];
    })()), Halogen_HTML_Elements.a([ Halogen_HTML_Properties.href("https://github.com/tablature-viewer/tablature-viewer.github.io"), Halogen_HTML_Properties.target("_blank"), Halogen_HTML_Properties.tabIndex(-1 | 0), HalogenUtils.classString("header-button") ])([ Halogen_HTML_Elements.button([ Halogen_HTML_Properties.title("Open the README in a new browser tab") ])([ HalogenUtils.fontAwesome("fa-question"), HalogenUtils.optionalText(" Readme") ]) ]) ]);
    var renderHeader = Halogen_HTML_Elements.div([ HalogenUtils.classString("header") ])([ renderTitle, renderControls, renderLoadingIcon ]);
    var clickNoMenuEvent = (function () {
        var $146 = notEq1(Data_Lens_Getter.view(_activeMenu)(state))(AppState.NoMenu.value);
        if ($146) {
            return [ Halogen_HTML_Events.onClick(function (v) {
                return AppActions.ClickNoMenu.value;
            }) ];
        };
        return [  ];
    })();
    var renderBody = (function () {
        var v = Data_Lens_Getter.view(_mode)(state);
        if (v instanceof AppState.ViewMode) {
            return Halogen_HTML_Elements.div(append1([ HalogenUtils.classString("tablatureViewer tablature"), Halogen_HTML_Properties.ref(refTablatureViewer), Halogen_HTML_Properties.tabIndex(1) ])(clickNoMenuEvent))([ Halogen_HTML_Elements.pre_(renderTablature) ]);
        };
        if (v instanceof AppState.EditMode) {
            return Halogen_HTML_Elements.textarea(append1([ Halogen_HTML_Properties.ref(refTablatureEditor), Halogen_HTML_Properties.tabIndex(1), HalogenUtils.classString("tablatureEditor"), Halogen_HTML_Properties.placeholder("Paste your plaintext tablature or chord sheet here, click 'Save' and bookmark it"), Halogen_HTML_Properties.spellcheck(false) ])(clickNoMenuEvent));
        };
        if (v instanceof AppState.SearchMode) {
            return Halogen_HTML_Elements.div(append1([ HalogenUtils.classString("tablatureSearch"), Halogen_HTML_Properties.tabIndex(1) ])(clickNoMenuEvent))([ renderSearchBar, renderSearchResults ]);
        };
        throw new Error("Failed pattern match at AppHtml (line 63, column 16 - line 88, column 49): " + [ v.constructor.name ]);
    })();
    return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.div([ HalogenUtils.classString("app") ])([ renderHeader, renderBody ]), Halogen_HTML_Elements.div([ HalogenUtils.classString("tablaturePrinter tablature") ])([ Halogen_HTML_Elements.pre_(renderTablature) ]) ]);
};
var getTablatureEditorElement = function (dictMonadEffect) {
    return mapFlipped2(Halogen_Query.getHTMLElementRef(refTablatureEditor))(function (maybeHtmlElement) {
        return bind(maybeHtmlElement)(Web_HTML_HTMLTextAreaElement.fromHTMLElement);
    });
};
var getTablatureTextFromEditor = function (dictMonadEffect) {
    var liftEffect = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect));
    return bind1(getTablatureEditorElement(dictMonadEffect))(function (maybeTextArea) {
        if (maybeTextArea instanceof Data_Maybe.Nothing) {
            return liftEffect(applySecond(Effect_Console.error("Could not find textareaTablature"))(pure("")));
        };
        if (maybeTextArea instanceof Data_Maybe.Just) {
            return liftEffect(Web_HTML_HTMLTextAreaElement.value(maybeTextArea.value0));
        };
        throw new Error("Failed pattern match at AppHtml (line 369, column 3 - line 371, column 72): " + [ maybeTextArea.constructor.name ]);
    });
};
var setTablatureTextInEditor = function (dictMonadEffect) {
    var getTablatureEditorElement1 = getTablatureEditorElement(dictMonadEffect);
    var liftEffect = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect));
    return function (text) {
        return bind1(getTablatureEditorElement1)(function (maybeTextArea) {
            if (maybeTextArea instanceof Data_Maybe.Nothing) {
                return liftEffect(applySecond(Effect_Console.error("Could not find textareaTablature to set text"))(pure(Data_Unit.unit)));
            };
            if (maybeTextArea instanceof Data_Maybe.Just) {
                return liftEffect(Web_HTML_HTMLTextAreaElement.setValue(text)(maybeTextArea.value0));
            };
            throw new Error("Failed pattern match at AppHtml (line 419, column 3 - line 421, column 80): " + [ maybeTextArea.constructor.name ]);
        });
    };
};
var getTablatureContainerHtmlElement = function (dictMonadEffect) {
    return bind1(viewState(function (dictStrong) {
        return AppState["_mode"](dictStrong);
    }))(function (mode) {
        if (mode instanceof AppState.EditMode) {
            return Halogen_Query.getHTMLElementRef(refTablatureEditor);
        };
        if (mode instanceof AppState.ViewMode) {
            return Halogen_Query.getHTMLElementRef(refTablatureViewer);
        };
        if (mode instanceof AppState.SearchMode) {
            return pure1(Data_Maybe.Nothing.value);
        };
        throw new Error("Failed pattern match at AppHtml (line 376, column 3 - line 379, column 31): " + [ mode.constructor.name ]);
    });
};
var getTablatureContainerElement = function (dictMonadEffect) {
    return mapFlipped2(getTablatureContainerHtmlElement(dictMonadEffect))(map(Web_HTML_HTMLElement.toElement));
};
var loadScrollTop = function (dictMonadEffect) {
    var liftEffect = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect));
    return bind1(getTablatureContainerElement(dictMonadEffect))(function (maybeTablatureContainerElem) {
        if (maybeTablatureContainerElem instanceof Data_Maybe.Nothing) {
            return liftEffect(Effect_Console.error("Could not find tablatureContainer to load scroll top"));
        };
        if (maybeTablatureContainerElem instanceof Data_Maybe.Just) {
            return bind1(viewState(function (dictStrong) {
                return AppState["_scrollTop"](dictStrong);
            }))(function (scrollTop) {
                return liftEffect(Web_DOM_Element.setScrollTop(scrollTop)(maybeTablatureContainerElem.value0));
            });
        };
        throw new Error("Failed pattern match at AppHtml (line 396, column 3 - line 400, column 65): " + [ maybeTablatureContainerElem.constructor.name ]);
    });
};
var saveScrollTop = function (dictMonadEffect) {
    var liftEffect = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect));
    return bind1(getTablatureContainerElement(dictMonadEffect))(function (maybeTablatureContainerElem) {
        if (maybeTablatureContainerElem instanceof Data_Maybe.Nothing) {
            return liftEffect(Effect_Console.error("Could not find tablatureContainer to save scroll top"));
        };
        if (maybeTablatureContainerElem instanceof Data_Maybe.Just) {
            return bind1(liftEffect(Web_DOM_Element.scrollTop(maybeTablatureContainerElem.value0)))(function (newScrollTop) {
                return setState(function (dictStrong) {
                    return AppState["_scrollTop"](dictStrong);
                })(newScrollTop);
            });
        };
        throw new Error("Failed pattern match at AppHtml (line 387, column 3 - line 391, column 39): " + [ maybeTablatureContainerElem.constructor.name ]);
    });
};
var getSearchInputHtmlElement = function (dictMonadEffect) {
    return Halogen_Query.getHTMLElementRef(refTablatureSearchInput);
};
var getSearchInputElement = function (dictMonadEffect) {
    return mapFlipped2(getSearchInputHtmlElement(dictMonadEffect))(function (maybeHtmlElement) {
        return bind(maybeHtmlElement)(Web_HTML_HTMLInputElement.fromHTMLElement);
    });
};
var focusTablatureContainer = function (dictMonadEffect) {
    var liftEffect = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect));
    return bind1(getTablatureContainerHtmlElement(dictMonadEffect))(function (maybeTablatureContainerElem) {
        if (maybeTablatureContainerElem instanceof Data_Maybe.Nothing) {
            return liftEffect(Effect_Console.error("Could not find tablatureContainer to focus"));
        };
        if (maybeTablatureContainerElem instanceof Data_Maybe.Just) {
            return liftEffect(Web_HTML_HTMLElement.focus(maybeTablatureContainerElem.value0));
        };
        throw new Error("Failed pattern match at AppHtml (line 412, column 3 - line 414, column 77): " + [ maybeTablatureContainerElem.constructor.name ]);
    });
};
var focusSearchInput = function (dictMonadEffect) {
    var liftEffect = Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect));
    return bind1(getSearchInputHtmlElement(dictMonadEffect))(function (maybeSearchInputElement) {
        if (maybeSearchInputElement instanceof Data_Maybe.Nothing) {
            return liftEffect(Effect_Console.error("Could not find search element to focus"));
        };
        if (maybeSearchInputElement instanceof Data_Maybe.Just) {
            return liftEffect(Web_HTML_HTMLElement.focus(maybeSearchInputElement.value0));
        };
        throw new Error("Failed pattern match at AppHtml (line 405, column 3 - line 407, column 69): " + [ maybeSearchInputElement.constructor.name ]);
    });
};
export {
    refTablatureEditor,
    refTablatureViewer,
    refTablatureSearchInput,
    render,
    getSearchInputHtmlElement,
    getSearchInputElement,
    getTablatureEditorElement,
    getTablatureTextFromEditor,
    getTablatureContainerHtmlElement,
    getTablatureContainerElement,
    saveScrollTop,
    loadScrollTop,
    focusSearchInput,
    focusTablatureContainer,
    setTablatureTextInEditor,
    stopAutoscroll,
    startAutoscrollOnElement
};
