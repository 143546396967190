// Generated by purs version 0.15.7
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_List from "../Data.List/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_CodePoints from "../Data.String.CodePoints/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_String_Regex from "../Data.String.Regex/index.js";
import * as Data_String_Regex_Flags from "../Data.String.Regex.Flags/index.js";
import * as Data_String_Regex_Unsafe from "../Data.String.Regex.Unsafe/index.js";
import * as Data_String_Utils from "../Data.String.Utils/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as Effect_Unsafe from "../Effect.Unsafe/index.js";
import * as StringParser_CodePoints from "../StringParser.CodePoints/index.js";
import * as StringParser_Combinators from "../StringParser.Combinators/index.js";
import * as StringParser_Parser from "../StringParser.Parser/index.js";
import * as TablatureDocument from "../TablatureDocument/index.js";
import * as Utils from "../Utils/index.js";
var $runtime_lazy = function (name, moduleName, init) {
    var state = 0;
    var val;
    return function (lineNumber) {
        if (state === 2) return val;
        if (state === 1) throw new ReferenceError(name + " was needed before it finished initializing (module " + moduleName + ", line " + lineNumber + ")", moduleName, lineNumber);
        state = 1;
        val = init();
        state = 2;
        return val;
    };
};
var applyFirst = /* #__PURE__ */ Control_Apply.applyFirst(StringParser_Parser.applyParser);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var show1 = /* #__PURE__ */ Data_Show.show(Data_Show.showString);
var pure = /* #__PURE__ */ Control_Applicative.pure(Data_Maybe.applicativeMaybe);
var mapFlipped = /* #__PURE__ */ Data_Functor.mapFlipped(StringParser_Parser.functorParser);
var fromJust = /* #__PURE__ */ Data_Maybe.fromJust();
var bind = /* #__PURE__ */ Control_Bind.bind(StringParser_Parser.bindParser);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(StringParser_Parser.applicativeParser);
var alt = /* #__PURE__ */ Control_Alt.alt(StringParser_Parser.altParser);
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_List_Types.semigroupList);
var applySecond = /* #__PURE__ */ Control_Apply.applySecond(StringParser_Parser.applyParser);
var print = /* #__PURE__ */ Utils.print(TablatureDocument.printTablatureLineElem);
var sum = /* #__PURE__ */ Data_Foldable.sum(Data_List_Types.foldableList)(Data_Semiring.semiringInt);
var mapFlipped1 = /* #__PURE__ */ Data_Functor.mapFlipped(Data_List_Types.functorList);
var fold = /* #__PURE__ */ Data_Foldable.fold(Data_List_Types.foldableList)(Data_Monoid.monoidString);
var append2 = /* #__PURE__ */ Data_Semigroup.append(/* #__PURE__ */ StringParser_Parser.semigroupParser(Data_Semigroup.semigroupString));
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit)(StringParser_Parser.bindParser);
var append3 = /* #__PURE__ */ Data_Semigroup.append(/* #__PURE__ */ StringParser_Parser.semigroupParser(Data_List_Types.semigroupList));
var tryRunParser = function (dictShow) {
    return function (parser) {
        return function (inputString) {
            var v = StringParser_Parser.unParser(applyFirst(parser)(StringParser_CodePoints.eof))({
                substring: inputString,
                position: 0
            });
            if (v instanceof Data_Either.Left) {
                var msg = "Position: " + (show(v.value0.pos) + ("\x0aError: " + (show1(v.value0.error) + ("\x0aIn input string: " + (inputString + ("\x0aWith unparsed suffix: " + show1(Data_String_CodePoints.drop(v.value0.pos)(inputString))))))));
                return (function (v1) {
                    return Data_Maybe.Nothing.value;
                })(Effect_Unsafe.unsafePerformEffect(Effect_Console.error(msg)));
            };
            if (v instanceof Data_Either.Right) {
                return pure(v.value0.result);
            };
            throw new Error("Failed pattern match at TablatureParser (line 227, column 3 - line 237, column 33): " + [ v.constructor.name ]);
        };
    };
};
var tryRunParser1 = /* #__PURE__ */ tryRunParser(/* #__PURE__ */ Data_List_Types.showList(TablatureDocument.showTablatureDocumentLine));
var sanitizeInput = function (inputString) {
    return Data_String_Regex.replace(Data_String_Regex_Unsafe.unsafeRegex("[ \x09]+\x0a")(Data_String_Regex_Flags.global))("\x0a")(Data_String_Regex.replace(Data_String_Regex_Unsafe.unsafeRegex("\x0d")(Data_String_Regex_Flags.global))("")(inputString));
};
var parseWord = /* #__PURE__ */ (function () {
    return mapFlipped(StringParser_CodePoints.regex("(?<!\\S)\\S+(?!\\S)"))(TablatureDocument.Text.create);
})();
var parseTimelineSep = /* #__PURE__ */ (function () {
    return mapFlipped(mapFlipped(StringParser_CodePoints.regex("[|\\[\\]]{1,2}"))(TablatureDocument.TimelineSep.create))(Data_List.singleton);
})();
var parseTimelineConnection = /* #__PURE__ */ (function () {
    return mapFlipped(mapFlipped(StringParser_CodePoints.regex("[\\-\u2014]+"))(TablatureDocument.TimelineConnection.create))(Data_List.singleton);
})();
var parseTextLineSpace = /* #__PURE__ */ (function () {
    return mapFlipped(StringParser_CodePoints.regex("[^\\S\\n]+"))(TablatureDocument.Spaces.create);
})();
var parseSpecial = /* #__PURE__ */ (function () {
    return mapFlipped(mapFlipped(StringParser_CodePoints.regex("[^\\s|\\[\\]\\-\u2014\\d\u218a\u218b]+"))(TablatureDocument.Special.create))(Data_List.singleton);
})();
var parseSpaces = /* #__PURE__ */ StringParser_CodePoints.regex("[ ]*");
var parsePunctuation = /* #__PURE__ */ StringParser_CodePoints.regex("[,.?())]");
var parseNote = /* #__PURE__ */ (function () {
    var lowercase = function (letter) {
        return Data_String_Common.toLower(letter) === letter;
    };
    var getPrimitive = function (rootLetter) {
        return fromJust(TablatureDocument.fromString(Data_String_Common.toUpper(rootLetter)));
    };
    return bind(StringParser_CodePoints.regex("[A-Ga-g]"))(function (rootLetter) {
        return bind(StringParser_CodePoints.regex("[#b]*"))(function (rootMod) {
            return pure1({
                letter: {
                    primitive: getPrimitive(rootLetter),
                    lowercase: lowercase(rootLetter)
                },
                mod: rootMod
            });
        });
    });
})();
var parseSpacedNote = /* #__PURE__ */ bind(parseNote)(function (note) {
    return bind(parseSpaces)(function (spaceSuffix) {
        return pure1({
            elem: note,
            spaceSuffix: Data_String_CodePoints.length(spaceSuffix)
        });
    });
});
var parseMaybeTuning = /* #__PURE__ */ (function () {
    return StringParser_Combinators.optionMaybe(mapFlipped(applyFirst(parseSpacedNote)(StringParser_Combinators.tryAhead(parseTimelineSep)))(TablatureDocument.Tuning.create));
})();
var parseFret = /* #__PURE__ */ (function () {
    return mapFlipped(mapFlipped(StringParser_CodePoints.regex("[\\d\u218a\u218b]+"))(TablatureDocument.Fret.create))(Data_List.singleton);
})();
var parseParseTimelineConnectionWithSubsequentFretsAndSpecials = /* #__PURE__ */ StringParser_Combinators["try"](/* #__PURE__ */ bind(parseTimelineConnection)(function (timeline) {
    return bind(StringParser_Combinators.many(alt(parseFret)(parseSpecial)))(function (fretsAndSpecials) {
        return pure1(append1(timeline)(Data_List.concat(fretsAndSpecials)));
    });
}));
var parseEndOfLineString = /* #__PURE__ */ StringParser_CodePoints.regex("\\n");
var parseEndOfLine = /* #__PURE__ */ alt(/* #__PURE__ */ applySecond(parseEndOfLineString)(/* #__PURE__ */ pure1(Data_Unit.unit)))(StringParser_CodePoints.eof);
var parseHeaderLine = /* #__PURE__ */ StringParser_Combinators["try"](/* #__PURE__ */ bind(/* #__PURE__ */ StringParser_CodePoints.regex("[^\\S\\n]*\\[[^\\n]*\\w{2}[^\\n]*\\]"))(function (header) {
    return bind(applyFirst(StringParser_CodePoints.regex("[^\\n]*"))(parseEndOfLine))(function (suffix) {
        return pure1(new TablatureDocument.HeaderLine(new Data_List_Types.Cons(new TablatureDocument.Header(header), new Data_List_Types.Cons(new TablatureDocument.HeaderSuffix(suffix), Data_List_Types.Nil.value))));
    });
}));
var parseTitleLine = /* #__PURE__ */ StringParser_Combinators["try"](/* #__PURE__ */ bind(/* #__PURE__ */ StringParser_CodePoints.regex("[^\\w\\n]*"))(function (prefix) {
    return bind(StringParser_CodePoints.regex("[^\\n]*[\\w)!?\"']"))(function (title) {
        return bind(applyFirst(StringParser_CodePoints.regex("[^\\n]*"))(parseEndOfLine))(function (suffix) {
            return pure1(new TablatureDocument.TitleLine(new Data_List_Types.Cons(new TablatureDocument.TitleOther(prefix), new Data_List_Types.Cons(new TablatureDocument.Title(title), new Data_List_Types.Cons(new TablatureDocument.TitleOther(suffix), Data_List_Types.Nil.value)))));
        });
    });
}));
var parseChordLegend = /* #__PURE__ */ (function () {
    return mapFlipped(applySecond(StringParser_Combinators.tryAhead(StringParser_CodePoints.regex("(?<!\\S)(([\\dxX\u218a\u218b]{1,2}[-]*){4,12})(?!\\S)")))(StringParser_Combinators.many(alt(mapFlipped(StringParser_CodePoints.regex("[1234567890\u218a\u218b]"))(TablatureDocument.ChordFret.create))(mapFlipped(StringParser_CodePoints.regex("[xX-]"))(TablatureDocument.ChordSpecial.create)))))(TablatureDocument.ChordLegend.create);
})();
var parseChordComment = /* #__PURE__ */ (function () {
    return mapFlipped(StringParser_CodePoints.regex("[^\\S\\n]*(\\([^\\n()]*\\)|\\.\\.+|[^\\S\\n]+|x\\d+)[^\\S\\n]*"))(TablatureDocument.ChordComment.create);
})();
var parseAnyLine = /* #__PURE__ */ mapFlipped(/* #__PURE__ */ applyFirst(/* #__PURE__ */ StringParser_CodePoints.regex("[^\\n]*"))(parseEndOfLine))(function (result) {
    return new TablatureDocument.TextLine(new Data_List_Types.Cons(new TablatureDocument.Text(result), Data_List_Types.Nil.value));
});
var parseAnyChar = /* #__PURE__ */ StringParser_CodePoints.regex("[^\\n]");
var assertTimelineNotEnded = /* #__PURE__ */ applyFirst(/* #__PURE__ */ pure1(Data_Unit.unit))(/* #__PURE__ */ StringParser_Combinators.tryAhead(/* #__PURE__ */ StringParser_Combinators.manyTill(parseAnyChar)(/* #__PURE__ */ alt(parseTimelineConnection)(parseTimelineSep))));
var parseTimelineSpace = /* #__PURE__ */ (function () {
    return mapFlipped(mapFlipped(StringParser_Combinators["try"](applyFirst(applyFirst(StringParser_CodePoints.regex("[ ]{1,2}"))(StringParser_Combinators.tryAhead(StringParser_CodePoints.regex("[^\\s]"))))(assertTimelineNotEnded)))(TablatureDocument.TimelineSpace.create))(Data_List.singleton);
})();
var parseInnerTablatureLine = /* #__PURE__ */ (function () {
    var countTimeLineSpaces = function (v) {
        if (v instanceof TablatureDocument.TimelineSpace) {
            return Data_String_CodePoints.length(v.value0);
        };
        return 0;
    };
    var countTimeLineChars = function (v) {
        if (v instanceof TablatureDocument.TimelineConnection) {
            return Data_String_CodePoints.length(v.value0);
        };
        if (v instanceof TablatureDocument.TimelineSep) {
            return Data_String_CodePoints.length(v.value0);
        };
        return 0;
    };
    var countChars = function (s) {
        return Data_String_CodePoints.length(print(s));
    };
    return StringParser_Combinators["try"](bind(mapFlipped(applySecond(StringParser_Combinators.tryAhead(parseTimelineSep))(StringParser_Combinators.many(alt(parseParseTimelineConnectionWithSubsequentFretsAndSpecials)(alt(parseTimelineSep)(alt(parseTimelineSpace)(alt(StringParser_Combinators["try"](applyFirst(parseFret)(assertTimelineNotEnded)))(StringParser_Combinators["try"](applyFirst(parseSpecial)(assertTimelineNotEnded)))))))))(Data_List.concat))(function (result) {
        var nrSpaces = sum(mapFlipped1(result)(countTimeLineSpaces));
        var nrTimelineChars = sum(mapFlipped1(result)(countTimeLineChars));
        var nrTablineChars = sum(mapFlipped1(result)(countChars));
        var $44 = nrSpaces < nrTimelineChars && 10 < nrTablineChars;
        if ($44) {
            return pure1(result);
        };
        return StringParser_Parser.fail("Not enough tablature line characters");
    }));
})();
var parseTablatureLineWithOptionalTuning = /* #__PURE__ */ (function () {
    var getTuning = function (v) {
        if (v instanceof Data_Maybe.Nothing) {
            return Data_List_Types.Nil.value;
        };
        if (v instanceof Data_Maybe.Just) {
            return new Data_List_Types.Cons(v.value0, Data_List_Types.Nil.value);
        };
        throw new Error("Failed pattern match at TablatureParser (line 89, column 15 - line 91, column 22): " + [ v.constructor.name ]);
    };
    return StringParser_Combinators["try"](bind(parseMaybeTuning)(function (maybeTuning) {
        return bind(parseInnerTablatureLine)(function (tabLine) {
            return bind(pure1((function () {
                var v = Data_List.head(tabLine);
                if (v instanceof Data_Maybe.Just && v.value0 instanceof TablatureDocument.TimelineSep) {
                    var $48 = Data_String_Utils.startsWith("[")(v.value0.value0);
                    if ($48) {
                        return true;
                    };
                    return false;
                };
                return false;
            })()))(function (startsWithBracket) {
                var $51 = startsWithBracket && Data_Maybe.isNothing(maybeTuning);
                if ($51) {
                    return StringParser_Parser.fail("If the tablature starts with [ then we require a tuning to make sure we don't label a header as a tab line");
                };
                return pure1(append1(getTuning(maybeTuning))(tabLine));
            });
        });
    }));
})();
var $lazy_parseTablatureLinesWithPrefix = /* #__PURE__ */ $runtime_lazy("parseTablatureLinesWithPrefix", "TablatureParser", function () {
    return StringParser_Combinators["try"](bind(mapFlipped(StringParser_Combinators.manyTill(StringParser_CodePoints.regex("[^\\n]"))(StringParser_Combinators.tryAhead(parseTablatureLineWithOptionalTuning)))(function (result) {
        return new TablatureDocument.Prefix(fold(result));
    }))(function (prefix) {
        return bind(parseTablatureLineWithOptionalTuning)(function (tabLine) {
            return bind(StringParser_Combinators.option(Data_List_Types.Nil.value)($lazy_parseTablatureLinesWithPrefix(75)))(function (subsequentTabLine) {
                return pure1(append1(new Data_List_Types.Cons(prefix, Data_List_Types.Nil.value))(append1(tabLine)(subsequentTabLine)));
            });
        });
    }));
});
var parseTablatureLinesWithPrefix = /* #__PURE__ */ $lazy_parseTablatureLinesWithPrefix(71);
var parseTablatureLinesWithSurroundings = /* #__PURE__ */ StringParser_Combinators["try"](/* #__PURE__ */ bind(parseTablatureLinesWithPrefix)(function (tabLine) {
    return bind(mapFlipped(applyFirst(StringParser_CodePoints.regex("[^\\n]*"))(parseEndOfLine))(TablatureDocument.Suffix.create))(function (suffix) {
        return pure1(new TablatureDocument.TablatureLine(append1(tabLine)(new Data_List_Types.Cons(suffix, Data_List_Types.Nil.value))));
    });
}));
var assertEndChordBoundary = /* #__PURE__ */ alt(StringParser_CodePoints.eof)(/* #__PURE__ */ applySecond(/* #__PURE__ */ StringParser_Combinators.tryAhead(/* #__PURE__ */ alt(/* #__PURE__ */ StringParser_CodePoints.regex("\\s"))(parsePunctuation)))(/* #__PURE__ */ pure1(Data_Unit.unit)));
var parseChord = /* #__PURE__ */ (function () {
    var parseStackableChordMods = StringParser_CodePoints.regex("(o|no|add|dim|dom|augm(?![a-zA-Z])|aug|maj|Maj|M|\xf8|\u0394|[*])?([2-9]|10|11|12|13)?(b|#|[+]|[-])?");
    var parseParenthesizedStackableChordMods = StringParser_Combinators["try"](append2(StringParser_CodePoints.string("("))(append2(parseStackableChordMods)(StringParser_CodePoints.string(")"))));
    var parseChordType = StringParser_CodePoints.regex("(\xf8|\u0394| ?Major| ?major|Maj|maj|Ma| ?Minor| ?minor|Min|min|M|m|[-]|[+]|o)?");
    var parseChordMods = StringParser_Combinators.many(alt(StringParser_CodePoints.regex("sus[24]?"))(alt(parseParenthesizedStackableChordMods)(parseStackableChordMods)));
    return bind(parseNote)(function (root) {
        return bind(parseChordType)(function (chordType) {
            return bind(mapFlipped(parseChordMods)(fold))(function (mods) {
                return bind(StringParser_Combinators.optionMaybe(applySecond(StringParser_CodePoints.string("/"))(parseNote)))(function (maybeBass) {
                    return discard(assertEndChordBoundary)(function () {
                        return bind(parseSpaces)(function (spaceSuffix) {
                            return pure1({
                                elem: {
                                    root: root,
                                    type: chordType,
                                    mods: new Data_List_Types.Cons({
                                        pre: "",
                                        interval: mods,
                                        post: ""
                                    }, Data_List_Types.Nil.value),
                                    bass: maybeBass
                                },
                                spaceSuffix: Data_String_CodePoints.length(spaceSuffix)
                            });
                        });
                    });
                });
            });
        });
    });
})();
var parseChordLineChord = /* #__PURE__ */ mapFlipped(parseChord)(function (chord) {
    return new TablatureDocument.ChordLineChord(chord);
});
var parseChordLine = /* #__PURE__ */ (function () {
    return StringParser_Combinators["try"](mapFlipped(applyFirst(append3(StringParser_Combinators.many(parseChordComment))(append3(mapFlipped(parseChordLineChord)(function (c) {
        return new Data_List_Types.Cons(c, Data_List_Types.Nil.value);
    }))(StringParser_Combinators.many(alt(parseChordLineChord)(parseChordComment)))))(parseEndOfLine))(TablatureDocument.ChordLine.create));
})();
var parseTextLine = /* #__PURE__ */ (function () {
    return StringParser_Combinators["try"](mapFlipped(StringParser_Combinators.manyTill(alt(parseTextLineSpace)(alt(mapFlipped(parsePunctuation)(TablatureDocument.Text.create))(alt(StringParser_Combinators["try"](mapFlipped(parseChord)(TablatureDocument.TextLineChord.create)))(alt(parseChordLegend)(parseWord)))))(parseEndOfLine))(TablatureDocument.TextLine.create));
})();
var parseTablatureDocument = /* #__PURE__ */ (function () {
    return bind(StringParser_Combinators.option(Data_List_Types.Nil.value)(StringParser_Combinators["try"](StringParser_Combinators.manyTill(parseTextLine)(StringParser_Combinators.tryAhead(alt(parseTitleLine)(parseTablatureLinesWithSurroundings))))))(function (commentLinesBeforeTitle) {
        return bind(StringParser_Combinators.option(Data_List_Types.Nil.value)(mapFlipped(parseTitleLine)(function (result) {
            return new Data_List_Types.Cons(result, Data_List_Types.Nil.value);
        })))(function (title) {
            return bind(StringParser_Combinators.many(alt(parseTablatureLinesWithSurroundings)(alt(parseChordLine)(alt(parseHeaderLine)(alt(parseTextLine)(parseAnyLine))))))(function (body) {
                return pure1(append1(commentLinesBeforeTitle)(append1(title)(body)));
            });
        });
    });
})();
var tryParseTablature = function (inputString) {
    return tryRunParser1(parseTablatureDocument)(sanitizeInput(inputString));
};
export {
    parseTablatureDocument,
    parseTitleLine,
    parseTimelineSep,
    parseTimelineConnection,
    parseTimelineSpace,
    assertTimelineNotEnded,
    parseFret,
    parseSpecial,
    parseMaybeTuning,
    parseTablatureLinesWithSurroundings,
    parseTablatureLinesWithPrefix,
    parseTablatureLineWithOptionalTuning,
    parseParseTimelineConnectionWithSubsequentFretsAndSpecials,
    parseInnerTablatureLine,
    parseHeaderLine,
    parseChordLine,
    parseChordLineChord,
    parseChord,
    parseNote,
    parseSpacedNote,
    parseChordComment,
    parseTextLine,
    parseTextLineSpace,
    parsePunctuation,
    assertEndChordBoundary,
    parseSpaces,
    parseWord,
    parseChordLegend,
    parseAnyLine,
    parseEndOfLine,
    parseEndOfLineString,
    parseAnyChar,
    sanitizeInput,
    tryParseTablature,
    tryRunParser
};
