// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Control_Monad_Except from "../Control.Monad.Except/index.js";
import * as Control_Monad_Except_Trans from "../Control.Monad.Except.Trans/index.js";
import * as Control_Semigroupoid from "../Control.Semigroupoid/index.js";
import * as Data_Array_NonEmpty from "../Data.Array.NonEmpty/index.js";
import * as Data_Bifunctor from "../Data.Bifunctor/index.js";
import * as Data_Boolean from "../Data.Boolean/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Identity from "../Data.Identity/index.js";
import * as Data_List_NonEmpty from "../Data.List.NonEmpty/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Nullable from "../Data.Nullable/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Symbol from "../Data.Symbol/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_TraversableWithIndex from "../Data.TraversableWithIndex/index.js";
import * as Data_Variant from "../Data.Variant/index.js";
import * as Effect_Exception from "../Effect.Exception/index.js";
import * as Effect_Uncurried from "../Effect.Uncurried/index.js";
import * as Effect_Unsafe from "../Effect.Unsafe/index.js";
import * as Foreign from "../Foreign/index.js";
import * as Foreign_Index from "../Foreign.Index/index.js";
import * as Foreign_Object from "../Foreign.Object/index.js";
import * as Partial_Unsafe from "../Partial.Unsafe/index.js";
import * as Record from "../Record/index.js";
import * as Record_Builder from "../Record.Builder/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorArray);
var fail = /* #__PURE__ */ Foreign.fail(Data_Identity.monadIdentity);
var applicativeExceptT = /* #__PURE__ */ Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity);
var pure = /* #__PURE__ */ Control_Applicative.pure(applicativeExceptT);
var map1 = /* #__PURE__ */ Data_Functor.map(/* #__PURE__ */ Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity));
var map2 = /* #__PURE__ */ Data_Functor.map(Data_List_Types.functorNonEmptyList);
var bindExceptT = /* #__PURE__ */ Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(bindExceptT);
var map3 = /* #__PURE__ */ Data_Functor.map(Data_Functor.functorFn);
var traverse = /* #__PURE__ */ Data_Traversable.traverse(Data_Traversable.traversableMaybe)(applicativeExceptT);
var readNull = /* #__PURE__ */ Foreign.readNull(Data_Identity.monadIdentity);
var composeKleisliFlipped = /* #__PURE__ */ Control_Bind.composeKleisliFlipped(bindExceptT);
var sequence = /* #__PURE__ */ Data_Traversable.sequence(Foreign_Object.traversableObject)(applicativeExceptT);
var identity = /* #__PURE__ */ Control_Category.identity(Record_Builder.categoryBuilder);
var traverseWithIndex = /* #__PURE__ */ Data_TraversableWithIndex.traverseWithIndex(Data_TraversableWithIndex.traversableWithIndexArray)(applicativeExceptT);
var readArray1 = /* #__PURE__ */ Foreign.readArray(Data_Identity.monadIdentity);
var bind = /* #__PURE__ */ Control_Bind.bind(bindExceptT);
var except = /* #__PURE__ */ Control_Monad_Except_Trans.except(Data_Identity.applicativeIdentity);
var on = /* #__PURE__ */ Data_Variant.on();
var compose1 = /* #__PURE__ */ Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder);
var insert = /* #__PURE__ */ Record_Builder.insert()();
var readProp = /* #__PURE__ */ Foreign_Index.readProp(Data_Identity.monadIdentity);
var alt = /* #__PURE__ */ Control_Alt.alt(/* #__PURE__ */ Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity));
var inj = /* #__PURE__ */ Data_Variant.inj();
var writeVariantImpl = function (dict) {
    return dict.writeVariantImpl;
};
var writeImplFields = function (dict) {
    return dict.writeImplFields;
};
var writeImpl = function (dict) {
    return dict.writeImpl;
};
var writeJSON = function (dictWriteForeign) {
    var $191 = writeImpl(dictWriteForeign);
    return function ($192) {
        return $foreign["_unsafeStringify"]($191($192));
    };
};
var writeForeignVariant = function () {
    return function (dictWriteForeignVariant) {
        var writeVariantImpl1 = writeVariantImpl(dictWriteForeignVariant);
        return {
            writeImpl: function (variant) {
                return writeVariantImpl1(Type_Proxy["Proxy"].value)(variant);
            }
        };
    };
};
var writeForeignString = {
    writeImpl: Foreign.unsafeToForeign
};
var writeForeignObject = function (dictWriteForeign) {
    return {
        writeImpl: (function () {
            var $193 = Foreign_Object.mapWithKey(Data_Function["const"](writeImpl(dictWriteForeign)));
            return function ($194) {
                return Foreign.unsafeToForeign($193($194));
            };
        })()
    };
};
var writeForeignNumber = {
    writeImpl: Foreign.unsafeToForeign
};
var writeForeignNullable = function (dictWriteForeign) {
    return {
        writeImpl: (function () {
            var $195 = Data_Maybe.maybe(Foreign.unsafeToForeign(Data_Nullable.toNullable(Data_Maybe.Nothing.value)))(writeImpl(dictWriteForeign));
            return function ($196) {
                return $195(Data_Nullable.toMaybe($196));
            };
        })()
    };
};
var writeForeignInt = {
    writeImpl: Foreign.unsafeToForeign
};
var writeForeignForeign = {
    writeImpl: /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn)
};
var writeForeignChar = {
    writeImpl: Foreign.unsafeToForeign
};
var writeForeignBoolean = {
    writeImpl: Foreign.unsafeToForeign
};
var writeForeignArray = function (dictWriteForeign) {
    var writeImpl1 = writeImpl(dictWriteForeign);
    return {
        writeImpl: function (xs) {
            return Foreign.unsafeToForeign(map(writeImpl1)(xs));
        }
    };
};
var writeForeignNEArray = function (dictWriteForeign) {
    var writeImpl1 = writeImpl(writeForeignArray(dictWriteForeign));
    return {
        writeImpl: function (a) {
            return writeImpl1(Data_Array_NonEmpty.toArray(a));
        }
    };
};
var write = function (dictWriteForeign) {
    return writeImpl(dictWriteForeign);
};
var unsafeStringify = $foreign["_unsafeStringify"];
var $$undefined = $foreign["_undefined"];
var writeForeignMaybe = function (dictWriteForeign) {
    return {
        writeImpl: Data_Maybe.maybe($$undefined)(writeImpl(dictWriteForeign))
    };
};
var recordWriteForeign = function () {
    return function (dictWriteForeignFields) {
        var writeImplFields1 = writeImplFields(dictWriteForeignFields);
        return {
            writeImpl: function (rec) {
                var steps = writeImplFields1(Type_Proxy["Proxy"].value)(rec);
                return Foreign.unsafeToForeign(Record_Builder.build(steps)({}));
            }
        };
    };
};
var readVariantNil = {
    readVariantImpl: function (v) {
        return function (v1) {
            return fail(new Foreign.ForeignError("Unable to match any variant member."));
        };
    }
};
var readVariantImpl = function (dict) {
    return dict.readVariantImpl;
};
var readString = {
    readImpl: /* #__PURE__ */ Foreign.readString(Data_Identity.monadIdentity)
};
var readNumber = {
    readImpl: /* #__PURE__ */ Foreign.readNumber(Data_Identity.monadIdentity)
};
var readInt = {
    readImpl: /* #__PURE__ */ Foreign.readInt(Data_Identity.monadIdentity)
};
var readImpl = function (dict) {
    return dict.readImpl;
};
var readMaybe = function (dictReadForeign) {
    return {
        readImpl: (function () {
            var readNullOrUndefined = function (v) {
                return function (v1) {
                    if (Foreign.isNull(v1) || Foreign.isUndefined(v1)) {
                        return pure(Data_Maybe.Nothing.value);
                    };
                    return map1(Data_Maybe.Just.create)(v(v1));
                };
            };
            return readNullOrUndefined(readImpl(dictReadForeign));
        })()
    };
};
var readNullable = function (dictReadForeign) {
    var readImpl2 = readImpl(dictReadForeign);
    return {
        readImpl: function (o) {
            var reformat = function (error) {
                if (error instanceof Foreign.TypeMismatch) {
                    return new Foreign.TypeMismatch("Nullable " + error.value0, error.value1);
                };
                return error;
            };
            return Control_Monad_Except.withExcept(map2(reformat))(bindFlipped(map3(map1(Data_Nullable.toNullable))(traverse(readImpl2)))(readNull(o)));
        }
    };
};
var readObject = function (dictReadForeign) {
    return {
        readImpl: (function () {
            var readObject$prime = function (value) {
                if (Foreign.tagOf(value) === "Object") {
                    return pure(Foreign.unsafeFromForeign(value));
                };
                if (Data_Boolean.otherwise) {
                    return fail(new Foreign.TypeMismatch("Object", Foreign.tagOf(value)));
                };
                throw new Error("Failed pattern match at SimpleJSON (line 204, column 5 - line 204, column 49): " + [ value.constructor.name ]);
            };
            return composeKleisliFlipped((function () {
                var $197 = Foreign_Object.mapWithKey(Data_Function["const"](readImpl(dictReadForeign)));
                return function ($198) {
                    return sequence($197($198));
                };
            })())(readObject$prime);
        })()
    };
};
var readForeignVariant = function () {
    return function (dictReadForeignVariant) {
        var readVariantImpl1 = readVariantImpl(dictReadForeignVariant);
        return {
            readImpl: function (o) {
                return readVariantImpl1(Type_Proxy["Proxy"].value)(o);
            }
        };
    };
};
var readForeign = {
    readImpl: pure
};
var readFieldsNil = {
    getFields: function (v) {
        return function (v1) {
            return pure(identity);
        };
    }
};
var readChar = {
    readImpl: /* #__PURE__ */ Foreign.readChar(Data_Identity.monadIdentity)
};
var readBoolean = {
    readImpl: /* #__PURE__ */ Foreign.readBoolean(Data_Identity.monadIdentity)
};
var readArray = function (dictReadForeign) {
    var readImpl2 = readImpl(dictReadForeign);
    return {
        readImpl: (function () {
            var readAtIdx = function (i) {
                return function (f) {
                    return Control_Monad_Except.withExcept(map2(Foreign.ErrorAtIndex.create(i)))(readImpl2(f));
                };
            };
            return composeKleisliFlipped(traverseWithIndex(readAtIdx))(readArray1);
        })()
    };
};
var readForeignNEArray = function (dictReadForeign) {
    var readImpl2 = readImpl(readArray(dictReadForeign));
    return {
        readImpl: function (f) {
            return bind(readImpl2(f))(function (v) {
                return except(Data_Either.note(Data_List_NonEmpty.singleton(new Foreign.ForeignError("Nonempty array expected, got empty array")))(Data_Array_NonEmpty.fromArray(v)));
            });
        }
    };
};
var read$prime = function (dictReadForeign) {
    return readImpl(dictReadForeign);
};
var read = function (dictReadForeign) {
    var $199 = readImpl(dictReadForeign);
    return function ($200) {
        return Control_Monad_Except.runExcept($199($200));
    };
};
var readAsForeign = function (dictReadForeign) {
    var $201 = read(dictReadForeign);
    return function ($202) {
        return $201(Foreign.unsafeToForeign($202));
    };
};
var read_ = function (dictReadForeign) {
    var $203 = read(dictReadForeign);
    return function ($204) {
        return Data_Either.hush($203($204));
    };
};
var parseJSON = /* #__PURE__ */ (function () {
    var $205 = Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)((function () {
        var $208 = Control_Applicative.pure(Data_List_Types.applicativeNonEmptyList);
        return function ($209) {
            return $208(Foreign.ForeignError.create(Effect_Exception.message($209)));
        };
    })());
    var $206 = Effect_Uncurried.runEffectFn1($foreign["_parseJSON"]);
    return function ($207) {
        return Control_Monad_Except_Trans.ExceptT(Data_Identity.Identity($205(Effect_Unsafe.unsafePerformEffect(Effect_Exception["try"]($206($207))))));
    };
})();
var readJSON = function (dictReadForeign) {
    var $210 = composeKleisliFlipped(readImpl(dictReadForeign))(parseJSON);
    return function ($211) {
        return Control_Monad_Except.runExcept($210($211));
    };
};
var readJSON_ = function (dictReadForeign) {
    var $212 = readJSON(dictReadForeign);
    return function ($213) {
        return Data_Either.hush($212($213));
    };
};
var readJSON$prime = function (dictReadForeign) {
    return composeKleisliFlipped(readImpl(dictReadForeign))(parseJSON);
};
var nilWriteForeignVariant = {
    writeVariantImpl: function (v) {
        return function (v1) {
            return Partial_Unsafe.unsafeCrashWith("Variant was not able to be writen row WriteForeign.");
        };
    }
};
var nilWriteForeignFields = {
    writeImplFields: function (v) {
        return function (v1) {
            return identity;
        };
    }
};
var getFields = function (dict) {
    return dict.getFields;
};
var readRecord = function () {
    return function (dictReadForeignFields) {
        var getFields1 = getFields(dictReadForeignFields);
        return {
            readImpl: function (o) {
                return map1(Data_Function.flip(Record_Builder.build)({}))(getFields1(Type_Proxy["Proxy"].value)(o));
            }
        };
    };
};
var consWriteForeignVariant = function (dictIsSymbol) {
    var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
    var on1 = on(dictIsSymbol);
    return function (dictWriteForeign) {
        var writeImpl1 = writeImpl(dictWriteForeign);
        return function () {
            return function (dictWriteForeignVariant) {
                var writeVariantImpl1 = writeVariantImpl(dictWriteForeignVariant);
                return {
                    writeVariantImpl: function (v) {
                        return function (variant) {
                            var writeVariant = function (value) {
                                return Foreign.unsafeToForeign({
                                    type: reflectSymbol(Type_Proxy["Proxy"].value),
                                    value: writeImpl1(value)
                                });
                            };
                            return on1(Type_Proxy["Proxy"].value)(writeVariant)(writeVariantImpl1(Type_Proxy["Proxy"].value))(variant);
                        };
                    }
                };
            };
        };
    };
};
var consWriteForeignFields = function (dictIsSymbol) {
    var get = Record.get(dictIsSymbol)();
    var insert1 = insert(dictIsSymbol);
    return function (dictWriteForeign) {
        var writeImpl1 = writeImpl(dictWriteForeign);
        return function (dictWriteForeignFields) {
            var writeImplFields1 = writeImplFields(dictWriteForeignFields);
            return function () {
                return function () {
                    return function () {
                        return {
                            writeImplFields: function (v) {
                                return function (rec) {
                                    var rest = writeImplFields1(Type_Proxy["Proxy"].value)(rec);
                                    var value = writeImpl1(get(Type_Proxy["Proxy"].value)(rec));
                                    var result = compose1(insert1(Type_Proxy["Proxy"].value)(value))(rest);
                                    return result;
                                };
                            }
                        };
                    };
                };
            };
        };
    };
};
var applyEither = function (dictSemigroup) {
    var append1 = Data_Semigroup.append(dictSemigroup);
    return function (v) {
        return function (v1) {
            if (v instanceof Data_Either.Left && v1 instanceof Data_Either.Right) {
                return new Data_Either.Left(v.value0);
            };
            if (v instanceof Data_Either.Left && v1 instanceof Data_Either.Left) {
                return new Data_Either.Left(append1(v.value0)(v1.value0));
            };
            if (v instanceof Data_Either.Right && v1 instanceof Data_Either.Left) {
                return new Data_Either.Left(v1.value0);
            };
            if (v instanceof Data_Either.Right && v1 instanceof Data_Either.Right) {
                return new Data_Either.Right(v.value0(v1.value0));
            };
            throw new Error("Failed pattern match at SimpleJSON (line 251, column 1 - line 251, column 90): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var exceptTApply = function (dictSemigroup) {
    var applyEither1 = applyEither(dictSemigroup);
    return function (dictApplicative) {
        var Apply0 = dictApplicative.Apply0();
        var apply = Control_Apply.apply(Apply0);
        var map4 = Data_Functor.map(Apply0.Functor0());
        return function (fun) {
            return function (a) {
                return apply(map4(applyEither1)(Control_Monad_Except_Trans.runExceptT(fun)))(Control_Monad_Except_Trans.runExceptT(a));
            };
        };
    };
};
var exceptTApply1 = /* #__PURE__ */ exceptTApply(Data_List_Types.semigroupNonEmptyList)(Data_Identity.applicativeIdentity);
var readFieldsCons = function (dictIsSymbol) {
    var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
    var insert1 = insert(dictIsSymbol);
    return function (dictReadForeign) {
        var readImpl2 = readImpl(dictReadForeign);
        return function (dictReadForeignFields) {
            var getFields1 = getFields(dictReadForeignFields);
            return function () {
                return function () {
                    return {
                        getFields: function (v) {
                            return function (obj) {
                                var rest = getFields1(Type_Proxy["Proxy"].value)(obj);
                                var name = reflectSymbol(Type_Proxy["Proxy"].value);
                                var withExcept$prime = Control_Monad_Except.withExcept(map2(Foreign.ErrorAtProperty.create(name)));
                                var first = bind(withExcept$prime(bindFlipped(readImpl2)(readProp(name)(obj))))(function (value) {
                                    return pure(insert1(Type_Proxy["Proxy"].value)(value));
                                });
                                return exceptTApply1(map1(compose1)(first))(rest);
                            };
                        }
                    };
                };
            };
        };
    };
};
var readImpl1 = /* #__PURE__ */ readImpl(/* #__PURE__ */ readRecord()(/* #__PURE__ */ readFieldsCons({
    reflectSymbol: function () {
        return "type";
    }
})(readString)(/* #__PURE__ */ readFieldsCons({
    reflectSymbol: function () {
        return "value";
    }
})(readForeign)(readFieldsNil)()())()()));
var readVariantCons = function (dictIsSymbol) {
    var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
    var inj1 = inj(dictIsSymbol);
    return function (dictReadForeign) {
        var readImpl2 = readImpl(dictReadForeign);
        return function () {
            return function (dictReadForeignVariant) {
                var readVariantImpl1 = readVariantImpl(dictReadForeignVariant);
                return {
                    readVariantImpl: function (v) {
                        return function (o) {
                            var name = reflectSymbol(Type_Proxy["Proxy"].value);
                            return alt(bind(readImpl1(o))(function (v1) {
                                var $189 = v1.type === name;
                                if ($189) {
                                    return bind(readImpl2(v1.value))(function (v2) {
                                        return pure(inj1(Type_Proxy["Proxy"].value)(v2));
                                    });
                                };
                                return fail(Foreign.ForeignError.create("Did not match variant tag " + name));
                            }))(readVariantImpl1(Type_Proxy["Proxy"].value)(o));
                        };
                    }
                };
            };
        };
    };
};
export {
    readJSON,
    readJSON$prime,
    readJSON_,
    writeJSON,
    write,
    read,
    read$prime,
    read_,
    readAsForeign,
    parseJSON,
    $$undefined as undefined,
    unsafeStringify,
    readImpl,
    getFields,
    readVariantImpl,
    writeImpl,
    writeImplFields,
    writeVariantImpl,
    readForeign,
    readChar,
    readNumber,
    readInt,
    readString,
    readBoolean,
    readArray,
    readMaybe,
    readNullable,
    readObject,
    readRecord,
    readFieldsCons,
    readFieldsNil,
    readForeignVariant,
    readVariantNil,
    readVariantCons,
    writeForeignForeign,
    writeForeignString,
    writeForeignInt,
    writeForeignChar,
    writeForeignNumber,
    writeForeignBoolean,
    writeForeignArray,
    writeForeignMaybe,
    writeForeignNullable,
    writeForeignObject,
    recordWriteForeign,
    consWriteForeignFields,
    nilWriteForeignFields,
    writeForeignVariant,
    nilWriteForeignVariant,
    consWriteForeignVariant,
    readForeignNEArray,
    writeForeignNEArray
};
