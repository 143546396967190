// Generated by purs version 0.15.7
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Filterable from "../Data.Filterable/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Lens_Fold from "../Data.Lens.Fold/index.js";
import * as Data_Lens_Getter from "../Data.Lens.Getter/index.js";
import * as Data_Lens_Internal_Forget from "../Data.Lens.Internal.Forget/index.js";
import * as Data_Lens_Traversal from "../Data.Lens.Traversal/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid_Endo from "../Data.Monoid.Endo/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_String_CodePoints from "../Data.String.CodePoints/index.js";
import * as Data_String_Utils from "../Data.String.Utils/index.js";
import * as Halogen_HTML_Core from "../Halogen.HTML.Core/index.js";
import * as Halogen_HTML_Elements from "../Halogen.HTML.Elements/index.js";
import * as HalogenUtils from "../HalogenUtils/index.js";
import * as TablatureDocument from "../TablatureDocument/index.js";
import * as Utils from "../Utils/index.js";
var fromFoldable = /* #__PURE__ */ Data_Array.fromFoldable(Data_List_Types.foldableList);
var append = /* #__PURE__ */ Data_Semigroup.append(Data_List_Types.semigroupList);
var map = /* #__PURE__ */ Data_Functor.map(Data_List_Types.functorList);
var foldr = /* #__PURE__ */ Data_Foldable.foldr(Data_List_Types.foldableList);
var append1 = /* #__PURE__ */ Data_Semigroup.append(Data_Semigroup.semigroupString);
var print = /* #__PURE__ */ Utils.print(TablatureDocument.printChordMod);
var _elem = /* #__PURE__ */ TablatureDocument["_elem"](Data_Lens_Internal_Forget.strongForget);
var _mods = /* #__PURE__ */ TablatureDocument["_mods"](Data_Lens_Internal_Forget.strongForget);
var filterMap = /* #__PURE__ */ Data_Filterable.filterMap(Data_Filterable.filterableArray);
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var print1 = /* #__PURE__ */ Utils.print(TablatureDocument.printNoteLetter);
var _root = /* #__PURE__ */ TablatureDocument["_root"](Data_Lens_Internal_Forget.strongForget);
var _letter = /* #__PURE__ */ TablatureDocument["_letter"](Data_Lens_Internal_Forget.strongForget);
var _mod = /* #__PURE__ */ TablatureDocument["_mod"](Data_Lens_Internal_Forget.strongForget);
var _type = /* #__PURE__ */ TablatureDocument["_type"](Data_Lens_Internal_Forget.strongForget);
var mapFlipped = /* #__PURE__ */ Data_Functor.mapFlipped(Data_Maybe.functorMaybe);
var _bass = /* #__PURE__ */ TablatureDocument["_bass"](Data_Lens_Internal_Forget.strongForget);
var max = /* #__PURE__ */ Data_Ord.max(Data_Ord.ordInt);
var _spaceSuffix = /* #__PURE__ */ TablatureDocument["_spaceSuffix"](Data_Lens_Internal_Forget.strongForget);
var traversed = /* #__PURE__ */ Data_Lens_Traversal.traversed(Data_List_Types.traversableList);
var minimumOf = /* #__PURE__ */ Data_Lens_Fold.minimumOf(Data_Ord.ordInt);
var wanderForget = /* #__PURE__ */ Data_Lens_Internal_Forget.wanderForget(/* #__PURE__ */ Data_Monoid_Endo.monoidEndo(Control_Category.categoryFn));
var renderTablatureDocument = function (doc) {
    var renderWithClass = function (string) {
        return function (klass) {
            return Halogen_HTML_Elements.span([ HalogenUtils.classString(klass) ])([ Halogen_HTML_Core.text(string) ]);
        };
    };
    var renderTitleLineElem = function (v) {
        if (v instanceof TablatureDocument.Title) {
            return renderWithClass(v.value0)("tabTitle");
        };
        if (v instanceof TablatureDocument.TitleOther) {
            return renderWithClass(v.value0)("tabText");
        };
        throw new Error("Failed pattern match at TablatureRenderer (line 32, column 3 - line 32, column 73): " + [ v.constructor.name ]);
    };
    var renderLine$prime = function (line) {
        return function (lineRenderer) {
            return Halogen_HTML_Elements.span_(fromFoldable(append(map(lineRenderer)(line))(new Data_List_Types.Cons(HalogenUtils.renderLineEnding, Data_List_Types.Nil.value))));
        };
    };
    var renderHeaderLineElem = function (v) {
        if (v instanceof TablatureDocument.Header) {
            return renderWithClass(v.value0)("tabHeader");
        };
        if (v instanceof TablatureDocument.HeaderSuffix) {
            return renderWithClass(v.value0)("tabText");
        };
        throw new Error("Failed pattern match at TablatureRenderer (line 48, column 3 - line 48, column 76): " + [ v.constructor.name ]);
    };
    var renderChordLegendElem = function (v) {
        if (v instanceof TablatureDocument.ChordFret) {
            return renderWithClass(v.value0)("tabFret");
        };
        if (v instanceof TablatureDocument.ChordSpecial) {
            return renderWithClass(v.value0)("tabSpecial");
        };
        throw new Error("Failed pattern match at TablatureRenderer (line 53, column 3 - line 53, column 58): " + [ v.constructor.name ]);
    };
    var createFontSizeCompensation = function (string) {
        return Halogen_HTML_Elements.span([ HalogenUtils.classString("fontsize-compensation") ])([ Halogen_HTML_Core.text(Data_Maybe.fromMaybe("")(Data_String_Utils.repeat(Data_String_CodePoints.length(string))(" "))) ]);
    };
    var renderChord = function (chord) {
        var chordMods = foldr(append1)("")(map(print)(Data_Lens_Getter.view(function ($81) {
            return _elem(_mods($81));
        })(chord)));
        return Halogen_HTML_Elements.span([ HalogenUtils.classString("tabChord") ])(filterMap(identity)([ new Data_Maybe.Just(Halogen_HTML_Core.text(print1(Data_Lens_Getter.view(function ($82) {
            return _elem(_root(_letter($82)));
        })(chord)))), new Data_Maybe.Just(Halogen_HTML_Elements.sub_([ Halogen_HTML_Core.text(Data_Lens_Getter.view(function ($83) {
            return _elem(_root(_mod($83)));
        })(chord)) ])), new Data_Maybe.Just(Halogen_HTML_Core.text(Data_Lens_Getter.view(function ($84) {
            return _elem(_type($84));
        })(chord))), new Data_Maybe.Just(Halogen_HTML_Elements.sup_([ Halogen_HTML_Core.text(chordMods) ])), mapFlipped(Data_Lens_Getter.view(function ($85) {
            return _elem(_bass($85));
        })(chord))(function (v) {
            return Halogen_HTML_Core.text("/" + print1(v.letter));
        }), mapFlipped(Data_Lens_Getter.view(function ($86) {
            return _elem(_bass($86));
        })(chord))(function (v) {
            return Halogen_HTML_Elements.sub_([ Halogen_HTML_Core.text(v.mod) ]);
        }), new Data_Maybe.Just(Halogen_HTML_Core.text(Data_Maybe.fromMaybe("")(Data_String_Utils.repeat(max(1)(Data_Lens_Getter.view(_spaceSuffix)(chord)))(" ")))), new Data_Maybe.Just(Halogen_HTML_Elements.sub_([ createFontSizeCompensation(Data_Lens_Getter.view(function ($87) {
            return _elem(_root(_mod($87)));
        })(chord)) ])), new Data_Maybe.Just(Halogen_HTML_Elements.sup_([ createFontSizeCompensation(chordMods) ])), mapFlipped(Data_Lens_Getter.view(function ($88) {
            return _elem(_bass($88));
        })(chord))(function (v) {
            return Halogen_HTML_Elements.sub_([ createFontSizeCompensation(v.mod) ]);
        }) ]));
    };
    var renderChordLineElem = function (v) {
        if (v instanceof TablatureDocument.ChordComment) {
            return renderWithClass(v.value0)("tabSuffix");
        };
        if (v instanceof TablatureDocument.ChordLineChord) {
            return renderChord(v.value0);
        };
        throw new Error("Failed pattern match at TablatureRenderer (line 50, column 3 - line 50, column 81): " + [ v.constructor.name ]);
    };
    var renderTextLineElem = function (v) {
        if (v instanceof TablatureDocument.Text) {
            return renderWithClass(v.value0)("tabText");
        };
        if (v instanceof TablatureDocument.Spaces) {
            return renderWithClass(v.value0)("tabText");
        };
        if (v instanceof TablatureDocument.TextLineChord) {
            return renderChord(v.value0);
        };
        if (v instanceof TablatureDocument.ChordLegend) {
            return Halogen_HTML_Elements.span_(fromFoldable(map(renderChordLegendElem)(v.value0)));
        };
        throw new Error("Failed pattern match at TablatureRenderer (line 35, column 3 - line 35, column 52): " + [ v.constructor.name ]);
    };
    var _tuningSpaceSuffixes = function (dictWander) {
        var traversed1 = traversed(dictWander);
        var Choice1 = dictWander.Choice1();
        var $89 = TablatureDocument["_TablatureLine"](Choice1);
        var $90 = TablatureDocument["_Tuning"](Choice1);
        var $91 = TablatureDocument["_spaceSuffix"](dictWander.Strong0());
        return function ($92) {
            return traversed1($89(traversed1($90($91($92)))));
        };
    };
    var minTuningSpace = Data_Maybe.fromMaybe(0)(minimumOf(_tuningSpaceSuffixes(wanderForget))(doc));
    var renderTuning = function (spacedNote) {
        return Halogen_HTML_Elements.span([ HalogenUtils.classString("tabChord") ])([ Halogen_HTML_Core.text(print1(Data_Lens_Getter.view(function ($93) {
            return _elem(_letter($93));
        })(spacedNote))), Halogen_HTML_Elements.sub_([ Halogen_HTML_Core.text(Data_Lens_Getter.view(function ($94) {
            return _elem(_mod($94));
        })(spacedNote)) ]), Halogen_HTML_Core.text(Data_Maybe.fromMaybe("")(Data_String_Utils.repeat(Data_Lens_Getter.view(_spaceSuffix)(spacedNote) - minTuningSpace | 0)(" "))), Halogen_HTML_Elements.sub_([ createFontSizeCompensation(Data_Lens_Getter.view(function ($95) {
            return _elem(_mod($95));
        })(spacedNote)) ]) ]);
    };
    var renderTablatureLineElem = function (v) {
        if (v instanceof TablatureDocument.Prefix) {
            return renderWithClass(v.value0)("tabPrefix");
        };
        if (v instanceof TablatureDocument.Tuning) {
            return renderTuning(v.value0);
        };
        if (v instanceof TablatureDocument.Suffix) {
            return renderWithClass(v.value0)("tabSuffix");
        };
        if (v instanceof TablatureDocument.Special) {
            return renderWithClass(v.value0)("tabSpecial");
        };
        if (v instanceof TablatureDocument.TimelineSep) {
            return renderWithClass(v.value0)("tabTimeline");
        };
        if (v instanceof TablatureDocument.TimelineConnection) {
            return renderWithClass(v.value0)("tabTimeline");
        };
        if (v instanceof TablatureDocument.TimelineSpace) {
            return renderWithClass(v.value0)("tabTimeline");
        };
        if (v instanceof TablatureDocument.Fret) {
            return renderWithClass(v.value0)("tabFret");
        };
        throw new Error("Failed pattern match at TablatureRenderer (line 40, column 3 - line 40, column 79): " + [ v.constructor.name ]);
    };
    var renderLine = function (v) {
        if (v instanceof TablatureDocument.TitleLine) {
            return renderLine$prime(v.value0)(renderTitleLineElem);
        };
        if (v instanceof TablatureDocument.TextLine) {
            return renderLine$prime(v.value0)(renderTextLineElem);
        };
        if (v instanceof TablatureDocument.ChordLine) {
            return renderLine$prime(v.value0)(renderChordLineElem);
        };
        if (v instanceof TablatureDocument.HeaderLine) {
            return renderLine$prime(v.value0)(renderHeaderLineElem);
        };
        if (v instanceof TablatureDocument.TablatureLine) {
            return renderLine$prime(v.value0)(renderTablatureLineElem);
        };
        throw new Error("Failed pattern match at TablatureRenderer (line 22, column 3 - line 22, column 53): " + [ v.constructor.name ]);
    };
    return map(renderLine)(doc);
};
export {
    renderTablatureDocument
};
