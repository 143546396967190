// Generated by purs version 0.15.7
import * as AppState from "../AppState/index.js";
import * as AppUrl from "../AppUrl/index.js";
import * as AutoscrollSpeed from "../AutoscrollSpeed/index.js";
import * as Cache from "../Cache/index.js";
import * as Clipboard from "../Clipboard/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Maybe_Trans from "../Control.Monad.Maybe.Trans/index.js";
import * as CuttlyUrlShortener from "../CuttlyUrlShortener/index.js";
import * as Data_Enum from "../Data.Enum/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as LocationString from "../LocationString/index.js";
import * as TablatureDocument from "../TablatureDocument/index.js";
import * as UGScraper from "../UGScraper/index.js";
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit);
var eq = /* #__PURE__ */ Data_Eq.eq(AppState.eqMode);
var succ = /* #__PURE__ */ Data_Enum.succ(AutoscrollSpeed.enumAutoscrollSpeed);
var pred = /* #__PURE__ */ Data_Enum.pred(AutoscrollSpeed.enumAutoscrollSpeed);
var createShortUrl = /* #__PURE__ */ CuttlyUrlShortener.createShortUrl(Effect_Aff_Class.monadAffAff);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var eq2 = /* #__PURE__ */ Data_Eq.eq(AppState.eqActiveMenu);
var Initialize = /* #__PURE__ */ (function () {
    function Initialize() {

    };
    Initialize.value = new Initialize();
    return Initialize;
})();
var ClickFileMenu = /* #__PURE__ */ (function () {
    function ClickFileMenu() {

    };
    ClickFileMenu.value = new ClickFileMenu();
    return ClickFileMenu;
})();
var ClickSettingsMenu = /* #__PURE__ */ (function () {
    function ClickSettingsMenu() {

    };
    ClickSettingsMenu.value = new ClickSettingsMenu();
    return ClickSettingsMenu;
})();
var ClickNoMenu = /* #__PURE__ */ (function () {
    function ClickNoMenu() {

    };
    ClickNoMenu.value = new ClickNoMenu();
    return ClickNoMenu;
})();
var ToggleEditMode = /* #__PURE__ */ (function () {
    function ToggleEditMode() {

    };
    ToggleEditMode.value = new ToggleEditMode();
    return ToggleEditMode;
})();
var ToggleTabNormalization = /* #__PURE__ */ (function () {
    function ToggleTabNormalization() {

    };
    ToggleTabNormalization.value = new ToggleTabNormalization();
    return ToggleTabNormalization;
})();
var ToggleTabDozenalization = /* #__PURE__ */ (function () {
    function ToggleTabDozenalization() {

    };
    ToggleTabDozenalization.value = new ToggleTabDozenalization();
    return ToggleTabDozenalization;
})();
var ToggleChordNormalization = /* #__PURE__ */ (function () {
    function ToggleChordNormalization() {

    };
    ToggleChordNormalization.value = new ToggleChordNormalization();
    return ToggleChordNormalization;
})();
var ToggleChordDozenalization = /* #__PURE__ */ (function () {
    function ToggleChordDozenalization() {

    };
    ToggleChordDozenalization.value = new ToggleChordDozenalization();
    return ToggleChordDozenalization;
})();
var CreateShortUrl = /* #__PURE__ */ (function () {
    function CreateShortUrl() {

    };
    CreateShortUrl.value = new CreateShortUrl();
    return CreateShortUrl;
})();
var ToggleAutoscroll = /* #__PURE__ */ (function () {
    function ToggleAutoscroll() {

    };
    ToggleAutoscroll.value = new ToggleAutoscroll();
    return ToggleAutoscroll;
})();
var IncreaseAutoscrollSpeed = /* #__PURE__ */ (function () {
    function IncreaseAutoscrollSpeed() {

    };
    IncreaseAutoscrollSpeed.value = new IncreaseAutoscrollSpeed();
    return IncreaseAutoscrollSpeed;
})();
var DecreaseAutoscrollSpeed = /* #__PURE__ */ (function () {
    function DecreaseAutoscrollSpeed() {

    };
    DecreaseAutoscrollSpeed.value = new DecreaseAutoscrollSpeed();
    return DecreaseAutoscrollSpeed;
})();
var IncreaseTransposition = /* #__PURE__ */ (function () {
    function IncreaseTransposition() {

    };
    IncreaseTransposition.value = new IncreaseTransposition();
    return IncreaseTransposition;
})();
var DecreaseTransposition = /* #__PURE__ */ (function () {
    function DecreaseTransposition() {

    };
    DecreaseTransposition.value = new DecreaseTransposition();
    return DecreaseTransposition;
})();
var FlatNoteOrientation = /* #__PURE__ */ (function () {
    function FlatNoteOrientation() {

    };
    FlatNoteOrientation.value = new FlatNoteOrientation();
    return FlatNoteOrientation;
})();
var SharpNoteOrientation = /* #__PURE__ */ (function () {
    function SharpNoteOrientation() {

    };
    SharpNoteOrientation.value = new SharpNoteOrientation();
    return SharpNoteOrientation;
})();
var DefaultNoteOrientation = /* #__PURE__ */ (function () {
    function DefaultNoteOrientation() {

    };
    DefaultNoteOrientation.value = new DefaultNoteOrientation();
    return DefaultNoteOrientation;
})();
var ToggleSearch = /* #__PURE__ */ (function () {
    function ToggleSearch() {

    };
    ToggleSearch.value = new ToggleSearch();
    return ToggleSearch;
})();
var SearchInput = /* #__PURE__ */ (function () {
    function SearchInput(value0) {
        this.value0 = value0;
    };
    SearchInput.create = function (value0) {
        return new SearchInput(value0);
    };
    return SearchInput;
})();
var ImportFromUrl = /* #__PURE__ */ (function () {
    function ImportFromUrl(value0) {
        this.value0 = value0;
    };
    ImportFromUrl.create = function (value0) {
        return new ImportFromUrl(value0);
    };
    return ImportFromUrl;
})();
var NoAction = /* #__PURE__ */ (function () {
    function NoAction() {

    };
    NoAction.value = new NoAction();
    return NoAction;
})();
var toggleTabNormalization = function (dictMonadAff) {
    var bind = Control_Bind.bind(((dictMonadAff.MonadEffect0()).Monad0()).Bind1());
    var tabNormalizationEnabledCache = AppState.tabNormalizationEnabledCache(dictMonadAff);
    return function (dictMonadState) {
        var tabNormalizationEnabledCache1 = tabNormalizationEnabledCache(dictMonadState);
        var write = Cache.write(dictMonadState);
        return bind(Cache.read(dictMonadState)(tabNormalizationEnabledCache1))(function (tabNormalizationEnabled) {
            return write(tabNormalizationEnabledCache1)(!tabNormalizationEnabled);
        });
    };
};
var toggleTabDozenalization = function (dictMonadAff) {
    var Monad0 = (dictMonadAff.MonadEffect0()).Monad0();
    var bind = Control_Bind.bind(Monad0.Bind1());
    var ignoreDozenalizationCache = AppState.ignoreDozenalizationCache(dictMonadAff);
    var pure1 = Control_Applicative.pure(Monad0.Applicative0());
    var tabDozenalizationEnabledCache = AppState.tabDozenalizationEnabledCache(dictMonadAff);
    return function (dictMonadState) {
        var read = Cache.read(dictMonadState);
        var tabDozenalizationEnabledCache1 = tabDozenalizationEnabledCache(dictMonadState);
        var write = Cache.write(dictMonadState);
        return bind(read(ignoreDozenalizationCache(dictMonadState)))(function (ignoreDozenalization) {
            if (ignoreDozenalization) {
                return pure1(Data_Unit.unit);
            };
            return bind(read(tabDozenalizationEnabledCache1))(function (tabDozenalizationEnabled) {
                return write(tabDozenalizationEnabledCache1)(!tabDozenalizationEnabled);
            });
        });
    };
};
var toggleSearch = function (dictMonadAff) {
    var Bind1 = ((dictMonadAff.MonadEffect0()).Monad0()).Bind1();
    var bind = Control_Bind.bind(Bind1);
    var tablatureTextCache = AppState.tablatureTextCache(dictMonadAff);
    var discard1 = discard(Bind1);
    return function (dictMonadState) {
        var read = Cache.read(dictMonadState);
        var tablatureTextCache1 = tablatureTextCache(dictMonadState);
        var setState = AppState.setState(dictMonadState);
        return bind(AppState.viewState(dictMonadState)(function (dictStrong) {
            return AppState["_mode"](dictStrong);
        }))(function (currentMode) {
            return bind(read(tablatureTextCache1))(function (tablatureText) {
                return discard1(setState(function (dictStrong) {
                    return AppState["_activeMenu"](dictStrong);
                })(AppState.NoMenu.value))(function () {
                    return setState(function (dictStrong) {
                        return AppState["_mode"](dictStrong);
                    })((function () {
                        var $229 = eq(currentMode)(AppState.SearchMode.value);
                        if ($229) {
                            var $230 = tablatureText === "";
                            if ($230) {
                                return AppState.EditMode.value;
                            };
                            return AppState.ViewMode.value;
                        };
                        return AppState.SearchMode.value;
                    })());
                });
            });
        });
    };
};
var toggleChordNormalization = function (dictMonadAff) {
    var bind = Control_Bind.bind(((dictMonadAff.MonadEffect0()).Monad0()).Bind1());
    var chordNormalizationEnabledCache = AppState.chordNormalizationEnabledCache(dictMonadAff);
    return function (dictMonadState) {
        var chordNormalizationEnabledCache1 = chordNormalizationEnabledCache(dictMonadState);
        var write = Cache.write(dictMonadState);
        return bind(Cache.read(dictMonadState)(chordNormalizationEnabledCache1))(function (chordNormalizationEnabled) {
            return write(chordNormalizationEnabledCache1)(!chordNormalizationEnabled);
        });
    };
};
var toggleChordDozenalization = function (dictMonadAff) {
    var Monad0 = (dictMonadAff.MonadEffect0()).Monad0();
    var bind = Control_Bind.bind(Monad0.Bind1());
    var ignoreDozenalizationCache = AppState.ignoreDozenalizationCache(dictMonadAff);
    var pure1 = Control_Applicative.pure(Monad0.Applicative0());
    var chordDozenalizationEnabledCache = AppState.chordDozenalizationEnabledCache(dictMonadAff);
    return function (dictMonadState) {
        var read = Cache.read(dictMonadState);
        var chordDozenalizationEnabledCache1 = chordDozenalizationEnabledCache(dictMonadState);
        var write = Cache.write(dictMonadState);
        return bind(read(ignoreDozenalizationCache(dictMonadState)))(function (ignoreDozenalization) {
            if (ignoreDozenalization) {
                return pure1(Data_Unit.unit);
            };
            return bind(read(chordDozenalizationEnabledCache1))(function (chordDozenalizationEnabled) {
                return write(chordDozenalizationEnabledCache1)(!chordDozenalizationEnabled);
            });
        });
    };
};
var setNoteOrientation = function (dictMonadAff) {
    var bind = Control_Bind.bind(((dictMonadAff.MonadEffect0()).Monad0()).Bind1());
    var urlParamsCache = AppState.urlParamsCache(dictMonadAff);
    return function (noteOrientation) {
        return function (dictMonadState) {
            var urlParamsCache1 = urlParamsCache(dictMonadState);
            var write = Cache.write(dictMonadState);
            return bind(Cache.read(dictMonadState)(urlParamsCache1))(function (urlParams) {
                return write(urlParamsCache1)({
                    transposition: urlParams.transposition,
                    noteOrientation: noteOrientation
                });
            });
        };
    };
};
var searchInput = function (dictMonadAff) {
    var discard1 = discard(((dictMonadAff.MonadEffect0()).Monad0()).Bind1());
    var execSearch = UGScraper.execSearch(dictMonadAff);
    return function (dictMonadState) {
        var setState = AppState.setState(dictMonadState);
        var execSearch1 = execSearch(dictMonadState);
        return function (phrase) {
            return discard1(setState(function (dictStrong) {
                return AppState["_searchPhrase"](dictStrong);
            })(new Data_Maybe.Just(phrase)))(function () {
                return execSearch1(phrase);
            });
        };
    };
};
var initialize = function (dictMonadAff) {
    var bind = Control_Bind.bind(((dictMonadAff.MonadEffect0()).Monad0()).Bind1());
    var tablatureTextCache = AppState.tablatureTextCache(dictMonadAff);
    return function (dictMonadState) {
        var setState = AppState.setState(dictMonadState);
        return bind(Cache.read(dictMonadState)(tablatureTextCache(dictMonadState)))(function (tablatureText) {
            var $232 = tablatureText === "";
            if ($232) {
                return setState(function (dictStrong) {
                    return AppState["_mode"](dictStrong);
                })(AppState.EditMode.value);
            };
            return setState(function (dictStrong) {
                return AppState["_mode"](dictStrong);
            })(AppState.ViewMode.value);
        });
    };
};
var increaseTransposition = function (dictMonadAff) {
    var bind = Control_Bind.bind(((dictMonadAff.MonadEffect0()).Monad0()).Bind1());
    var urlParamsCache = AppState.urlParamsCache(dictMonadAff);
    return function (dictMonadState) {
        var urlParamsCache1 = urlParamsCache(dictMonadState);
        var write = Cache.write(dictMonadState);
        return bind(Cache.read(dictMonadState)(urlParamsCache1))(function (urlParams) {
            return write(urlParamsCache1)({
                transposition: TablatureDocument.succTransposition(urlParams.transposition),
                noteOrientation: urlParams.noteOrientation
            });
        });
    };
};
var increaseAutoscrollSpeed = function (dictMonadAff) {
    var Monad0 = (dictMonadAff.MonadEffect0()).Monad0();
    var bind = Control_Bind.bind(Monad0.Bind1());
    var pure1 = Control_Applicative.pure(Monad0.Applicative0());
    return function (dictMonadState) {
        var setState = AppState.setState(dictMonadState);
        return bind(AppState.viewState(dictMonadState)(function (dictStrong) {
            return AppState["_autoscrollSpeed"](dictStrong);
        }))(function (currentSpeed) {
            var v = succ(currentSpeed);
            if (v instanceof Data_Maybe.Nothing) {
                return pure1(Data_Unit.unit);
            };
            if (v instanceof Data_Maybe.Just) {
                return setState(function (dictStrong) {
                    return AppState["_autoscrollSpeed"](dictStrong);
                })(v.value0);
            };
            throw new Error("Failed pattern match at AppActions (line 70, column 3 - line 72, column 50): " + [ v.constructor.name ]);
        });
    };
};
var importFromUrl = function (dictMonadAff) {
    var MonadEffect0 = dictMonadAff.MonadEffect0();
    var Monad0 = MonadEffect0.Monad0();
    var map = Data_Functor.map(((Monad0.Bind1()).Apply0()).Functor0());
    var bindMaybeT = Control_Monad_Maybe_Trans.bindMaybeT(Monad0);
    var bind = Control_Bind.bind(bindMaybeT);
    var fetchTabFromUrl = UGScraper.fetchTabFromUrl(dictMonadAff);
    var discard1 = discard(bindMaybeT);
    var tablatureTextCache = AppState.tablatureTextCache(Effect_Aff_Class.monadAffMaybe(dictMonadAff));
    var liftEffect = Effect_Class.liftEffect(Control_Monad_Maybe_Trans.monadEffectMaybe(MonadEffect0));
    return function (dictMonadState) {
        var fetchTabFromUrl1 = fetchTabFromUrl(dictMonadState);
        var monadStateMaybeT = Control_Monad_Maybe_Trans.monadStateMaybeT(dictMonadState);
        var write = Cache.write(monadStateMaybeT);
        var tablatureTextCache1 = tablatureTextCache(monadStateMaybeT);
        var invalidate = Cache.invalidate(monadStateMaybeT);
        var setState = AppState.setState(monadStateMaybeT);
        return function (url) {
            return map(Data_Function["const"](Data_Unit.unit))(Control_Monad_Maybe_Trans.runMaybeT(bind(fetchTabFromUrl1(url))(function (tablatureText) {
                return discard1(write(tablatureTextCache1)(tablatureText))(function () {
                    return discard1(liftEffect(AppUrl.resetUrlParams))(function () {
                        return discard1(invalidate(AppState["_urlParams"]))(function () {
                            return setState(function (dictStrong) {
                                return AppState["_mode"](dictStrong);
                            })(AppState.ViewMode.value);
                        });
                    });
                });
            })));
        };
    };
};
var decreaseTransposition = function (dictMonadAff) {
    var bind = Control_Bind.bind(((dictMonadAff.MonadEffect0()).Monad0()).Bind1());
    var urlParamsCache = AppState.urlParamsCache(dictMonadAff);
    return function (dictMonadState) {
        var urlParamsCache1 = urlParamsCache(dictMonadState);
        var write = Cache.write(dictMonadState);
        return bind(Cache.read(dictMonadState)(urlParamsCache1))(function (urlParams) {
            return write(urlParamsCache1)({
                transposition: TablatureDocument.predTransposition(urlParams.transposition),
                noteOrientation: urlParams.noteOrientation
            });
        });
    };
};
var decreaseAutoscrollSpeed = function (dictMonadAff) {
    var Monad0 = (dictMonadAff.MonadEffect0()).Monad0();
    var bind = Control_Bind.bind(Monad0.Bind1());
    var pure1 = Control_Applicative.pure(Monad0.Applicative0());
    return function (dictMonadState) {
        var setState = AppState.setState(dictMonadState);
        return bind(AppState.viewState(dictMonadState)(function (dictStrong) {
            return AppState["_autoscrollSpeed"](dictStrong);
        }))(function (currentSpeed) {
            var v = pred(currentSpeed);
            if (v instanceof Data_Maybe.Nothing) {
                return pure1(Data_Unit.unit);
            };
            if (v instanceof Data_Maybe.Just) {
                return setState(function (dictStrong) {
                    return AppState["_autoscrollSpeed"](dictStrong);
                })(v.value0);
            };
            throw new Error("Failed pattern match at AppActions (line 77, column 3 - line 79, column 50): " + [ v.constructor.name ]);
        });
    };
};
var createAndCopyShortUrl = function (dictMonadAff) {
    var MonadEffect0 = dictMonadAff.MonadEffect0();
    var bind = Control_Bind.bind((MonadEffect0.Monad0()).Bind1());
    var liftEffect = Effect_Class.liftEffect(MonadEffect0);
    var liftAff = Effect_Aff_Class.liftAff(dictMonadAff);
    return function (dictMonadState) {
        return bind(liftEffect(LocationString.getLocationString))(function (longUrl) {
            return bind(liftAff(Control_Monad_Maybe_Trans.runMaybeT(createShortUrl(longUrl))))(function (maybeShortUrl) {
                return liftEffect((function () {
                    if (maybeShortUrl instanceof Data_Maybe.Just) {
                        return Clipboard.copyToClipboard(maybeShortUrl.value0);
                    };
                    if (maybeShortUrl instanceof Data_Maybe.Nothing) {
                        return pure(Data_Unit.unit);
                    };
                    throw new Error("Failed pattern match at AppActions (line 111, column 16 - line 113, column 25): " + [ maybeShortUrl.constructor.name ]);
                })());
            });
        });
    };
};
var clickSettingsMenu = function (dictMonadAff) {
    var bind = Control_Bind.bind(((dictMonadAff.MonadEffect0()).Monad0()).Bind1());
    return function (dictMonadState) {
        var setState = AppState.setState(dictMonadState);
        return bind(AppState.viewState(dictMonadState)(function (dictStrong) {
            return AppState["_activeMenu"](dictStrong);
        }))(function (activeMenu) {
            var $239 = eq2(activeMenu)(AppState.SettingsMenu.value);
            if ($239) {
                return setState(function (dictStrong) {
                    return AppState["_activeMenu"](dictStrong);
                })(AppState.NoMenu.value);
            };
            return setState(function (dictStrong) {
                return AppState["_activeMenu"](dictStrong);
            })(AppState.SettingsMenu.value);
        });
    };
};
var clickNoMenu = function (dictMonadAff) {
    return function (dictMonadState) {
        return AppState.setState(dictMonadState)(function (dictStrong) {
            return AppState["_activeMenu"](dictStrong);
        })(AppState.NoMenu.value);
    };
};
var clickFileMenu = function (dictMonadAff) {
    var bind = Control_Bind.bind(((dictMonadAff.MonadEffect0()).Monad0()).Bind1());
    return function (dictMonadState) {
        var setState = AppState.setState(dictMonadState);
        return bind(AppState.viewState(dictMonadState)(function (dictStrong) {
            return AppState["_activeMenu"](dictStrong);
        }))(function (activeMenu) {
            var $240 = eq2(activeMenu)(AppState.FileMenu.value);
            if ($240) {
                return setState(function (dictStrong) {
                    return AppState["_activeMenu"](dictStrong);
                })(AppState.NoMenu.value);
            };
            return setState(function (dictStrong) {
                return AppState["_activeMenu"](dictStrong);
            })(AppState.FileMenu.value);
        });
    };
};
export {
    Initialize,
    ClickFileMenu,
    ClickSettingsMenu,
    ClickNoMenu,
    ToggleEditMode,
    ToggleTabNormalization,
    ToggleTabDozenalization,
    ToggleChordNormalization,
    ToggleChordDozenalization,
    CreateShortUrl,
    ToggleAutoscroll,
    IncreaseAutoscrollSpeed,
    DecreaseAutoscrollSpeed,
    IncreaseTransposition,
    DecreaseTransposition,
    FlatNoteOrientation,
    SharpNoteOrientation,
    DefaultNoteOrientation,
    ToggleSearch,
    SearchInput,
    ImportFromUrl,
    NoAction,
    initialize,
    clickNoMenu,
    clickSettingsMenu,
    clickFileMenu,
    increaseAutoscrollSpeed,
    decreaseAutoscrollSpeed,
    toggleTabNormalization,
    toggleTabDozenalization,
    toggleChordNormalization,
    toggleChordDozenalization,
    createAndCopyShortUrl,
    increaseTransposition,
    decreaseTransposition,
    setNoteOrientation,
    toggleSearch,
    searchInput,
    importFromUrl
};
