// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Control_Monad_Except from "../Control.Monad.Except/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Identity from "../Data.Identity/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Foreign from "../Foreign/index.js";
var readString = /* #__PURE__ */ Foreign.readString(Data_Identity.monadIdentity);
var decompressFromEncodedURIComponent = function (compressed) {
    var v = Control_Monad_Except.runExcept(readString($foreign.unsafeDecompressFromEncodedURIComponent(compressed)));
    if (v instanceof Data_Either.Right && v.value0 === "") {
        return Data_Maybe.Nothing.value;
    };
    if (v instanceof Data_Either.Right) {
        return new Data_Maybe.Just(v.value0);
    };
    return Data_Maybe.Nothing.value;
};
var compressToEncodedURIComponent = function (value) {
    var v = Control_Monad_Except.runExcept(readString($foreign.unsafeCompressToEncodedURIComponent(value)));
    if (v instanceof Data_Either.Right && v.value0 === "") {
        return Data_Maybe.Nothing.value;
    };
    if (v instanceof Data_Either.Right) {
        return new Data_Maybe.Just(v.value0);
    };
    return Data_Maybe.Nothing.value;
};
export {
    unsafeDecompressFromEncodedURIComponent,
    unsafeCompressToEncodedURIComponent
} from "./foreign.js";
export {
    compressToEncodedURIComponent,
    decompressFromEncodedURIComponent
};
