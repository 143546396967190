// Generated by purs version 0.15.7
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_MediaType_Common from "../Data.MediaType.Common/index.js";
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var $$ArrayBuffer = /* #__PURE__ */ (function () {
    function $$ArrayBuffer(value0) {
        this.value0 = value0;
    };
    $$ArrayBuffer.create = function (value0) {
        return new $$ArrayBuffer(value0);
    };
    return $$ArrayBuffer;
})();
var Blob = /* #__PURE__ */ (function () {
    function Blob(value0) {
        this.value0 = value0;
    };
    Blob.create = function (value0) {
        return new Blob(value0);
    };
    return Blob;
})();
var Document = /* #__PURE__ */ (function () {
    function Document(value0) {
        this.value0 = value0;
    };
    Document.create = function (value0) {
        return new Document(value0);
    };
    return Document;
})();
var Json = /* #__PURE__ */ (function () {
    function Json(value0) {
        this.value0 = value0;
    };
    Json.create = function (value0) {
        return new Json(value0);
    };
    return Json;
})();
var $$String = /* #__PURE__ */ (function () {
    function $$String(value0) {
        this.value0 = value0;
    };
    $$String.create = function (value0) {
        return new $$String(value0);
    };
    return $$String;
})();
var Ignore = /* #__PURE__ */ (function () {
    function Ignore(value0) {
        this.value0 = value0;
    };
    Ignore.create = function (value0) {
        return new Ignore(value0);
    };
    return Ignore;
})();
var toResponseType = function (v) {
    if (v instanceof $$ArrayBuffer) {
        return "arraybuffer";
    };
    if (v instanceof Blob) {
        return "blob";
    };
    if (v instanceof Document) {
        return "document";
    };
    if (v instanceof Json) {
        return "text";
    };
    if (v instanceof $$String) {
        return "text";
    };
    if (v instanceof Ignore) {
        return "";
    };
    throw new Error("Failed pattern match at Affjax.ResponseFormat (line 44, column 3 - line 50, column 19): " + [ v.constructor.name ]);
};
var toMediaType = function (v) {
    if (v instanceof Json) {
        return new Data_Maybe.Just(Data_MediaType_Common.applicationJSON);
    };
    return Data_Maybe.Nothing.value;
};
var string = /* #__PURE__ */ (function () {
    return new $$String(identity);
})();
var json = /* #__PURE__ */ (function () {
    return new Json(identity);
})();
var ignore = /* #__PURE__ */ (function () {
    return new Ignore(identity);
})();
var document = /* #__PURE__ */ (function () {
    return new Document(identity);
})();
var blob = /* #__PURE__ */ (function () {
    return new Blob(identity);
})();
var arrayBuffer = /* #__PURE__ */ (function () {
    return new $$ArrayBuffer(identity);
})();
export {
    $$ArrayBuffer as ArrayBuffer,
    Blob,
    Document,
    Json,
    $$String as String,
    Ignore,
    arrayBuffer,
    blob,
    document,
    json,
    string,
    ignore,
    toResponseType,
    toMediaType
};
