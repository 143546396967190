// Generated by purs version 0.15.7
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Array_NonEmpty from "../Data.Array.NonEmpty/index.js";
import * as Data_Char from "../Data.Char/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Enum from "../Data.Enum/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_CodePoints from "../Data.String.CodePoints/index.js";
import * as Data_String_CodeUnits from "../Data.String.CodeUnits/index.js";
import * as Data_String_Regex from "../Data.String.Regex/index.js";
import * as Data_String_Regex_Flags from "../Data.String.Regex.Flags/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as StringParser_CodeUnits from "../StringParser.CodeUnits/index.js";
import * as StringParser_Combinators from "../StringParser.Combinators/index.js";
import * as StringParser_Parser from "../StringParser.Parser/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(StringParser_Parser.bindParser);
var elem = /* #__PURE__ */ Data_Foldable.elem(Data_Foldable.foldableArray)(Data_Eq.eqInt);
var pure = /* #__PURE__ */ Control_Applicative.pure(StringParser_Parser.applicativeParser);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showChar);
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var alt = /* #__PURE__ */ Control_Alt.alt(StringParser_Parser.altParser);
var show1 = /* #__PURE__ */ Data_Show.show(Data_String_CodePoints.showCodePoint);
var eq1 = /* #__PURE__ */ Data_Eq.eq(Data_String_CodePoints.eqCodePoint);
var show2 = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var foldMap = /* #__PURE__ */ Data_Foldable.foldMap(Data_List_Types.foldableList)(Data_Monoid.monoidString);
var upperCaseChar = /* #__PURE__ */ StringParser_Combinators["try"](/* #__PURE__ */ bind(StringParser_CodeUnits.anyChar)(function (c) {
    var $42 = elem(Data_Char.toCharCode(c))(Data_Array.range(65)(90));
    if ($42) {
        return pure(c);
    };
    return StringParser_Parser.fail("Expected an upper case character but found " + show(c));
}));
var string = function (pattern) {
    return function (v) {
        var length = Data_String_CodePoints.length(pattern);
        var v1 = Data_String_CodePoints.splitAt(length)(v.substring);
        var $45 = v1.before === pattern;
        if ($45) {
            return new Data_Either.Right({
                result: pattern,
                suffix: {
                    substring: v1.after,
                    position: v.position + length | 0
                }
            });
        };
        return new Data_Either.Left({
            pos: v.position,
            error: "Expected '" + (pattern + "'.")
        });
    };
};
var regex = function (pat) {
    var pattern = "^(" + (pat + ")");
    var matchRegex = function (r) {
        return function (v) {
            var v1 = map(Data_Array_NonEmpty.head)(Data_String_Regex.match(r)(v.substring));
            if (v1 instanceof Data_Maybe.Just && v1.value0 instanceof Data_Maybe.Just) {
                return new Data_Either.Right({
                    result: v1.value0.value0,
                    suffix: {
                        substring: Data_String_CodePoints.drop(Data_String_CodePoints.length(v1.value0.value0))(v.substring),
                        position: v.position + Data_String_CodePoints.length(v1.value0.value0) | 0
                    }
                });
            };
            return new Data_Either.Left({
                pos: v.position,
                error: "no match"
            });
        };
    };
    var v = Data_String_Regex.regex(pattern)(Data_String_Regex_Flags.noFlags);
    if (v instanceof Data_Either.Left) {
        return StringParser_Parser.fail("StringParser.String.regex': illegal regex " + pat);
    };
    if (v instanceof Data_Either.Right) {
        return matchRegex(v.value0);
    };
    throw new Error("Failed pattern match at StringParser.CodePoints (line 158, column 3 - line 162, column 19): " + [ v.constructor.name ]);
};
var lowerCaseChar = /* #__PURE__ */ StringParser_Combinators["try"](/* #__PURE__ */ bind(StringParser_CodeUnits.anyChar)(function (c) {
    var $59 = elem(Data_Char.toCharCode(c))(Data_Array.range(97)(122));
    if ($59) {
        return pure(c);
    };
    return StringParser_Parser.fail("Expected a lower case character but found " + show(c));
}));
var eof = function (s) {
    if (0 < Data_String_CodePoints.length(s.substring)) {
        return new Data_Either.Left({
            pos: s.position,
            error: "Expected EOF"
        });
    };
    return new Data_Either.Right({
        result: Data_Unit.unit,
        suffix: s
    });
};
var anyLetter = /* #__PURE__ */ alt(lowerCaseChar)(/* #__PURE__ */ StringParser_Combinators.withError(upperCaseChar)("Expected a letter"));
var anyDigit = /* #__PURE__ */ StringParser_Combinators["try"](/* #__PURE__ */ bind(StringParser_CodeUnits.anyChar)(function (c) {
    var $63 = c >= "0" && c <= "9";
    if ($63) {
        return pure(c);
    };
    return StringParser_Parser.fail("Character " + (show(c) + " is not a digit"));
}));
var anyCodePoint = function (v) {
    var v1 = Data_String_CodePoints.uncons(v.substring);
    if (v1 instanceof Data_Maybe.Nothing) {
        return new Data_Either.Left({
            pos: v.position,
            error: "Unexpected EOF"
        });
    };
    if (v1 instanceof Data_Maybe.Just) {
        return new Data_Either.Right({
            result: v1.value0.head,
            suffix: {
                substring: v1.value0.tail,
                position: v.position + 1 | 0
            }
        });
    };
    throw new Error("Failed pattern match at StringParser.CodePoints (line 72, column 3 - line 74, column 103): " + [ v1.constructor.name ]);
};
var satisfyCodePoint = function (f) {
    return StringParser_Combinators["try"](bind(anyCodePoint)(function (cp) {
        var $71 = f(cp);
        if ($71) {
            return pure(cp);
        };
        return StringParser_Parser.fail("Code point " + (show1(cp) + " did not satisfy predicate"));
    }));
};
var codePoint = function (c) {
    return StringParser_Combinators.withError(satisfyCodePoint(function (v) {
        return eq1(v)(c);
    }))("Could not match code point " + show1(c));
};
var anyChar = /* #__PURE__ */ (function () {
    var notAChar = function (cc) {
        return StringParser_Parser.fail("Code point " + (show2(cc) + " is not a character"));
    };
    return bind(Data_Functor.mapFlipped(StringParser_Parser.functorParser)(anyCodePoint)(Data_Enum.fromEnum(Data_String_CodePoints.boundedEnumCodePoint)))(function (cc) {
        var v = Data_Char.fromCharCode(cc);
        if (v instanceof Data_Maybe.Just) {
            var $73 = cc > 65535;
            if ($73) {
                return notAChar(cc);
            };
            return pure(v.value0);
        };
        if (v instanceof Data_Maybe.Nothing) {
            return notAChar(cc);
        };
        throw new Error("Failed pattern match at StringParser.CodePoints (line 57, column 3 - line 65, column 27): " + [ v.constructor.name ]);
    });
})();
var satisfy = function (f) {
    return StringParser_Combinators["try"](bind(anyChar)(function (c) {
        var $75 = f(c);
        if ($75) {
            return pure(c);
        };
        return StringParser_Parser.fail("Character " + (show(c) + " did not satisfy predicate"));
    }));
};
var $$char = function (c) {
    return StringParser_Combinators.withError(satisfy(function (v) {
        return v === c;
    }))("Could not match character " + show(c));
};
var noneOf = function (dictFoldable) {
    var $76 = Data_Function.flip(Data_Foldable.notElem(dictFoldable)(Data_Eq.eqChar));
    return function ($77) {
        return satisfy($76($77));
    };
};
var oneOf = function (dictFoldable) {
    var $78 = Data_Function.flip(Data_Foldable.elem(dictFoldable)(Data_Eq.eqChar));
    return function ($79) {
        return satisfy($78($79));
    };
};
var whiteSpace = /* #__PURE__ */ bind(/* #__PURE__ */ StringParser_Combinators.many(/* #__PURE__ */ satisfy(function (c) {
    return c === "\x0a" || (c === "\x0d" || (c === " " || c === "\x09"));
})))(function (cs) {
    return pure(foldMap(Data_String_CodeUnits.singleton)(cs));
});
var skipSpaces = /* #__PURE__ */ Data_Functor["void"](StringParser_Parser.functorParser)(whiteSpace);
var alphaNum = /* #__PURE__ */ alt(anyLetter)(/* #__PURE__ */ StringParser_Combinators.withError(anyDigit)("Expected a letter or a number"));
export {
    eof,
    anyChar,
    anyCodePoint,
    anyDigit,
    string,
    satisfy,
    satisfyCodePoint,
    $$char as char,
    codePoint,
    whiteSpace,
    skipSpaces,
    oneOf,
    noneOf,
    lowerCaseChar,
    upperCaseChar,
    anyLetter,
    alphaNum,
    regex
};
