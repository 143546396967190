// Generated by purs version 0.15.7
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Lens_Getter from "../Data.Lens.Getter/index.js";
import * as Data_Lens_Internal_Forget from "../Data.Lens.Internal.Forget/index.js";
import * as Data_Lens_Setter from "../Data.Lens.Setter/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Profunctor_Strong from "../Data.Profunctor.Strong/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Utils from "../Utils/index.js";
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit);
var Flush = /* #__PURE__ */ (function () {
    function Flush(value0) {
        this.value0 = value0;
    };
    Flush.create = function (value0) {
        return new Flush(value0);
    };
    return Flush;
})();
var Fetch = /* #__PURE__ */ (function () {
    function Fetch(value0) {
        this.value0 = value0;
    };
    Fetch.create = function (value0) {
        return new Fetch(value0);
    };
    return Fetch;
})();
var Cached = /* #__PURE__ */ (function () {
    function Cached(value0) {
        this.value0 = value0;
    };
    Cached.create = function (value0) {
        return new Cached(value0);
    };
    return Cached;
})();
var NoValue = /* #__PURE__ */ (function () {
    function NoValue() {

    };
    NoValue.value = new NoValue();
    return NoValue;
})();
var AnyEntryKey = function (x) {
    return x;
};
var EntryKey = function (x) {
    return x;
};
var runFlush = function (v) {
    return v.value0;
};
var runFetch = function (v) {
    return v.value0;
};
var runEntryKey = function (v) {
    return function (dictStrong) {
        return v(dictStrong);
    };
};
var viewEntry$prime = function (key) {
    return Data_Lens_Getter.view(runEntryKey(key)(Data_Lens_Internal_Forget.strongForget));
};
var viewEntry = function (dictMonadState) {
    var mapFlipped = Data_Functor.mapFlipped((((dictMonadState.Monad0()).Bind1()).Apply0()).Functor0());
    var get = Control_Monad_State_Class.get(dictMonadState);
    return function (key) {
        return mapFlipped(get)(viewEntry$prime(key));
    };
};
var peek$prime = function (_key) {
    return function (state) {
        var entry = viewEntry$prime(_key)(state);
        return entry.value;
    };
};
var peek = function (_key) {
    return function (state) {
        var entry = viewEntry$prime(_key)(state);
        if (entry.value instanceof NoValue) {
            return entry["default"];
        };
        if (entry.value instanceof Cached) {
            return entry.value.value0;
        };
        throw new Error("Failed pattern match at Cache (line 86, column 3 - line 88, column 28): " + [ entry.value.constructor.name ]);
    };
};
var overEntry = function (dictMonadState) {
    var Bind1 = (dictMonadState.Monad0()).Bind1();
    var bind = Control_Bind.bind(Bind1);
    var mapFlipped = Data_Functor.mapFlipped((Bind1.Apply0()).Functor0());
    var get = Control_Monad_State_Class.get(dictMonadState);
    var put = Control_Monad_State_Class.put(dictMonadState);
    return function (key) {
        return function (f) {
            return bind(mapFlipped(get)(Data_Lens_Setter.over(runEntryKey(key)(Data_Profunctor_Strong.strongFn))(f)))(put);
        };
    };
};
var read = function (dictMonadState) {
    var Monad0 = dictMonadState.Monad0();
    var Bind1 = Monad0.Bind1();
    var bind = Control_Bind.bind(Bind1);
    var viewEntry1 = viewEntry(dictMonadState);
    var pure = Control_Applicative.pure(Monad0.Applicative0());
    var discard1 = discard(Bind1);
    var overEntry1 = overEntry(dictMonadState);
    return function (cache) {
        return bind(viewEntry1(cache.entry))(function (entry) {
            if (entry.value instanceof Cached) {
                return pure(entry.value.value0);
            };
            if (entry.value instanceof NoValue) {
                return bind(runFetch(cache.fetch))(function (maybeValue) {
                    return bind(pure(Data_Maybe.fromMaybe(entry["default"])(maybeValue)))(function (value) {
                        return discard1(overEntry1(cache.entry)(function (v) {
                            return {
                                value: new Cached(value),
                                "default": v["default"],
                                dependants: v.dependants
                            };
                        }))(function () {
                            return pure(value);
                        });
                    });
                });
            };
            throw new Error("Failed pattern match at Cache (line 124, column 3 - line 130, column 17): " + [ entry.value.constructor.name ]);
        });
    };
};
var mkAnyEntryKey = function (x) {
    return function (f) {
        return f(x);
    };
};
var mapAnyEntryKey = function (f) {
    return function (v) {
        return v(f);
    };
};
var invalidateDependants = function (dictMonadState) {
    var foreachM = Utils.foreachM(dictMonadState);
    return function (entry) {
        return foreachM(entry.dependants)(mapAnyEntryKey(invalidate(dictMonadState)));
    };
};
var invalidate = function (dictMonadState) {
    var Monad0 = dictMonadState.Monad0();
    var Bind1 = Monad0.Bind1();
    var bind = Control_Bind.bind(Bind1);
    var viewEntry1 = viewEntry(dictMonadState);
    var pure = Control_Applicative.pure(Monad0.Applicative0());
    var discard1 = discard(Bind1);
    var overEntry1 = overEntry(dictMonadState);
    return function (_key) {
        return bind(viewEntry1(_key))(function (entry) {
            if (entry.value instanceof NoValue) {
                return pure(Data_Unit.unit);
            };
            if (entry.value instanceof Cached) {
                return discard1(overEntry1(_key)(function (v) {
                    return {
                        value: NoValue.value,
                        "default": v["default"],
                        dependants: Data_List_Types.Nil.value
                    };
                }))(function () {
                    return invalidateDependants(dictMonadState)(entry);
                });
            };
            throw new Error("Failed pattern match at Cache (line 95, column 3 - line 99, column 33): " + [ entry.value.constructor.name ]);
        });
    };
};
var write = function (dictMonadState) {
    var Bind1 = (dictMonadState.Monad0()).Bind1();
    var bind = Control_Bind.bind(Bind1);
    var viewEntry1 = viewEntry(dictMonadState);
    var discard1 = discard(Bind1);
    var invalidateDependants1 = invalidateDependants(dictMonadState);
    var overEntry1 = overEntry(dictMonadState);
    return function (cache) {
        return function (value) {
            return bind(viewEntry1(cache.entry))(function (entry) {
                return discard1(runFlush(cache.flush)(value))(function () {
                    return discard1(invalidateDependants1(entry))(function () {
                        return overEntry1(cache.entry)(function (v) {
                            return {
                                value: new Cached(value),
                                "default": v["default"],
                                dependants: v.dependants
                            };
                        });
                    });
                });
            });
        };
    };
};
var buildCache = function ($$default) {
    return {
        value: NoValue.value,
        "default": $$default,
        dependants: Data_List_Types.Nil.value
    };
};
var addDependant = function (dictMonadState) {
    var overEntry1 = overEntry(dictMonadState);
    return function (dependency) {
        return function (_dependant) {
            return overEntry1(dependency.entry)(function (entry) {
                return {
                    value: entry.value,
                    "default": entry["default"],
                    dependants: new Data_List_Types.Cons(mkAnyEntryKey(_dependant), entry.dependants)
                };
            });
        };
    };
};
var subscribe = function (dictMonadState) {
    var Monad0 = dictMonadState.Monad0();
    var Bind1 = Monad0.Bind1();
    var bind = Control_Bind.bind(Bind1);
    var read1 = read(dictMonadState);
    var discard1 = discard(Bind1);
    var addDependant1 = addDependant(dictMonadState);
    var pure = Control_Applicative.pure(Monad0.Applicative0());
    return function (_dependant) {
        return function (dependency) {
            return bind(read1(dependency))(function (dependencyValue) {
                return discard1(addDependant1(dependency)(_dependant))(function () {
                    return pure(dependencyValue);
                });
            });
        };
    };
};
export {
    Cached,
    NoValue,
    AnyEntryKey,
    mkAnyEntryKey,
    mapAnyEntryKey,
    buildCache,
    EntryKey,
    runEntryKey,
    viewEntry$prime,
    viewEntry,
    overEntry,
    Fetch,
    runFetch,
    Flush,
    runFlush,
    peek$prime,
    peek,
    invalidate,
    invalidateDependants,
    subscribe,
    addDependant,
    write,
    read
};
