// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad from "../Control.Monad/index.js";
import * as Control_Monad_Error_Class from "../Control.Monad.Error.Class/index.js";
import * as Control_Monad_Rec_Class from "../Control.Monad.Rec.Class/index.js";
import * as Control_Parallel from "../Control.Parallel/index.js";
import * as Control_Parallel_Class from "../Control.Parallel.Class/index.js";
import * as Control_Plus from "../Control.Plus/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Semigroup from "../Data.Semigroup/index.js";
import * as Data_Time_Duration from "../Data.Time.Duration/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Exception from "../Effect.Exception/index.js";
import * as Effect_Unsafe from "../Effect.Unsafe/index.js";
import * as Partial_Unsafe from "../Partial.Unsafe/index.js";
import * as Unsafe_Coerce from "../Unsafe.Coerce/index.js";
var $runtime_lazy = function (name, moduleName, init) {
    var state = 0;
    var val;
    return function (lineNumber) {
        if (state === 2) return val;
        if (state === 1) throw new ReferenceError(name + " was needed before it finished initializing (module " + moduleName + ", line " + lineNumber + ")", moduleName, lineNumber);
        state = 1;
        val = init();
        state = 2;
        return val;
    };
};
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var $$void = /* #__PURE__ */ Data_Functor["void"](Effect.functorEffect);
var map = /* #__PURE__ */ Data_Functor.map(Effect.functorEffect);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Data_Either.applicativeEither);
var Fiber = function (x) {
    return x;
};
var FFIUtil = function (x) {
    return x;
};
var Canceler = function (x) {
    return x;
};
var suspendAff = /* #__PURE__ */ $foreign["_fork"](false);
var newtypeCanceler = {
    Coercible0: function () {
        return undefined;
    }
};
var functorParAff = {
    map: $foreign["_parAffMap"]
};
var functorAff = {
    map: $foreign["_map"]
};
var map1 = /* #__PURE__ */ Data_Functor.map(functorAff);
var forkAff = /* #__PURE__ */ $foreign["_fork"](true);
var ffiUtil = /* #__PURE__ */ (function () {
    var unsafeFromRight = function (v) {
        if (v instanceof Data_Either.Right) {
            return v.value0;
        };
        if (v instanceof Data_Either.Left) {
            return Partial_Unsafe.unsafeCrashWith("unsafeFromRight: Left");
        };
        throw new Error("Failed pattern match at Effect.Aff (line 407, column 21 - line 409, column 54): " + [ v.constructor.name ]);
    };
    var unsafeFromLeft = function (v) {
        if (v instanceof Data_Either.Left) {
            return v.value0;
        };
        if (v instanceof Data_Either.Right) {
            return Partial_Unsafe.unsafeCrashWith("unsafeFromLeft: Right");
        };
        throw new Error("Failed pattern match at Effect.Aff (line 402, column 20 - line 404, column 55): " + [ v.constructor.name ]);
    };
    var isLeft = function (v) {
        if (v instanceof Data_Either.Left) {
            return true;
        };
        if (v instanceof Data_Either.Right) {
            return false;
        };
        throw new Error("Failed pattern match at Effect.Aff (line 397, column 12 - line 399, column 21): " + [ v.constructor.name ]);
    };
    return {
        isLeft: isLeft,
        fromLeft: unsafeFromLeft,
        fromRight: unsafeFromRight,
        left: Data_Either.Left.create,
        right: Data_Either.Right.create
    };
})();
var makeFiber = function (aff) {
    return $foreign["_makeFiber"](ffiUtil, aff);
};
var launchAff = function (aff) {
    return function __do() {
        var fiber = makeFiber(aff)();
        fiber.run();
        return fiber;
    };
};
var launchAff_ = function ($73) {
    return $$void(launchAff($73));
};
var launchSuspendedAff = makeFiber;
var delay = function (v) {
    return $foreign["_delay"](Data_Either.Right.create, v);
};
var bracket = function (acquire) {
    return function (completed) {
        return $foreign.generalBracket(acquire)({
            killed: Data_Function["const"](completed),
            failed: Data_Function["const"](completed),
            completed: Data_Function["const"](completed)
        });
    };
};
var applyParAff = {
    apply: $foreign["_parAffApply"],
    Functor0: function () {
        return functorParAff;
    }
};
var lift2 = /* #__PURE__ */ Control_Apply.lift2(applyParAff);
var semigroupParAff = function (dictSemigroup) {
    return {
        append: lift2(Data_Semigroup.append(dictSemigroup))
    };
};
var monadAff = {
    Applicative0: function () {
        return applicativeAff;
    },
    Bind1: function () {
        return bindAff;
    }
};
var bindAff = {
    bind: $foreign["_bind"],
    Apply0: function () {
        return $lazy_applyAff(0);
    }
};
var applicativeAff = {
    pure: $foreign["_pure"],
    Apply0: function () {
        return $lazy_applyAff(0);
    }
};
var $lazy_applyAff = /* #__PURE__ */ $runtime_lazy("applyAff", "Effect.Aff", function () {
    return {
        apply: Control_Monad.ap(monadAff),
        Functor0: function () {
            return functorAff;
        }
    };
});
var applyAff = /* #__PURE__ */ $lazy_applyAff(71);
var pure2 = /* #__PURE__ */ Control_Applicative.pure(applicativeAff);
var bind1 = /* #__PURE__ */ Control_Bind.bind(bindAff);
var lift21 = /* #__PURE__ */ Control_Apply.lift2(applyAff);
var apply = /* #__PURE__ */ Control_Apply.apply(applyAff);
var bindFlipped = /* #__PURE__ */ Control_Bind.bindFlipped(bindAff);
var cancelWith = function (aff) {
    return function (v) {
        return $foreign.generalBracket(pure2(Data_Unit.unit))({
            killed: function (e) {
                return function (v1) {
                    return v(e);
                };
            },
            failed: Data_Function["const"](pure2),
            completed: Data_Function["const"](pure2)
        })(Data_Function["const"](aff));
    };
};
var $$finally = function (fin) {
    return function (a) {
        return bracket(pure2(Data_Unit.unit))(Data_Function["const"](fin))(Data_Function["const"](a));
    };
};
var invincible = function (a) {
    return bracket(a)(Data_Function["const"](pure2(Data_Unit.unit)))(pure2);
};
var lazyAff = {
    defer: function (f) {
        return bind1(pure2(Data_Unit.unit))(f);
    }
};
var semigroupAff = function (dictSemigroup) {
    return {
        append: lift21(Data_Semigroup.append(dictSemigroup))
    };
};
var monadEffectAff = {
    liftEffect: $foreign["_liftEffect"],
    Monad0: function () {
        return monadAff;
    }
};
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(monadEffectAff);
var effectCanceler = function ($74) {
    return Canceler(Data_Function["const"](liftEffect($74)));
};
var joinFiber = function (v) {
    return $foreign.makeAff(function (k) {
        return map(effectCanceler)(v.join(k));
    });
};
var functorFiber = {
    map: function (f) {
        return function (t) {
            return Effect_Unsafe.unsafePerformEffect(makeFiber(map1(f)(joinFiber(t))));
        };
    }
};
var applyFiber = {
    apply: function (t1) {
        return function (t2) {
            return Effect_Unsafe.unsafePerformEffect(makeFiber(apply(joinFiber(t1))(joinFiber(t2))));
        };
    },
    Functor0: function () {
        return functorFiber;
    }
};
var applicativeFiber = {
    pure: function (a) {
        return Effect_Unsafe.unsafePerformEffect(makeFiber(pure2(a)));
    },
    Apply0: function () {
        return applyFiber;
    }
};
var killFiber = function (e) {
    return function (v) {
        return bind1(liftEffect(v.isSuspended))(function (suspended) {
            if (suspended) {
                return liftEffect($$void(v.kill(e, Data_Function["const"](pure(Data_Unit.unit)))));
            };
            return $foreign.makeAff(function (k) {
                return map(effectCanceler)(v.kill(e, k));
            });
        });
    };
};
var fiberCanceler = /* #__PURE__ */ (function () {
    var $75 = Data_Function.flip(killFiber);
    return function ($76) {
        return Canceler($75($76));
    };
})();
var monadThrowAff = {
    throwError: $foreign["_throwError"],
    Monad0: function () {
        return monadAff;
    }
};
var monadErrorAff = {
    catchError: $foreign["_catchError"],
    MonadThrow0: function () {
        return monadThrowAff;
    }
};
var $$try = /* #__PURE__ */ Control_Monad_Error_Class["try"](monadErrorAff);
var catchError = /* #__PURE__ */ Control_Monad_Error_Class.catchError(monadErrorAff);
var attempt = $$try;
var runAff = function (k) {
    return function (aff) {
        return launchAff(bindFlipped(function ($77) {
            return liftEffect(k($77));
        })($$try(aff)));
    };
};
var runAff_ = function (k) {
    return function (aff) {
        return $$void(runAff(k)(aff));
    };
};
var runSuspendedAff = function (k) {
    return function (aff) {
        return launchSuspendedAff(bindFlipped(function ($78) {
            return liftEffect(k($78));
        })($$try(aff)));
    };
};
var parallelAff = {
    parallel: Unsafe_Coerce.unsafeCoerce,
    sequential: $foreign["_sequential"],
    Monad0: function () {
        return monadAff;
    },
    Applicative1: function () {
        return $lazy_applicativeParAff(0);
    }
};
var $lazy_applicativeParAff = /* #__PURE__ */ $runtime_lazy("applicativeParAff", "Effect.Aff", function () {
    return {
        pure: (function () {
            var $79 = Control_Parallel_Class.parallel(parallelAff);
            return function ($80) {
                return $79(pure2($80));
            };
        })(),
        Apply0: function () {
            return applyParAff;
        }
    };
});
var applicativeParAff = /* #__PURE__ */ $lazy_applicativeParAff(131);
var pure3 = /* #__PURE__ */ Control_Applicative.pure(applicativeParAff);
var parSequence_ = /* #__PURE__ */ Control_Parallel.parSequence_(parallelAff)(Data_Foldable.foldableArray);
var monoidParAff = function (dictMonoid) {
    var semigroupParAff1 = semigroupParAff(dictMonoid.Semigroup0());
    return {
        mempty: pure3(Data_Monoid.mempty(dictMonoid)),
        Semigroup0: function () {
            return semigroupParAff1;
        }
    };
};
var semigroupCanceler = {
    append: function (v) {
        return function (v1) {
            return function (err) {
                return parSequence_([ v(err), v1(err) ]);
            };
        };
    }
};
var supervise = function (aff) {
    var killError = Effect_Exception.error("[Aff] Child fiber outlived parent");
    var killAll = function (err) {
        return function (sup) {
            return $foreign.makeAff(function (k) {
                return $foreign["_killAll"](err, sup.supervisor, k(pure1(Data_Unit.unit)));
            });
        };
    };
    var acquire = function __do() {
        var sup = $foreign["_makeSupervisedFiber"](ffiUtil, aff)();
        sup.fiber.run();
        return sup;
    };
    return $foreign.generalBracket(liftEffect(acquire))({
        killed: function (err) {
            return function (sup) {
                return parSequence_([ killFiber(err)(sup.fiber), killAll(err)(sup) ]);
            };
        },
        failed: Data_Function["const"](killAll(killError)),
        completed: Data_Function["const"](killAll(killError))
    })(function ($81) {
        return joinFiber((function (v) {
            return v.fiber;
        })($81));
    });
};
var monadRecAff = {
    tailRecM: function (k) {
        var go = function (a) {
            return bind1(k(a))(function (res) {
                if (res instanceof Control_Monad_Rec_Class.Done) {
                    return pure2(res.value0);
                };
                if (res instanceof Control_Monad_Rec_Class.Loop) {
                    return go(res.value0);
                };
                throw new Error("Failed pattern match at Effect.Aff (line 102, column 7 - line 104, column 23): " + [ res.constructor.name ]);
            });
        };
        return go;
    },
    Monad0: function () {
        return monadAff;
    }
};
var monoidAff = function (dictMonoid) {
    var semigroupAff1 = semigroupAff(dictMonoid.Semigroup0());
    return {
        mempty: pure2(Data_Monoid.mempty(dictMonoid)),
        Semigroup0: function () {
            return semigroupAff1;
        }
    };
};
var nonCanceler = /* #__PURE__ */ Data_Function["const"](/* #__PURE__ */ pure2(Data_Unit.unit));
var monoidCanceler = {
    mempty: nonCanceler,
    Semigroup0: function () {
        return semigroupCanceler;
    }
};
var mempty = /* #__PURE__ */ Data_Monoid.mempty(monoidCanceler);
var never = /* #__PURE__ */ $foreign.makeAff(function (v) {
    return pure(mempty);
});
var apathize = /* #__PURE__ */ (function () {
    var $82 = map1(Data_Function["const"](Data_Unit.unit));
    return function ($83) {
        return $82(attempt($83));
    };
})();
var altParAff = {
    alt: $foreign["_parAffAlt"],
    Functor0: function () {
        return functorParAff;
    }
};
var altAff = {
    alt: function (a1) {
        return function (a2) {
            return catchError(a1)(Data_Function["const"](a2));
        };
    },
    Functor0: function () {
        return functorAff;
    }
};
var plusAff = {
    empty: /* #__PURE__ */ Control_Monad_Error_Class.throwError(monadThrowAff)(/* #__PURE__ */ Effect_Exception.error("Always fails")),
    Alt0: function () {
        return altAff;
    }
};
var plusParAff = {
    empty: /* #__PURE__ */ Control_Parallel_Class.parallel(parallelAff)(/* #__PURE__ */ Control_Plus.empty(plusAff)),
    Alt0: function () {
        return altParAff;
    }
};
var alternativeParAff = {
    Applicative0: function () {
        return applicativeParAff;
    },
    Plus1: function () {
        return plusParAff;
    }
};
export {
    makeAff,
    generalBracket
} from "./foreign.js";
export {
    Canceler,
    launchAff,
    launchAff_,
    launchSuspendedAff,
    runAff,
    runAff_,
    runSuspendedAff,
    forkAff,
    suspendAff,
    supervise,
    attempt,
    apathize,
    delay,
    never,
    $$finally as finally,
    invincible,
    killFiber,
    joinFiber,
    cancelWith,
    bracket,
    nonCanceler,
    effectCanceler,
    fiberCanceler,
    functorAff,
    applyAff,
    applicativeAff,
    bindAff,
    monadAff,
    semigroupAff,
    monoidAff,
    altAff,
    plusAff,
    monadRecAff,
    monadThrowAff,
    monadErrorAff,
    monadEffectAff,
    lazyAff,
    functorParAff,
    applyParAff,
    applicativeParAff,
    semigroupParAff,
    monoidParAff,
    altParAff,
    plusParAff,
    alternativeParAff,
    parallelAff,
    functorFiber,
    applyFiber,
    applicativeFiber,
    newtypeCanceler,
    semigroupCanceler,
    monoidCanceler
};
export {
    catchError,
    throwError,
    try
} from "../Control.Monad.Error.Class/index.js";
export {
    parallel,
    sequential
} from "../Control.Parallel.Class/index.js";
export {
    Milliseconds
} from "../Data.Time.Duration/index.js";
export {
    error,
    message
} from "../Effect.Exception/index.js";
