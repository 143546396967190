// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Effect from "../Effect/index.js";
import * as Web_DOM_Document from "../Web.DOM.Document/index.js";
import * as Web_DOM_Element from "../Web.DOM.Element/index.js";
import * as Web_DOM_HTMLCollection from "../Web.DOM.HTMLCollection/index.js";
import * as Web_DOM_Node from "../Web.DOM.Node/index.js";
var map = /* #__PURE__ */ Data_Functor.map(Data_Maybe.functorMaybe);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var map1 = /* #__PURE__ */ Data_Functor.map(Effect.functorEffect);
var join = /* #__PURE__ */ Control_Bind.join(Effect.bindEffect);
var returnIfNothing = function (errMay) {
    return function (val) {
        if (errMay instanceof Data_Maybe.Nothing) {
            return new Data_Either.Right(val);
        };
        if (errMay instanceof Data_Maybe.Just) {
            return new Data_Either.Left(errMay.value0);
        };
        throw new Error("Failed pattern match at Web.DOM.DOMParser (line 73, column 30 - line 75, column 21): " + [ errMay.constructor.name ]);
    };
};
var _getParserError = function (doc) {
    var getText = function (elMay) {
        var v = map(Web_DOM_Element.toNode)(elMay);
        if (v instanceof Data_Maybe.Nothing) {
            return pure(Data_Maybe.Nothing.value);
        };
        if (v instanceof Data_Maybe.Just) {
            return map1(Data_Maybe.Just.create)(Web_DOM_Node.textContent(v.value0));
        };
        throw new Error("Failed pattern match at Web.DOM.DOMParser (line 65, column 23 - line 67, column 45): " + [ v.constructor.name ]);
    };
    return function __do() {
        var v = join(map1(Web_DOM_HTMLCollection.toArray)(Web_DOM_Document.getElementsByTagName("parsererror")(doc)))();
        var v1 = Data_Array.head(v);
        return getText(v1)();
    };
};
var parseHTMLFromString = function (s) {
    return function (d) {
        return function __do() {
            var doc = $foreign.parseFromString("text/html")(s)(d)();
            var errMay = _getParserError(doc)();
            return returnIfNothing(errMay)(doc);
        };
    };
};
var parseSVGFromString = function (s) {
    return function (d) {
        return function __do() {
            var doc = $foreign.parseFromString("image/svg+xml")(s)(d)();
            var errMay = _getParserError(doc)();
            return returnIfNothing(errMay)(doc);
        };
    };
};
var parseXMLFromString = function (s) {
    return function (d) {
        return function __do() {
            var doc = $foreign.parseFromString("application/xml")(s)(d)();
            var errMay = _getParserError(doc)();
            return returnIfNothing(errMay)(doc);
        };
    };
};
export {
    makeDOMParser,
    parseFromString
} from "./foreign.js";
export {
    parseHTMLFromString,
    parseSVGFromString,
    parseXMLFromString,
    _getParserError
};
