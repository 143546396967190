// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as AppActions from "../AppActions/index.js";
import * as AppHtml from "../AppHtml/index.js";
import * as AppState from "../AppState/index.js";
import * as AppUrl from "../AppUrl/index.js";
import * as Cache from "../Cache/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Control_Monad_State_Trans from "../Control.Monad.State.Trans/index.js";
import * as Control_Monad_Trans_Class from "../Control.Monad.Trans.Class/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect_Aff from "../Effect.Aff/index.js";
import * as Effect_Aff_Class from "../Effect.Aff.Class/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Halogen_Aff_Util from "../Halogen.Aff.Util/index.js";
import * as Halogen_Component from "../Halogen.Component/index.js";
import * as Halogen_Query_HalogenM from "../Halogen.Query.HalogenM/index.js";
import * as Halogen_VDom_Driver from "../Halogen.VDom.Driver/index.js";
import * as LocationString from "../LocationString/index.js";
import * as TablatureRewriter from "../TablatureRewriter/index.js";
import * as Web_HTML from "../Web.HTML/index.js";
import * as Web_HTML_HTMLDocument from "../Web.HTML.HTMLDocument/index.js";
import * as Web_HTML_Window from "../Web.HTML.Window/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM);
var viewState = /* #__PURE__ */ AppState.viewState(Halogen_Query_HalogenM.monadStateHalogenM);
var eq = /* #__PURE__ */ Data_Eq.eq(AppState.eqMode);
var pure = /* #__PURE__ */ Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit);
var discard1 = /* #__PURE__ */ discard(Halogen_Query_HalogenM.bindHalogenM);
var setState = /* #__PURE__ */ AppState.setState(Halogen_Query_HalogenM.monadStateHalogenM);
var read = /* #__PURE__ */ Cache.read(Halogen_Query_HalogenM.monadStateHalogenM);
var write = /* #__PURE__ */ Cache.write(Halogen_Query_HalogenM.monadStateHalogenM);
var bindStateT = /* #__PURE__ */ Control_Monad_State_Trans.bindStateT(Halogen_Query_HalogenM.monadHalogenM);
var bind1 = /* #__PURE__ */ Control_Bind.bind(bindStateT);
var lift = /* #__PURE__ */ Control_Monad_Trans_Class.lift(Control_Monad_State_Trans.monadTransStateT)(Halogen_Query_HalogenM.monadHalogenM);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(/* #__PURE__ */ Control_Monad_State_Trans.applicativeStateT(Halogen_Query_HalogenM.monadHalogenM));
var monadStateStateT = /* #__PURE__ */ Control_Monad_State_Trans.monadStateStateT(Halogen_Query_HalogenM.monadHalogenM);
var discard2 = /* #__PURE__ */ discard(bindStateT);
var viewState1 = /* #__PURE__ */ AppState.viewState(monadStateStateT);
var setState1 = /* #__PURE__ */ AppState.setState(monadStateStateT);
var read1 = /* #__PURE__ */ Cache.read(monadStateStateT);
var get = /* #__PURE__ */ Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM);
var execStateT = /* #__PURE__ */ Control_Monad_State_Trans.execStateT(Halogen_Query_HalogenM.functorHalogenM);
var put = /* #__PURE__ */ Control_Monad_State_Class.put(Halogen_Query_HalogenM.monadStateHalogenM);
var bind3 = /* #__PURE__ */ Control_Bind.bind(Effect_Aff.bindAff);
var updateFocus = function (dictMonadAff) {
    var MonadEffect0 = dictMonadAff.MonadEffect0();
    var focusTablatureContainer = AppHtml.focusTablatureContainer(MonadEffect0);
    var loadScrollTop = AppHtml.loadScrollTop(MonadEffect0);
    var focusSearchInput = AppHtml.focusSearchInput(MonadEffect0);
    return function (action) {
        if (action instanceof AppActions.Initialize) {
            return focusTablatureContainer;
        };
        if (action instanceof AppActions.ToggleEditMode) {
            return loadScrollTop;
        };
        if (action instanceof AppActions.CreateShortUrl) {
            return focusTablatureContainer;
        };
        if (action instanceof AppActions.ToggleSearch) {
            return bind(viewState(function (dictStrong) {
                return AppState["_mode"](dictStrong);
            }))(function (mode) {
                var $143 = eq(mode)(AppState.SearchMode.value);
                if ($143) {
                    return focusSearchInput;
                };
                return focusTablatureContainer;
            });
        };
        return pure(Data_Unit.unit);
    };
};
var toggleEditMode = function (dictMonadAff) {
    var tablatureTextCache = AppState.tablatureTextCache(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(Halogen_Query_HalogenM.monadStateHalogenM);
    var MonadEffect0 = dictMonadAff.MonadEffect0();
    var setTablatureTextInEditor = AppHtml.setTablatureTextInEditor(MonadEffect0);
    var getTablatureTextFromEditor = AppHtml.getTablatureTextFromEditor(MonadEffect0);
    var focusTablatureContainer = AppHtml.focusTablatureContainer(MonadEffect0);
    return bind(viewState(function (dictStrong) {
        return AppState["_mode"](dictStrong);
    }))(function (mode) {
        if (mode instanceof AppState.ViewMode) {
            return discard1(setState(function (dictStrong) {
                return AppState["_mode"](dictStrong);
            })(AppState.EditMode.value))(function () {
                return bind(read(tablatureTextCache))(function (tablatureText) {
                    return setTablatureTextInEditor(tablatureText);
                });
            });
        };
        if (mode instanceof AppState.EditMode) {
            return bind(getTablatureTextFromEditor)(function (tablatureText) {
                return discard1(write(tablatureTextCache)(tablatureText))(function () {
                    return discard1(setState(function (dictStrong) {
                        return AppState["_mode"](dictStrong);
                    })(AppState.ViewMode.value))(function () {
                        return focusTablatureContainer;
                    });
                });
            });
        };
        if (mode instanceof AppState.SearchMode) {
            return pure(Data_Unit.unit);
        };
        throw new Error("Failed pattern match at Main (line 131, column 3 - line 142, column 28): " + [ mode.constructor.name ]);
    });
};
var startAutoscroll = function (dictMonadAff) {
    var MonadEffect0 = dictMonadAff.MonadEffect0();
    var startAutoscrollOnElement = AppHtml.startAutoscrollOnElement(Control_Monad_State_Trans.monadEffectState(Halogen_Query_HalogenM.monadEffectHalogenM(MonadEffect0)))(monadStateStateT);
    return bind1(lift(AppHtml.getTablatureContainerElement(MonadEffect0)))(function (maybeTablatureContainerElem) {
        if (maybeTablatureContainerElem instanceof Data_Maybe.Nothing) {
            return pure1(Data_Unit.unit);
        };
        if (maybeTablatureContainerElem instanceof Data_Maybe.Just) {
            return startAutoscrollOnElement(maybeTablatureContainerElem.value0);
        };
        throw new Error("Failed pattern match at Main (line 164, column 3 - line 166, column 47): " + [ maybeTablatureContainerElem.constructor.name ]);
    });
};
var updateAutoscrollTimer = function (dictMonadAff) {
    var startAutoscroll1 = startAutoscroll(dictMonadAff);
    return discard2(AppHtml.stopAutoscroll(Control_Monad_State_Trans.monadEffectState(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0())))(monadStateStateT))(function () {
        return bind1(viewState1(function (dictStrong) {
            return AppState["_autoscroll"](dictStrong);
        }))(function (autoscroll) {
            if (autoscroll) {
                return startAutoscroll1;
            };
            return pure1(Data_Unit.unit);
        });
    });
};
var updateAutoscroll = function (dictMonadAff) {
    var updateAutoscrollTimer1 = updateAutoscrollTimer(dictMonadAff);
    return function (action) {
        return bind1(viewState1(function (dictStrong) {
            return AppState["_autoscroll"](dictStrong);
        }))(function (currentAutoscroll) {
            return discard2(setState1(function (dictStrong) {
                return AppState["_autoscroll"](dictStrong);
            })((function () {
                if (action instanceof AppActions.DecreaseAutoscrollSpeed) {
                    return true;
                };
                if (action instanceof AppActions.IncreaseAutoscrollSpeed) {
                    return true;
                };
                if (action instanceof AppActions.ToggleAutoscroll) {
                    return !currentAutoscroll;
                };
                if (action instanceof AppActions.ClickNoMenu) {
                    return currentAutoscroll;
                };
                return false;
            })()))(function () {
                return updateAutoscrollTimer1;
            });
        });
    };
};
var setDocumentTitle = function (title) {
    return function __do() {
        var window = Web_HTML.window();
        var document = Web_HTML_Window.document(window)();
        return Web_HTML_HTMLDocument.setTitle(title)(document)();
    };
};
var updateDocumentTitle = function (dictMonadAff) {
    var MonadEffect0 = dictMonadAff.MonadEffect0();
    var bind4 = Control_Bind.bind((MonadEffect0.Monad0()).Bind1());
    var tablatureTitleCache = AppState.tablatureTitleCache(dictMonadAff);
    var liftEffect = Effect_Class.liftEffect(MonadEffect0);
    return function (dictMonadState) {
        return bind4(Cache.read(dictMonadState)(tablatureTitleCache(dictMonadState)))(function (tablatureTitle) {
            return liftEffect(setDocumentTitle(tablatureTitle));
        });
    };
};
var prepareHtml = function (dictMonadAff) {
    var MonadEffect0 = dictMonadAff.MonadEffect0();
    var saveScrollTop = AppHtml.saveScrollTop(MonadEffect0);
    var toggleEditMode1 = toggleEditMode(dictMonadAff);
    var focusTablatureContainer = AppHtml.focusTablatureContainer(MonadEffect0);
    return function (action) {
        return discard1(setState(function (dictStrong) {
            return AppState["_loading"](dictStrong);
        })(true))(function () {
            if (action instanceof AppActions.ToggleEditMode) {
                return discard1(saveScrollTop)(function () {
                    return discard1(toggleEditMode1)(function () {
                        return bind(viewState(function (dictStrong) {
                            return AppState["_mode"](dictStrong);
                        }))(function (mode) {
                            if (mode instanceof AppState.ViewMode) {
                                return focusTablatureContainer;
                            };
                            if (mode instanceof AppState.EditMode) {
                                return pure(Data_Unit.unit);
                            };
                            if (mode instanceof AppState.SearchMode) {
                                return pure(Data_Unit.unit);
                            };
                            throw new Error("Failed pattern match at Main (line 70, column 7 - line 74, column 32): " + [ mode.constructor.name ]);
                        });
                    });
                });
            };
            return pure(Data_Unit.unit);
        });
    };
};
var doAction = function (dictMonadAff) {
    var monadAffState = Effect_Aff_Class.monadAffState(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff));
    var initialize = AppActions.initialize(monadAffState)(monadStateStateT);
    var clickFileMenu = AppActions.clickFileMenu(monadAffState)(monadStateStateT);
    var clickSettingsMenu = AppActions.clickSettingsMenu(monadAffState)(monadStateStateT);
    var clickNoMenu = AppActions.clickNoMenu(monadAffState)(monadStateStateT);
    var toggleTabNormalization = AppActions.toggleTabNormalization(monadAffState)(monadStateStateT);
    var toggleTabDozenalization = AppActions.toggleTabDozenalization(monadAffState)(monadStateStateT);
    var toggleChordDozenalization = AppActions.toggleChordDozenalization(monadAffState)(monadStateStateT);
    var toggleChordNormalization = AppActions.toggleChordNormalization(monadAffState)(monadStateStateT);
    var createAndCopyShortUrl = AppActions.createAndCopyShortUrl(monadAffState)(monadStateStateT);
    var increaseAutoscrollSpeed = AppActions.increaseAutoscrollSpeed(monadAffState)(monadStateStateT);
    var decreaseAutoscrollSpeed = AppActions.decreaseAutoscrollSpeed(monadAffState)(monadStateStateT);
    var increaseTransposition = AppActions.increaseTransposition(monadAffState)(monadStateStateT);
    var decreaseTransposition = AppActions.decreaseTransposition(monadAffState)(monadStateStateT);
    var setNoteOrientation = AppActions.setNoteOrientation(monadAffState);
    var importFromUrl = AppActions.importFromUrl(monadAffState)(monadStateStateT);
    var searchInput = AppActions.searchInput(monadAffState)(monadStateStateT);
    var toggleSearch = AppActions.toggleSearch(monadAffState)(monadStateStateT);
    var updateAutoscroll1 = updateAutoscroll(dictMonadAff);
    var updateDocumentTitle1 = updateDocumentTitle(monadAffState)(monadStateStateT);
    var rewriteResultCache = AppState.rewriteResultCache(monadAffState)(monadStateStateT);
    return function (action) {
        return discard2((function () {
            if (action instanceof AppActions.Initialize) {
                return initialize;
            };
            if (action instanceof AppActions.ClickFileMenu) {
                return clickFileMenu;
            };
            if (action instanceof AppActions.ClickSettingsMenu) {
                return clickSettingsMenu;
            };
            if (action instanceof AppActions.ClickNoMenu) {
                return clickNoMenu;
            };
            if (action instanceof AppActions.ToggleEditMode) {
                return pure1(Data_Unit.unit);
            };
            if (action instanceof AppActions.ToggleTabNormalization) {
                return toggleTabNormalization;
            };
            if (action instanceof AppActions.ToggleTabDozenalization) {
                return toggleTabDozenalization;
            };
            if (action instanceof AppActions.ToggleChordDozenalization) {
                return toggleChordDozenalization;
            };
            if (action instanceof AppActions.ToggleChordNormalization) {
                return toggleChordNormalization;
            };
            if (action instanceof AppActions.CreateShortUrl) {
                return createAndCopyShortUrl;
            };
            if (action instanceof AppActions.ToggleAutoscroll) {
                return pure1(Data_Unit.unit);
            };
            if (action instanceof AppActions.IncreaseAutoscrollSpeed) {
                return increaseAutoscrollSpeed;
            };
            if (action instanceof AppActions.DecreaseAutoscrollSpeed) {
                return decreaseAutoscrollSpeed;
            };
            if (action instanceof AppActions.IncreaseTransposition) {
                return increaseTransposition;
            };
            if (action instanceof AppActions.DecreaseTransposition) {
                return decreaseTransposition;
            };
            if (action instanceof AppActions.FlatNoteOrientation) {
                return setNoteOrientation(TablatureRewriter.Flat.value)(monadStateStateT);
            };
            if (action instanceof AppActions.SharpNoteOrientation) {
                return setNoteOrientation(TablatureRewriter.Sharp.value)(monadStateStateT);
            };
            if (action instanceof AppActions.DefaultNoteOrientation) {
                return setNoteOrientation(TablatureRewriter.Default.value)(monadStateStateT);
            };
            if (action instanceof AppActions.ImportFromUrl) {
                return importFromUrl(action.value0);
            };
            if (action instanceof AppActions.SearchInput) {
                return searchInput(action.value0);
            };
            if (action instanceof AppActions.ToggleSearch) {
                return toggleSearch;
            };
            if (action instanceof AppActions.NoAction) {
                return pure1(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at Main (line 81, column 3 - line 103, column 26): " + [ action.constructor.name ]);
        })())(function () {
            return discard2(updateAutoscroll1(action))(function () {
                return discard2(updateDocumentTitle1)(function () {
                    return bind1(read1(rewriteResultCache))(function () {
                        return setState1(function (dictStrong) {
                            return AppState["_loading"](dictStrong);
                        })(false);
                    });
                });
            });
        });
    };
};
var handleAction = function (dictMonadAff) {
    var prepareHtml1 = prepareHtml(dictMonadAff);
    var liftAff = Effect_Aff_Class.liftAff(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff));
    var doAction1 = doAction(dictMonadAff);
    var updateFocus1 = updateFocus(dictMonadAff);
    return function (action) {
        return discard1(prepareHtml1(action))(function () {
            return discard1(liftAff(Effect_Aff.delay(0.0)))(function () {
                return bind(get)(function (currentState) {
                    return bind(execStateT(doAction1(action))(currentState))(function (newState) {
                        return discard1(put(newState))(function () {
                            return discard1(liftAff(Effect_Aff.delay(0.0)))(function () {
                                return updateFocus1(action);
                            });
                        });
                    });
                });
            });
        });
    };
};
var component = function (dictMonadAff) {
    return Halogen_Component.mkComponent({
        initialState: AppState.initialState,
        render: AppHtml.render,
        "eval": Halogen_Component.mkEval({
            handleAction: handleAction(dictMonadAff),
            handleQuery: Halogen_Component.defaultEval.handleQuery,
            receive: Halogen_Component.defaultEval.receive,
            initialize: new Data_Maybe.Just(AppActions.Initialize.value),
            finalize: Halogen_Component.defaultEval.finalize
        })
    });
};
var component1 = /* #__PURE__ */ component(Effect_Aff_Class.monadAffAff);
var main = function __do() {
    var mode = LocationString.getQueryParam("u")();
    if (mode instanceof Data_Maybe.Just) {
        return AppUrl.redirectToUrlInFragment();
    };
    $foreign.executeJavascriptHacks();
    return Halogen_Aff_Util.runHalogenAff(bind3(Halogen_Aff_Util.awaitBody)(function (body) {
        return Halogen_VDom_Driver.runUI(component1)(Data_Unit.unit)(body);
    }))();
};
export {
    executeJavascriptHacks
} from "./foreign.js";
export {
    main,
    component,
    handleAction,
    prepareHtml,
    doAction,
    updateDocumentTitle,
    updateFocus,
    toggleEditMode,
    updateAutoscroll,
    updateAutoscrollTimer,
    startAutoscroll,
    setDocumentTitle
};
