// Generated by purs version 0.15.7
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as LZString from "../LZString/index.js";
import * as LocationString from "../LocationString/index.js";
import * as TablatureDocument from "../TablatureDocument/index.js";
import * as TablatureRewriter from "../TablatureRewriter/index.js";
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var liftEffect = /* #__PURE__ */ Effect_Class.liftEffect(Effect_Class.monadEffectEffect);
var applySecond = /* #__PURE__ */ Control_Apply.applySecond(Effect.applyEffect);
var pure = /* #__PURE__ */ Control_Applicative.pure(Effect.applicativeEffect);
var setAppQueryString = function (params) {
    return LocationString.setQueryComponents(Data_Array.mapMaybe(identity)([ (function () {
        if (params.noteOrientation instanceof TablatureRewriter.Default) {
            return Data_Maybe.Nothing.value;
        };
        if (params.noteOrientation instanceof TablatureRewriter.Flat) {
            return new Data_Maybe.Just("o=0");
        };
        if (params.noteOrientation instanceof TablatureRewriter.Sharp) {
            return new Data_Maybe.Just("o=1");
        };
        throw new Error("Failed pattern match at AppUrl (line 74, column 5 - line 77, column 26): " + [ params.noteOrientation.constructor.name ]);
    })(), (function () {
        if (params.transposition === 0) {
            return Data_Maybe.Nothing.value;
        };
        return new Data_Maybe.Just("t=" + show(params.transposition));
    })() ]));
};
var saveTablatureToUrl = function (tablatureText) {
    var v = LZString.compressToEncodedURIComponent(tablatureText);
    if (v instanceof Data_Maybe.Just) {
        return liftEffect(LocationString.setFragmentString(v.value0));
    };
    if (v instanceof Data_Maybe.Nothing) {
        return liftEffect(Effect_Console.error("Could not save tablature to URL"));
    };
    throw new Error("Failed pattern match at AppUrl (line 23, column 3 - line 25, column 80): " + [ v.constructor.name ]);
};
var resetUrlParams = /* #__PURE__ */ LocationString.setQueryComponents([  ]);
var redirectToUrlInFragment = function __do() {
    var compressedUrl = LocationString.getFragmentString();
    var v = LZString.decompressFromEncodedURIComponent(compressedUrl);
    if (v instanceof Data_Maybe.Just) {
        return LocationString.setLocationString(v.value0)();
    };
    return applySecond(Effect_Console.error("Could not load decompressed shortlink URL"))(pure(Data_Unit.unit))();
};
var getTranspositionFromUrl = function __do() {
    var maybeTransposition = LocationString.getQueryParam("t")();
    if (maybeTransposition instanceof Data_Maybe.Nothing) {
        return Data_Maybe.Nothing.value;
    };
    if (maybeTransposition instanceof Data_Maybe.Just) {
        var v = Data_Int.fromString(maybeTransposition.value0);
        if (v instanceof Data_Maybe.Just) {
            return new Data_Maybe.Just(v.value0);
        };
        return applySecond(Effect_Console.error("Could not parse transposition parameter"))(pure(Data_Maybe.Nothing.value))();
    };
    throw new Error("Failed pattern match at AppUrl (line 45, column 3 - line 50, column 87): " + [ maybeTransposition.constructor.name ]);
};
var getTablatureTextFromUrl = function __do() {
    var fragment = liftEffect(LocationString.getFragmentString)();
    var $24 = fragment === "" || fragment === "#";
    if ($24) {
        return "";
    };
    var v = LZString.decompressFromEncodedURIComponent(fragment);
    if (v instanceof Data_Maybe.Just) {
        return v.value0;
    };
    if (v instanceof Data_Maybe.Nothing) {
        return applySecond(Effect_Console.error("Could not load tablature from URL"))(pure(""))();
    };
    throw new Error("Failed pattern match at AppUrl (line 31, column 8 - line 33, column 78): " + [ v.constructor.name ]);
};
var getNoteOrientationFromUrl = function __do() {
    var maybeNoteOrientation = LocationString.getQueryParam("o")();
    if (maybeNoteOrientation instanceof Data_Maybe.Nothing) {
        return Data_Maybe.Nothing.value;
    };
    if (maybeNoteOrientation instanceof Data_Maybe.Just) {
        var v = Data_Int.fromString(maybeNoteOrientation.value0);
        if (v instanceof Data_Maybe.Just && v.value0 === 0) {
            return new Data_Maybe.Just(TablatureRewriter.Flat.value);
        };
        if (v instanceof Data_Maybe.Just && v.value0 === 1) {
            return new Data_Maybe.Just(TablatureRewriter.Sharp.value);
        };
        return applySecond(Effect_Console.error("Could not parse note orientation parameter"))(pure(Data_Maybe.Nothing.value))();
    };
    throw new Error("Failed pattern match at AppUrl (line 55, column 3 - line 61, column 90): " + [ maybeNoteOrientation.constructor.name ]);
};
var getAppUrlParams = function __do() {
    var maybeTransposition = getTranspositionFromUrl();
    var maybeNoteOrientation = getNoteOrientationFromUrl();
    return {
        transposition: Data_Maybe.fromMaybe(TablatureDocument.identityTransposition)(maybeTransposition),
        noteOrientation: Data_Maybe.fromMaybe(TablatureRewriter.Default.value)(maybeNoteOrientation)
    };
};
export {
    saveTablatureToUrl,
    getTablatureTextFromUrl,
    redirectToUrlInFragment,
    getTranspositionFromUrl,
    getNoteOrientationFromUrl,
    getAppUrlParams,
    setAppQueryString,
    resetUrlParams
};
