// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as AppState from "../AppState/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_Maybe_Trans from "../Control.Monad.Maybe.Trans/index.js";
import * as Control_Monad_Writer_Class from "../Control.Monad.Writer.Class/index.js";
import * as Control_Monad_Writer_Trans from "../Control.Monad.Writer.Trans/index.js";
import * as CorsProxy from "../CorsProxy/index.js";
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Argonaut_Parser from "../Data.Argonaut.Parser/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_HeytingAlgebra from "../Data.HeytingAlgebra/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_String_Regex from "../Data.String.Regex/index.js";
import * as Data_String_Regex_Flags from "../Data.String.Regex.Flags/index.js";
import * as Data_String_Regex_Unsafe from "../Data.String.Regex.Unsafe/index.js";
import * as Data_Traversable from "../Data.Traversable/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as Effect_Console from "../Effect.Console/index.js";
import * as JsonUtils from "../JsonUtils/index.js";
import * as Utils from "../Utils/index.js";
import * as Web_DOM_DOMParser from "../Web.DOM.DOMParser/index.js";
import * as Web_DOM_Document from "../Web.DOM.Document/index.js";
import * as Web_DOM_Element from "../Web.DOM.Element/index.js";
import * as Web_DOM_ParentNode from "../Web.DOM.ParentNode/index.js";
var bind = /* #__PURE__ */ Control_Bind.bind(Data_Maybe.bindMaybe);
var discard = /* #__PURE__ */ Control_Bind.discard(Control_Bind.discardUnit);
var monadWriterT = /* #__PURE__ */ Control_Monad_Writer_Trans.monadWriterT(Data_Monoid.monoidArray);
var monadTellWriterT = /* #__PURE__ */ Control_Monad_Writer_Trans.monadTellWriterT(Data_Monoid.monoidArray);
var not = /* #__PURE__ */ Data_HeytingAlgebra.not(/* #__PURE__ */ Data_HeytingAlgebra.heytingAlgebraFunction(/* #__PURE__ */ Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)));
var elem = /* #__PURE__ */ Data_Array.elem(/* #__PURE__ */ Data_Maybe.eqMaybe(Data_Eq.eqString));
var applicativeWriterT = /* #__PURE__ */ Control_Monad_Writer_Trans.applicativeWriterT(Data_Monoid.monoidArray);
var map = /* #__PURE__ */ Data_Functor.map(Effect.functorEffect);
var traverse = /* #__PURE__ */ Data_Traversable.traverse(Data_Traversable.traversableArray)(Effect.applicativeEffect);
var toSearchResult = function (dictMonad) {
    var monadWriterT1 = monadWriterT(dictMonad);
    var discard1 = discard(Control_Monad_Maybe_Trans.bindMaybeT(monadWriterT1));
    var when = Control_Applicative.when(Control_Monad_Maybe_Trans.applicativeMaybeT(monadWriterT1));
    var tell = Control_Monad_Writer_Class.tell(Control_Monad_Maybe_Trans.monadTellMaybeT(monadTellWriterT(dictMonad)));
    var hoistMaybe$prime = Utils["hoistMaybe$prime"](dictMonad);
    return function (json) {
        var maybeResult = bind(bind(JsonUtils.child("tab_url")(json))(JsonUtils.string))(function (url) {
            return bind(bind(JsonUtils.child("song_name")(json))(JsonUtils.string))(function (name) {
                return bind(bind(JsonUtils.child("artist_name")(json))(JsonUtils.string))(function (artist) {
                    var rating = bind(JsonUtils.child("rating")(json))(JsonUtils.number);
                    var contentType = bind(JsonUtils.child("type")(json))(JsonUtils.string);
                    var marketingType = bind(JsonUtils.child("marketing_type")(json))(JsonUtils.string);
                    return new Data_Maybe.Just({
                        url: url,
                        rating: rating,
                        name: name,
                        artist: artist,
                        contentType: contentType,
                        marketingType: marketingType
                    });
                });
            });
        });
        return discard1(when(Data_Maybe.isNothing(maybeResult))(tell([ "Could not find search results in json " + Data_Argonaut_Core.stringify(json) ])))(function () {
            return hoistMaybe$prime(maybeResult);
        });
    };
};
var hoistMaybe = function (dictMonad) {
    var $123 = Control_Applicative.pure(dictMonad.Applicative0());
    return function ($124) {
        return Control_Monad_Maybe_Trans.MaybeT($123($124));
    };
};
var filterSearchResults = /* #__PURE__ */ (function () {
    var pred = function (v) {
        return Data_Maybe.isJust(v.rating) && (Data_Maybe.isNothing(v.marketingType) && not(elem)(v.contentType)([ new Data_Maybe.Just("Pro"), new Data_Maybe.Just("Video"), new Data_Maybe.Just("Power") ]));
    };
    return Data_Array.filter(pred);
})();
var extractTab = function (dictMonad) {
    var monadWriterT1 = monadWriterT(dictMonad);
    var bindMaybeT = Control_Monad_Maybe_Trans.bindMaybeT(monadWriterT1);
    var discard1 = discard(bindMaybeT);
    var applicativeMaybeT = Control_Monad_Maybe_Trans.applicativeMaybeT(monadWriterT1);
    var when = Control_Applicative.when(applicativeMaybeT);
    var tell = Control_Monad_Writer_Class.tell(Control_Monad_Maybe_Trans.monadTellMaybeT(monadTellWriterT(dictMonad)));
    var bind1 = Control_Bind.bind(bindMaybeT);
    var pure = Control_Applicative.pure(applicativeWriterT(dictMonad.Applicative0()));
    var pure1 = Control_Applicative.pure(applicativeMaybeT);
    return function (json) {
        var maybeRawTab = bind(bind(bind(bind(bind(bind(JsonUtils.child("store")(json))(JsonUtils.child("page")))(JsonUtils.child("data")))(JsonUtils.child("tab_view")))(JsonUtils.child("wiki_tab")))(JsonUtils.child("content")))(JsonUtils.string);
        return discard1(when(Data_Maybe.isNothing(maybeRawTab))(tell([ "Could not retrieve tablature data from json " + Data_Argonaut_Core.stringify(json) ])))(function () {
            return bind1(pure(maybeRawTab))(function (rawTab) {
                return pure1($foreign["_htmlDecode"](Data_String_Regex.replace(Data_String_Regex_Unsafe.unsafeRegex("\\[\\/?(ch|tab)\\]")(Data_String_Regex_Flags.global))("")(rawTab)));
            });
        });
    };
};
var extractSearchResults = function (dictMonad) {
    var monadWriterT1 = monadWriterT(dictMonad);
    var bindMaybeT = Control_Monad_Maybe_Trans.bindMaybeT(monadWriterT1);
    var discard1 = discard(bindMaybeT);
    var when = Control_Applicative.when(Control_Monad_Maybe_Trans.applicativeMaybeT(monadWriterT1));
    var tell = Control_Monad_Writer_Class.tell(Control_Monad_Maybe_Trans.monadTellMaybeT(monadTellWriterT(dictMonad)));
    var bind1 = Control_Bind.bind(bindMaybeT);
    var hoistMaybe$prime = Utils["hoistMaybe$prime"](dictMonad);
    var mapFlipped = Data_Functor.mapFlipped(Control_Monad_Writer_Trans.functorWriterT(((dictMonad.Bind1()).Apply0()).Functor0()));
    var mapMaybeT = Utils.mapMaybeT(monadWriterT1);
    var toSearchResult1 = toSearchResult(dictMonad);
    return function (json) {
        var maybeJsonSearchResults = bind(bind(bind(bind(JsonUtils.child("store")(json))(JsonUtils.child("page")))(JsonUtils.child("data")))(JsonUtils.child("results")))(JsonUtils.array);
        return discard1(when(Data_Maybe.isNothing(maybeJsonSearchResults))(tell([ "Could not find search results in json " + Data_Argonaut_Core.stringify(json) ])))(function () {
            return bind1(hoistMaybe$prime(maybeJsonSearchResults))(function (jsonSearchResults) {
                return mapFlipped(mapMaybeT(toSearchResult1)(jsonSearchResults))(Data_Maybe.Just.create);
            });
        });
    };
};
var extractDataContent = function (dictMonadAff) {
    var MonadEffect0 = dictMonadAff.MonadEffect0();
    var Monad0 = MonadEffect0.Monad0();
    var bind1 = Control_Bind.bind(Control_Monad_Maybe_Trans.bindMaybeT(Monad0));
    var liftEffect = Effect_Class.liftEffect(Control_Monad_Maybe_Trans.monadEffectMaybe(MonadEffect0));
    var liftEffect1 = Effect_Class.liftEffect(MonadEffect0);
    var pure = Control_Applicative.pure(Monad0.Applicative0());
    return function (input) {
        return bind1(liftEffect(Web_DOM_DOMParser.makeDOMParser))(function (parser) {
            return bind1(liftEffect1(map(Data_Either.hush)(Web_DOM_DOMParser.parseHTMLFromString(input)(parser))))(function (doc) {
                return bind1(liftEffect1(Web_DOM_ParentNode.querySelector(".js-store")(Web_DOM_Document.toParentNode(doc))))(function (elem1) {
                    return bind1(liftEffect1(Web_DOM_Element.getAttribute("data-content")(elem1)))(function (jsonString) {
                        return pure(Data_Either.hush(Data_Argonaut_Parser.jsonParser(jsonString)));
                    });
                });
            });
        });
    };
};
var fetchUrlDataContent = function (dictMonadAff) {
    var bind1 = Control_Bind.bind(Control_Monad_Maybe_Trans.bindMaybeT((dictMonadAff.MonadEffect0()).Monad0()));
    var fetchThroughProxy = CorsProxy.fetchThroughProxy(dictMonadAff);
    var extractDataContent1 = extractDataContent(dictMonadAff);
    return function (url) {
        return bind1(fetchThroughProxy(url))(function (result) {
            return extractDataContent1(result);
        });
    };
};
var fetchTabFromUrl = function (dictMonadAff) {
    var MonadEffect0 = dictMonadAff.MonadEffect0();
    var Monad0 = MonadEffect0.Monad0();
    var bind1 = Control_Bind.bind(Control_Monad_Maybe_Trans.bindMaybeT(Monad0));
    var fetchUrlDataContent1 = fetchUrlDataContent(dictMonadAff);
    var extractTab1 = extractTab(Control_Monad_Maybe_Trans.monadMaybeT(Monad0));
    var liftEffect = Effect_Class.liftEffect(Control_Monad_Maybe_Trans.monadEffectMaybe(MonadEffect0));
    var hoistMaybe1 = hoistMaybe(Monad0);
    return function (dictMonadState) {
        return function (url) {
            return bind1(fetchUrlDataContent1(url))(function (dataContent) {
                return bind1(Control_Monad_Writer_Trans.runWriterT(Control_Monad_Maybe_Trans.runMaybeT(extractTab1(dataContent))))(function (v) {
                    return bind1(liftEffect(traverse(Effect_Console.error)(v.value1)))(function () {
                        return hoistMaybe1(v.value0);
                    });
                });
            });
        };
    };
};
var execSearch = function (dictMonadAff) {
    var MonadEffect0 = dictMonadAff.MonadEffect0();
    var Monad0 = MonadEffect0.Monad0();
    var map1 = Data_Functor.map(((Monad0.Bind1()).Apply0()).Functor0());
    var bind1 = Control_Bind.bind(Control_Monad_Maybe_Trans.bindMaybeT(Monad0));
    var fetchUrlDataContent1 = fetchUrlDataContent(dictMonadAff);
    var extractSearchResults1 = extractSearchResults(Control_Monad_Maybe_Trans.monadMaybeT(Monad0));
    var liftEffect = Effect_Class.liftEffect(Control_Monad_Maybe_Trans.monadEffectMaybe(MonadEffect0));
    var hoistMaybe1 = hoistMaybe(Monad0);
    return function (dictMonadState) {
        var setState = AppState.setState(Control_Monad_Maybe_Trans.monadStateMaybeT(dictMonadState));
        return function (phrase) {
            return map1(Data_Function["const"](Data_Unit.unit))(Control_Monad_Maybe_Trans.runMaybeT((function () {
                var url = "https://www.ultimate-guitar.com/search.php?search_type=title&value=" + phrase;
                return bind1(fetchUrlDataContent1(url))(function (dataContent) {
                    return bind1(Control_Monad_Writer_Trans.runWriterT(Control_Monad_Maybe_Trans.runMaybeT(extractSearchResults1(dataContent))))(function (v) {
                        return bind1(liftEffect(traverse(Effect_Console.error)(v.value1)))(function () {
                            return bind1(hoistMaybe1(v.value0))(function (searchResults) {
                                return setState(function (dictStrong) {
                                    return AppState["_searchResults"](dictStrong);
                                })(new Data_Maybe.Just(filterSearchResults(searchResults)));
                            });
                        });
                    });
                });
            })()));
        };
    };
};
export {
    _htmlDecode
} from "./foreign.js";
export {
    hoistMaybe,
    execSearch,
    filterSearchResults,
    extractSearchResults,
    toSearchResult,
    fetchTabFromUrl,
    extractTab,
    fetchUrlDataContent,
    extractDataContent
};
