// Generated by purs version 0.15.7
import * as Data_Bounded_Generic from "../Data.Bounded.Generic/index.js";
import * as Data_Enum_Generic from "../Data.Enum.Generic/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
var genericEnumConstructor = /* #__PURE__ */ Data_Enum_Generic.genericEnumConstructor(Data_Enum_Generic.genericEnumNoArguments);
var genericEnumSum = /* #__PURE__ */ Data_Enum_Generic.genericEnumSum(genericEnumConstructor)(/* #__PURE__ */ Data_Bounded_Generic.genericTopConstructor(Data_Bounded_Generic.genericTopNoArguments));
var genericBottomConstructor = /* #__PURE__ */ Data_Bounded_Generic.genericBottomConstructor(Data_Bounded_Generic.genericBottomNoArguments);
var genericBottomSum = /* #__PURE__ */ Data_Bounded_Generic.genericBottomSum(genericBottomConstructor);
var genericEnumSum1 = /* #__PURE__ */ genericEnumSum(/* #__PURE__ */ genericEnumSum(/* #__PURE__ */ genericEnumSum(/* #__PURE__ */ genericEnumSum(/* #__PURE__ */ genericEnumSum(genericEnumConstructor)(genericBottomConstructor))(genericBottomSum))(genericBottomSum))(genericBottomSum))(genericBottomSum);
var Slowest = /* #__PURE__ */ (function () {
    function Slowest() {

    };
    Slowest.value = new Slowest();
    return Slowest;
})();
var Slower = /* #__PURE__ */ (function () {
    function Slower() {

    };
    Slower.value = new Slower();
    return Slower;
})();
var Slow = /* #__PURE__ */ (function () {
    function Slow() {

    };
    Slow.value = new Slow();
    return Slow;
})();
var Normal = /* #__PURE__ */ (function () {
    function Normal() {

    };
    Normal.value = new Normal();
    return Normal;
})();
var Fast = /* #__PURE__ */ (function () {
    function Fast() {

    };
    Fast.value = new Fast();
    return Fast;
})();
var Fastest = /* #__PURE__ */ (function () {
    function Fastest() {

    };
    Fastest.value = new Fastest();
    return Fastest;
})();
var printAutoscrollSpeed = {
    print: function (v) {
        if (v instanceof Slowest) {
            return "(0.2)";
        };
        if (v instanceof Slower) {
            return "(0.4)";
        };
        if (v instanceof Slow) {
            return "(0.6)";
        };
        if (v instanceof Normal) {
            return "(1.0)";
        };
        if (v instanceof Fast) {
            return "(2.0)";
        };
        if (v instanceof Fastest) {
            return "(4.0)";
        };
        throw new Error("Failed pattern match at AutoscrollSpeed (line 18, column 1 - line 24, column 26): " + [ v.constructor.name ]);
    }
};
var speedToIntervalPixelDelta = function (v) {
    if (v instanceof Slowest) {
        return 1;
    };
    if (v instanceof Slower) {
        return 1;
    };
    if (v instanceof Slow) {
        return 1;
    };
    if (v instanceof Normal) {
        return 1;
    };
    if (v instanceof Fast) {
        return 1;
    };
    if (v instanceof Fastest) {
        return 2;
    };
    throw new Error("Failed pattern match at AutoscrollSpeed (line 41, column 1 - line 41, column 52): " + [ v.constructor.name ]);
};
var speedToIntervalMs = function (v) {
    if (v instanceof Slowest) {
        return 400;
    };
    if (v instanceof Slower) {
        return 200;
    };
    if (v instanceof Slow) {
        return 120;
    };
    if (v instanceof Normal) {
        return 80;
    };
    if (v instanceof Fast) {
        return 40;
    };
    if (v instanceof Fastest) {
        return 40;
    };
    throw new Error("Failed pattern match at AutoscrollSpeed (line 33, column 1 - line 33, column 44): " + [ v.constructor.name ]);
};
var genericAutoscrollSpeed = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Slowest.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return Slower.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return Slow.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return Normal.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return Fast.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))) {
            return Fastest.value;
        };
        throw new Error("Failed pattern match at AutoscrollSpeed (line 28, column 1 - line 28, column 68): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Slowest) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof Slower) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof Slow) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof Normal) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
        };
        if (x instanceof Fast) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
        };
        if (x instanceof Fastest) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)))));
        };
        throw new Error("Failed pattern match at AutoscrollSpeed (line 28, column 1 - line 28, column 68): " + [ x.constructor.name ]);
    }
};
var eqAutoscrollSpeed = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Slowest && y instanceof Slowest) {
                return true;
            };
            if (x instanceof Slower && y instanceof Slower) {
                return true;
            };
            if (x instanceof Slow && y instanceof Slow) {
                return true;
            };
            if (x instanceof Normal && y instanceof Normal) {
                return true;
            };
            if (x instanceof Fast && y instanceof Fast) {
                return true;
            };
            if (x instanceof Fastest && y instanceof Fastest) {
                return true;
            };
            return false;
        };
    }
};
var ordAutoscrollSpeed = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Slowest && y instanceof Slowest) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slowest) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slowest) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slower && y instanceof Slower) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slower) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slower) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Slow && y instanceof Slow) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Slow) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Slow) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Normal && y instanceof Normal) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Normal) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Normal) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Fast && y instanceof Fast) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Fast) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Fast) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Fastest && y instanceof Fastest) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at AutoscrollSpeed (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqAutoscrollSpeed;
    }
};
var enumAutoscrollSpeed = {
    succ: /* #__PURE__ */ Data_Enum_Generic.genericSucc(genericAutoscrollSpeed)(genericEnumSum1),
    pred: /* #__PURE__ */ Data_Enum_Generic.genericPred(genericAutoscrollSpeed)(genericEnumSum1),
    Ord0: function () {
        return ordAutoscrollSpeed;
    }
};
export {
    Slowest,
    Slower,
    Slow,
    Normal,
    Fast,
    Fastest,
    speedToIntervalMs,
    speedToIntervalPixelDelta,
    printAutoscrollSpeed,
    eqAutoscrollSpeed,
    ordAutoscrollSpeed,
    genericAutoscrollSpeed,
    enumAutoscrollSpeed
};
