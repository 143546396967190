// Generated by purs version 0.15.7
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Generic_Rep from "../Data.Generic.Rep/index.js";
import * as Data_Lens_Iso from "../Data.Lens.Iso/index.js";
import * as Data_Lens_Lens from "../Data.Lens.Lens/index.js";
import * as Data_Lens_Lens_Unit from "../Data.Lens.Lens.Unit/index.js";
import * as Data_Lens_Prism from "../Data.Lens.Prism/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
var _ToGeneric = function (dictGeneric) {
    var from = Data_Generic_Rep.from(dictGeneric);
    var to = Data_Generic_Rep.to(dictGeneric);
    return function (dictProfunctor) {
        return Data_Lens_Iso.iso(from)(to)(dictProfunctor);
    };
};
var _SumRight = function (dictChoice) {
    return Data_Lens_Prism.prism(Data_Generic_Rep.Inr.create)(function (v) {
        if (v instanceof Data_Generic_Rep.Inr) {
            return new Data_Either.Right(v.value0);
        };
        return new Data_Either.Left(v);
    })(dictChoice);
};
var _SumLeft = function (dictChoice) {
    return Data_Lens_Prism.prism(Data_Generic_Rep.Inl.create)(function (v) {
        if (v instanceof Data_Generic_Rep.Inl) {
            return new Data_Either.Right(v.value0);
        };
        return new Data_Either.Left(v);
    })(dictChoice);
};
var _ProductRight = function (dictStrong) {
    return Data_Lens_Lens["lens$prime"](function (v) {
        return new Data_Tuple.Tuple(v.value1, function (b) {
            return new Data_Generic_Rep.Product(v.value0, b);
        });
    })(dictStrong);
};
var _ProductLeft = function (dictStrong) {
    return Data_Lens_Lens["lens$prime"](function (v) {
        return new Data_Tuple.Tuple(v.value0, function (a) {
            return new Data_Generic_Rep.Product(a, v.value1);
        });
    })(dictStrong);
};
var _NoArguments = function (dictStrong) {
    return Data_Lens_Lens_Unit.united(dictStrong);
};
var _Constructor = function (dictProfunctor) {
    var wrapC = function (a) {
        return a;
    };
    var unwrapC = function (v) {
        return v;
    };
    return Data_Lens_Iso.iso(unwrapC)(wrapC)(dictProfunctor);
};
var _Argument = function (dictProfunctor) {
    var wrapA = function (a) {
        return a;
    };
    var unwrapA = function (v) {
        return v;
    };
    return Data_Lens_Iso.iso(unwrapA)(wrapA)(dictProfunctor);
};
export {
    _SumRight,
    _SumLeft,
    _ProductLeft,
    _ProductRight,
    _Constructor,
    _Argument,
    _NoArguments,
    _ToGeneric
};
