// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Control_Monad_Except from "../Control.Monad.Except/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Identity from "../Data.Identity/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Foreign from "../Foreign/index.js";
var readString = /* #__PURE__ */ Foreign.readString(Data_Identity.monadIdentity);
var setQueryComponents = function (components) {
    return $foreign.setQueryString(Data_String_Common.joinWith("&")(components));
};
var getQueryParam = function (paramName) {
    return function __do() {
        var result = $foreign["_getQueryParam"](paramName)();
        var v = Control_Monad_Except.runExcept(readString(result));
        if (v instanceof Data_Either.Right) {
            return new Data_Maybe.Just(v.value0);
        };
        return Data_Maybe.Nothing.value;
    };
};
export {
    getLocationString,
    getLocationBaseString,
    setLocationString,
    getFragmentString,
    setFragmentString,
    getQueryString,
    setQueryString,
    _getQueryParam
} from "./foreign.js";
export {
    getQueryParam,
    setQueryComponents
};
