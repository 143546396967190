// Generated by purs version 0.15.7
import * as Control_Alt from "../Control.Alt/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Apply from "../Control.Apply/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_ST_Internal from "../Control.Monad.ST.Internal/index.js";
import * as Control_Plus from "../Control.Plus/index.js";
import * as Data_Array from "../Data.Array/index.js";
import * as Data_Array_ST from "../Data.Array.ST/index.js";
import * as Data_Array_ST_Iterator from "../Data.Array.ST.Iterator/index.js";
import * as Data_Either from "../Data.Either/index.js";
import * as Data_Foldable from "../Data.Foldable/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_List from "../Data.List/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Map_Internal from "../Data.Map.Internal/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid from "../Data.Monoid/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
var toUnfoldable = /* #__PURE__ */ Data_Map_Internal.toUnfoldable(Data_List_Types.unfoldableList);
var foldr = /* #__PURE__ */ Data_Foldable.foldr(Data_List_Types.foldableList);
var alt = /* #__PURE__ */ Control_Alt.alt(Data_List_Types.altList);
var pure = /* #__PURE__ */ Control_Applicative.pure(Data_List_Types.applicativeList);
var empty = /* #__PURE__ */ Control_Plus.empty(Data_List_Types.plusList);
var $$void = /* #__PURE__ */ Data_Functor["void"](Control_Monad_ST_Internal.functorST);
var pure1 = /* #__PURE__ */ Control_Applicative.pure(Control_Monad_ST_Internal.applicativeST);
var apply = /* #__PURE__ */ Control_Apply.apply(Control_Monad_ST_Internal.applyST);
var map = /* #__PURE__ */ Data_Functor.map(Control_Monad_ST_Internal.functorST);
var separate = function (dict) {
    return dict.separate;
};
var mapToList = function (dictOrd) {
    return toUnfoldable;
};
var compactableMaybe = {
    compact: /* #__PURE__ */ Control_Bind.join(Data_Maybe.bindMaybe),
    separate: function (v) {
        if (v instanceof Data_Maybe.Nothing) {
            return {
                left: Data_Maybe.Nothing.value,
                right: Data_Maybe.Nothing.value
            };
        };
        if (v instanceof Data_Maybe.Just) {
            if (v.value0 instanceof Data_Either.Left) {
                return {
                    left: new Data_Maybe.Just(v.value0.value0),
                    right: Data_Maybe.Nothing.value
                };
            };
            if (v.value0 instanceof Data_Either.Right) {
                return {
                    left: Data_Maybe.Nothing.value,
                    right: new Data_Maybe.Just(v.value0.value0)
                };
            };
            throw new Error("Failed pattern match at Data.Compactable (line 91, column 23 - line 93, column 48): " + [ v.value0.constructor.name ]);
        };
        throw new Error("Failed pattern match at Data.Compactable (line 87, column 1 - line 93, column 48): " + [ v.constructor.name ]);
    }
};
var compactableMap = function (dictOrd) {
    var alter = Data_Map_Internal.alter(dictOrd);
    var mapToList1 = mapToList(dictOrd);
    var insert = Data_Map_Internal.insert(dictOrd);
    return {
        compact: (function () {
            var select = function (v) {
                return function (m) {
                    return alter(Data_Function["const"](v.value1))(v.value0)(m);
                };
            };
            var $104 = foldr(select)(Data_Map_Internal.empty);
            return function ($105) {
                return $104(mapToList1($105));
            };
        })(),
        separate: (function () {
            var select = function (v) {
                return function (v1) {
                    if (v.value1 instanceof Data_Either.Left) {
                        return {
                            left: insert(v.value0)(v.value1.value0)(v1.left),
                            right: v1.right
                        };
                    };
                    if (v.value1 instanceof Data_Either.Right) {
                        return {
                            left: v1.left,
                            right: insert(v.value0)(v.value1.value0)(v1.right)
                        };
                    };
                    throw new Error("Failed pattern match at Data.Compactable (line 142, column 44 - line 144, column 63): " + [ v.value1.constructor.name ]);
                };
            };
            var $106 = foldr(select)({
                left: Data_Map_Internal.empty,
                right: Data_Map_Internal.empty
            });
            return function ($107) {
                return $106(mapToList1($107));
            };
        })()
    };
};
var compactableList = {
    compact: Data_List.catMaybes,
    separate: /* #__PURE__ */ (function () {
        var go = function (acc) {
            return function (v) {
                if (v instanceof Data_Either.Left) {
                    return {
                        left: alt(acc.left)(pure(v.value0)),
                        right: acc.right
                    };
                };
                if (v instanceof Data_Either.Right) {
                    return {
                        right: alt(acc.right)(pure(v.value0)),
                        left: acc.left
                    };
                };
                throw new Error("Failed pattern match at Data.Compactable (line 131, column 14 - line 133, column 54): " + [ v.constructor.name ]);
            };
        };
        return Data_Foldable.foldl(Data_List_Types.foldableList)(go)({
            left: empty,
            right: empty
        });
    })()
};
var compactableEither = function (dictMonoid) {
    var mempty = Data_Monoid.mempty(dictMonoid);
    return {
        compact: function (v) {
            if (v instanceof Data_Either.Left) {
                return new Data_Either.Left(v.value0);
            };
            if (v instanceof Data_Either.Right) {
                if (v.value0 instanceof Data_Maybe.Just) {
                    return new Data_Either.Right(v.value0.value0);
                };
                if (v.value0 instanceof Data_Maybe.Nothing) {
                    return new Data_Either.Left(mempty);
                };
                throw new Error("Failed pattern match at Data.Compactable (line 97, column 23 - line 99, column 27): " + [ v.value0.constructor.name ]);
            };
            throw new Error("Failed pattern match at Data.Compactable (line 95, column 1 - line 104, column 53): " + [ v.constructor.name ]);
        },
        separate: function (v) {
            if (v instanceof Data_Either.Left) {
                return {
                    left: new Data_Either.Left(v.value0),
                    right: new Data_Either.Left(v.value0)
                };
            };
            if (v instanceof Data_Either.Right) {
                if (v.value0 instanceof Data_Either.Left) {
                    return {
                        left: new Data_Either.Right(v.value0.value0),
                        right: new Data_Either.Left(mempty)
                    };
                };
                if (v.value0 instanceof Data_Either.Right) {
                    return {
                        left: new Data_Either.Left(mempty),
                        right: new Data_Either.Right(v.value0.value0)
                    };
                };
                throw new Error("Failed pattern match at Data.Compactable (line 102, column 24 - line 104, column 53): " + [ v.value0.constructor.name ]);
            };
            throw new Error("Failed pattern match at Data.Compactable (line 95, column 1 - line 104, column 53): " + [ v.constructor.name ]);
        }
    };
};
var compactableArray = {
    compact: function (xs) {
        return (function __do() {
            var result = Data_Array_ST["new"]();
            var iter = Data_Array_ST_Iterator.iterator(function (v) {
                return Data_Array.index(xs)(v);
            })();
            Data_Array_ST_Iterator.iterate(iter)(function ($108) {
                return $$void((function (v) {
                    if (v instanceof Data_Maybe.Nothing) {
                        return pure1(0);
                    };
                    if (v instanceof Data_Maybe.Just) {
                        return Data_Array_ST.push(v.value0)(result);
                    };
                    throw new Error("Failed pattern match at Data.Compactable (line 111, column 34 - line 113, column 35): " + [ v.constructor.name ]);
                })($108));
            })();
            return Data_Array_ST.unsafeFreeze(result)();
        })();
    },
    separate: function (xs) {
        return (function __do() {
            var ls = Data_Array_ST["new"]();
            var rs = Data_Array_ST["new"]();
            var iter = Data_Array_ST_Iterator.iterator(function (v) {
                return Data_Array.index(xs)(v);
            })();
            Data_Array_ST_Iterator.iterate(iter)(function ($109) {
                return $$void((function (v) {
                    if (v instanceof Data_Either.Left) {
                        return Data_Array_ST.push(v.value0)(ls);
                    };
                    if (v instanceof Data_Either.Right) {
                        return Data_Array_ST.push(v.value0)(rs);
                    };
                    throw new Error("Failed pattern match at Data.Compactable (line 122, column 34 - line 124, column 31): " + [ v.constructor.name ]);
                })($109));
            })();
            return apply(map(function (v) {
                return function (v1) {
                    return {
                        left: v,
                        right: v1
                    };
                };
            })(Data_Array_ST.unsafeFreeze(ls)))(Data_Array_ST.unsafeFreeze(rs))();
        })();
    }
};
var compactDefault = function (dictFunctor) {
    var map1 = Data_Functor.map(dictFunctor);
    return function (dictCompactable) {
        var $110 = separate(dictCompactable);
        var $111 = map1(Data_Either.note(Data_Unit.unit));
        return function ($112) {
            return (function (v) {
                return v.right;
            })($110($111($112)));
        };
    };
};
var compact = function (dict) {
    return dict.compact;
};
var separateDefault = function (dictFunctor) {
    var map1 = Data_Functor.map(dictFunctor);
    return function (dictCompactable) {
        var compact1 = compact(dictCompactable);
        return function (xs) {
            var swapEither = function (e) {
                if (e instanceof Data_Either.Left) {
                    return new Data_Either.Right(e.value0);
                };
                if (e instanceof Data_Either.Right) {
                    return new Data_Either.Left(e.value0);
                };
                throw new Error("Failed pattern match at Data.Compactable (line 83, column 20 - line 85, column 24): " + [ e.constructor.name ]);
            };
            return {
                left: compact1(map1(function ($113) {
                    return Data_Either.hush(swapEither($113));
                })(xs)),
                right: compact1(map1(Data_Either.hush)(xs))
            };
        };
    };
};
var bindMaybe = function (dictBind) {
    var bind1 = Control_Bind.bind(dictBind);
    return function (dictCompactable) {
        var compact1 = compact(dictCompactable);
        return function (x) {
            var $114 = bind1(x);
            return function ($115) {
                return compact1($114($115));
            };
        };
    };
};
var bindEither = function (dictBind) {
    var bind1 = Control_Bind.bind(dictBind);
    return function (dictCompactable) {
        var separate1 = separate(dictCompactable);
        return function (x) {
            var $116 = bind1(x);
            return function ($117) {
                return separate1($116($117));
            };
        };
    };
};
var applyMaybe = function (dictApply) {
    var apply1 = Control_Apply.apply(dictApply);
    return function (dictCompactable) {
        var compact1 = compact(dictCompactable);
        return function (p) {
            var $118 = apply1(p);
            return function ($119) {
                return compact1($118($119));
            };
        };
    };
};
var applyEither = function (dictApply) {
    var apply1 = Control_Apply.apply(dictApply);
    return function (dictCompactable) {
        var separate1 = separate(dictCompactable);
        return function (p) {
            var $120 = apply1(p);
            return function ($121) {
                return separate1($120($121));
            };
        };
    };
};
export {
    compact,
    separate,
    compactDefault,
    separateDefault,
    applyMaybe,
    applyEither,
    bindMaybe,
    bindEither,
    compactableMaybe,
    compactableEither,
    compactableArray,
    compactableList,
    compactableMap
};
