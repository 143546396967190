// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Symbol from "../Data.Symbol/index.js";
import * as Data_Unit from "../Data.Unit/index.js";
import * as Record_Unsafe from "../Record.Unsafe/index.js";
import * as Type_Proxy from "../Type.Proxy/index.js";
var semiringRecord = /* #__PURE__ */ Data_Semiring.semiringRecord();
var subRecord = function (dict) {
    return dict.subRecord;
};
var sub = function (dict) {
    return dict.sub;
};
var ringUnit = {
    sub: function (v) {
        return function (v1) {
            return Data_Unit.unit;
        };
    },
    Semiring0: function () {
        return Data_Semiring.semiringUnit;
    }
};
var ringRecordNil = {
    subRecord: function (v) {
        return function (v1) {
            return function (v2) {
                return {};
            };
        };
    },
    SemiringRecord0: function () {
        return Data_Semiring.semiringRecordNil;
    }
};
var ringRecordCons = function (dictIsSymbol) {
    var reflectSymbol = Data_Symbol.reflectSymbol(dictIsSymbol);
    var semiringRecordCons = Data_Semiring.semiringRecordCons(dictIsSymbol)();
    return function () {
        return function (dictRingRecord) {
            var subRecord1 = subRecord(dictRingRecord);
            var semiringRecordCons1 = semiringRecordCons(dictRingRecord.SemiringRecord0());
            return function (dictRing) {
                var sub1 = sub(dictRing);
                var semiringRecordCons2 = semiringRecordCons1(dictRing.Semiring0());
                return {
                    subRecord: function (v) {
                        return function (ra) {
                            return function (rb) {
                                var tail = subRecord1(Type_Proxy["Proxy"].value)(ra)(rb);
                                var key = reflectSymbol(Type_Proxy["Proxy"].value);
                                var insert = Record_Unsafe.unsafeSet(key);
                                var get = Record_Unsafe.unsafeGet(key);
                                return insert(sub1(get(ra))(get(rb)))(tail);
                            };
                        };
                    },
                    SemiringRecord0: function () {
                        return semiringRecordCons2;
                    }
                };
            };
        };
    };
};
var ringRecord = function () {
    return function (dictRingRecord) {
        var semiringRecord1 = semiringRecord(dictRingRecord.SemiringRecord0());
        return {
            sub: subRecord(dictRingRecord)(Type_Proxy["Proxy"].value),
            Semiring0: function () {
                return semiringRecord1;
            }
        };
    };
};
var ringProxy = {
    sub: function (v) {
        return function (v1) {
            return Type_Proxy["Proxy"].value;
        };
    },
    Semiring0: function () {
        return Data_Semiring.semiringProxy;
    }
};
var ringNumber = {
    sub: $foreign.numSub,
    Semiring0: function () {
        return Data_Semiring.semiringNumber;
    }
};
var ringInt = {
    sub: $foreign.intSub,
    Semiring0: function () {
        return Data_Semiring.semiringInt;
    }
};
var ringFn = function (dictRing) {
    var sub1 = sub(dictRing);
    var semiringFn = Data_Semiring.semiringFn(dictRing.Semiring0());
    return {
        sub: function (f) {
            return function (g) {
                return function (x) {
                    return sub1(f(x))(g(x));
                };
            };
        },
        Semiring0: function () {
            return semiringFn;
        }
    };
};
var negate = function (dictRing) {
    var sub1 = sub(dictRing);
    var zero = Data_Semiring.zero(dictRing.Semiring0());
    return function (a) {
        return sub1(zero)(a);
    };
};
export {
    sub,
    negate,
    subRecord,
    ringInt,
    ringNumber,
    ringUnit,
    ringFn,
    ringProxy,
    ringRecord,
    ringRecordNil,
    ringRecordCons
};
export {
    add,
    mul,
    one,
    zero
} from "../Data.Semiring/index.js";
