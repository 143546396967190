// Generated by purs version 0.15.7
import * as Control_Category from "../Control.Category/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Lens_Types from "../Data.Lens.Types/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
import * as Data_Profunctor_Strong from "../Data.Profunctor.Strong/index.js";
var unwrap = /* #__PURE__ */ Data_Newtype.unwrap();
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var fanout = /* #__PURE__ */ Data_Profunctor_Strong.fanout(Control_Category.categoryFn)(Data_Profunctor_Strong.strongFn);
var view = function (l) {
    return unwrap(l(identity));
};
var viewOn = function (s) {
    return function (l) {
        return view(l)(s);
    };
};
var use = function (dictMonadState) {
    var gets = Control_Monad_State_Class.gets(dictMonadState);
    return function (p) {
        return gets(function (v) {
            return viewOn(v)(p);
        });
    };
};
var to = function (f) {
    return function (p) {
        var $10 = unwrap(p);
        return function ($11) {
            return $10(f($11));
        };
    };
};
var takeBoth = function (l) {
    return function (r) {
        return to(fanout(view(l))(view(r)));
    };
};
var iview = function (l) {
    return unwrap(l(identity));
};
var iuse = function (dictMonadState) {
    var gets = Control_Monad_State_Class.gets(dictMonadState);
    return function (p) {
        return gets(iview(p));
    };
};
var cloneGetter = function (g) {
    return to(view(g));
};
export {
    viewOn,
    view,
    to,
    takeBoth,
    use,
    iview,
    iuse,
    cloneGetter
};
export {
    Indexed
} from "../Data.Lens.Types/index.js";
