// Generated by purs version 0.15.7
import * as AppUrl from "../AppUrl/index.js";
import * as AutoscrollSpeed from "../AutoscrollSpeed/index.js";
import * as Cache from "../Cache/index.js";
import * as Control_Applicative from "../Control.Applicative/index.js";
import * as Control_Bind from "../Control.Bind/index.js";
import * as Control_Monad_State_Class from "../Control.Monad.State.Class/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_Lens_Barlow from "../Data.Lens.Barlow/index.js";
import * as Data_Lens_Barlow_Construction from "../Data.Lens.Barlow.Construction/index.js";
import * as Data_Lens_Getter from "../Data.Lens.Getter/index.js";
import * as Data_Lens_Internal_Forget from "../Data.Lens.Internal.Forget/index.js";
import * as Data_Lens_Setter from "../Data.Lens.Setter/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Profunctor_Strong from "../Data.Profunctor.Strong/index.js";
import * as Data_String_Regex from "../Data.String.Regex/index.js";
import * as Data_String_Regex_Flags from "../Data.String.Regex.Flags/index.js";
import * as Data_String_Regex_Unsafe from "../Data.String.Regex.Unsafe/index.js";
import * as Effect from "../Effect/index.js";
import * as Effect_Class from "../Effect.Class/index.js";
import * as LocalStorage from "../LocalStorage/index.js";
import * as TablatureDocument from "../TablatureDocument/index.js";
import * as TablatureParser from "../TablatureParser/index.js";
import * as TablatureRewriter from "../TablatureRewriter/index.js";
var barlowInstance = /* #__PURE__ */ Data_Lens_Barlow.barlowInstance();
var constructBarlowTConsRecor = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "urlParams";
    }
})()();
var mapFlipped = /* #__PURE__ */ Data_Functor.mapFlipped(Effect.functorEffect);
var constructBarlowTConsRecor1 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "tablatureTitle";
    }
})()();
var constructBarlowTConsRecor2 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "tablatureText";
    }
})()();
var constructBarlowTConsRecor3 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "tabNormalizationEnabled";
    }
})()();
var constructBarlowTConsRecor4 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "tabDozenalizationEnabled";
    }
})()();
var constructBarlowTConsRecor5 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "searchResults";
    }
})()();
var constructBarlowTConsRecor6 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "searchPhrase";
    }
})()();
var constructBarlowTConsRecor7 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "scrollTop";
    }
})()();
var constructBarlowTConsRecor8 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "rewriteResult";
    }
})()();
var constructBarlowTConsRecor9 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "parseResult";
    }
})()();
var constructBarlowTConsRecor10 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "mode";
    }
})()();
var constructBarlowTConsRecor11 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "loading";
    }
})()();
var constructBarlowTConsRecor12 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "ignoreDozenalization";
    }
})()();
var constructBarlowTConsRecor13 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "chordNormalizationEnabled";
    }
})()();
var constructBarlowTConsRecor14 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "chordDozenalizationEnabled";
    }
})()();
var constructBarlowTConsRecor15 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "autoscrollTimer";
    }
})()();
var constructBarlowTConsRecor16 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "autoscrollSpeed";
    }
})()();
var constructBarlowTConsRecor17 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "autoscroll";
    }
})()();
var constructBarlowTConsRecor18 = /* #__PURE__ */ Data_Lens_Barlow_Construction.constructBarlowTConsRecor({
    reflectSymbol: function () {
        return "activeMenu";
    }
})()();
var ViewMode = /* #__PURE__ */ (function () {
    function ViewMode() {

    };
    ViewMode.value = new ViewMode();
    return ViewMode;
})();
var EditMode = /* #__PURE__ */ (function () {
    function EditMode() {

    };
    EditMode.value = new EditMode();
    return EditMode;
})();
var SearchMode = /* #__PURE__ */ (function () {
    function SearchMode() {

    };
    SearchMode.value = new SearchMode();
    return SearchMode;
})();
var NoMenu = /* #__PURE__ */ (function () {
    function NoMenu() {

    };
    NoMenu.value = new NoMenu();
    return NoMenu;
})();
var FileMenu = /* #__PURE__ */ (function () {
    function FileMenu() {

    };
    FileMenu.value = new FileMenu();
    return FileMenu;
})();
var SettingsMenu = /* #__PURE__ */ (function () {
    function SettingsMenu() {

    };
    SettingsMenu.value = new SettingsMenu();
    return SettingsMenu;
})();
var State = function (x) {
    return x;
};
var newtypeState_ = {
    Coercible0: function () {
        return undefined;
    }
};
var eqMode = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ViewMode && y instanceof ViewMode) {
                return true;
            };
            if (x instanceof EditMode && y instanceof EditMode) {
                return true;
            };
            if (x instanceof SearchMode && y instanceof SearchMode) {
                return true;
            };
            return false;
        };
    }
};
var eqActiveMenu = {
    eq: function (x) {
        return function (y) {
            if (x instanceof NoMenu && y instanceof NoMenu) {
                return true;
            };
            if (x instanceof FileMenu && y instanceof FileMenu) {
                return true;
            };
            if (x instanceof SettingsMenu && y instanceof SettingsMenu) {
                return true;
            };
            return false;
        };
    }
};
var viewState = function (dictMonadState) {
    var Monad0 = dictMonadState.Monad0();
    var bind = Control_Bind.bind(Monad0.Bind1());
    var get = Control_Monad_State_Class.get(dictMonadState);
    var pure = Control_Applicative.pure(Monad0.Applicative0());
    return function (_key) {
        var _key1 = _key(Data_Lens_Internal_Forget.strongForget);
        return bind(get)(function (state) {
            return pure(Data_Lens_Getter.view(_key1)(state));
        });
    };
};
var setState = function (dictMonadState) {
    var bind = Control_Bind.bind((dictMonadState.Monad0()).Bind1());
    var get = Control_Monad_State_Class.get(dictMonadState);
    var put = Control_Monad_State_Class.put(dictMonadState);
    return function (_key) {
        var _key1 = _key(Data_Profunctor_Strong.strongFn);
        return function (value) {
            return bind(get)(function (state) {
                return put(Data_Lens_Setter.set(_key1)(value)(state));
            });
        };
    };
};
var overState = function (dictMonadState) {
    var bind = Control_Bind.bind((dictMonadState.Monad0()).Bind1());
    var get = Control_Monad_State_Class.get(dictMonadState);
    var put = Control_Monad_State_Class.put(dictMonadState);
    return function (_key) {
        var _key1 = _key(Data_Profunctor_Strong.strongFn);
        return function (f) {
            return bind(get)(function (state) {
                return put(Data_Lens_Setter.over(_key1)(f)(state));
            });
        };
    };
};
var localStorageBooleanCache = function (localStorageKey) {
    return function (_key) {
        return function (dictMonadAff) {
            var liftEffect = Effect_Class.liftEffect(dictMonadAff.MonadEffect0());
            return function (dictMonadState) {
                return {
                    entry: _key,
                    flush: new Cache.Flush(function (value) {
                        return liftEffect(LocalStorage.setLocalStorageBoolean(localStorageKey)(value));
                    }),
                    fetch: new Cache.Fetch(liftEffect(LocalStorage.getLocalStorageBoolean(localStorageKey)))
                };
            };
        };
    };
};
var initialState = function (v) {
    return {
        mode: EditMode.value,
        activeMenu: NoMenu.value,
        loading: false,
        scrollTop: 0.0,
        searchPhrase: Data_Maybe.Nothing.value,
        searchResults: Data_Maybe.Nothing.value,
        autoscroll: false,
        autoscrollTimer: Data_Maybe.Nothing.value,
        autoscrollSpeed: AutoscrollSpeed.Normal.value,
        tablatureText: Cache.buildCache(""),
        parseResult: Cache.buildCache(Data_List_Types.Nil.value),
        rewriteResult: Cache.buildCache(Data_List_Types.Nil.value),
        tablatureTitle: Cache.buildCache("Tab viewer"),
        tabNormalizationEnabled: Cache.buildCache(true),
        tabDozenalizationEnabled: Cache.buildCache(false),
        chordNormalizationEnabled: Cache.buildCache(false),
        chordDozenalizationEnabled: Cache.buildCache(false),
        upperCaseNotes: Cache.buildCache(true),
        ignoreDozenalization: Cache.buildCache(false),
        urlParams: Cache.buildCache({
            transposition: 0,
            noteOrientation: TablatureRewriter.Default.value
        })
    };
};
var _urlParams = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var urlParamsCache = function (dictMonadAff) {
    var liftEffect = Effect_Class.liftEffect(dictMonadAff.MonadEffect0());
    return function (dictMonadState) {
        return {
            entry: _urlParams,
            flush: new Cache.Flush(function (value) {
                return liftEffect(AppUrl.setAppQueryString(value));
            }),
            fetch: new Cache.Fetch(liftEffect(mapFlipped(AppUrl.getAppUrlParams)(Data_Maybe.Just.create)))
        };
    };
};
var _tablatureTitle = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor1(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var _tablatureText = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor2(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var tablatureTextCache = function (dictMonadAff) {
    var MonadEffect0 = dictMonadAff.MonadEffect0();
    var liftEffect = Effect_Class.liftEffect(MonadEffect0);
    var Monad0 = MonadEffect0.Monad0();
    var bind = Control_Bind.bind(Monad0.Bind1());
    var pure = Control_Applicative.pure(Monad0.Applicative0());
    return function (dictMonadState) {
        return {
            entry: _tablatureText,
            flush: new Cache.Flush(function (value) {
                return liftEffect(AppUrl.saveTablatureToUrl(value));
            }),
            fetch: new Cache.Fetch(bind(liftEffect(AppUrl.getTablatureTextFromUrl))(function (text) {
                return pure(new Data_Maybe.Just(text));
            }))
        };
    };
};
var _tabNormalizationEnabled = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor3(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var tabNormalizationEnabledCache = function (dictMonadAff) {
    return function (dictMonadState) {
        return localStorageBooleanCache("tabNormalizationEnabled")(_tabNormalizationEnabled)(dictMonadAff)(dictMonadState);
    };
};
var _tabDozenalizationEnabled = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor4(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var tabDozenalizationEnabledCache = function (dictMonadAff) {
    return function (dictMonadState) {
        return localStorageBooleanCache("tabDozenalizationEnabled")(_tabDozenalizationEnabled)(dictMonadAff)(dictMonadState);
    };
};
var _searchResults = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor5(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var _searchPhrase = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor6(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var _scrollTop = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor7(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var _rewriteResult = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor8(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var _parseResult = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor9(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var parseResultCache = function (dictMonadAff) {
    var Monad0 = (dictMonadAff.MonadEffect0()).Monad0();
    var bind = Control_Bind.bind(Monad0.Bind1());
    var tablatureTextCache1 = tablatureTextCache(dictMonadAff);
    var pure = Control_Applicative.pure(Monad0.Applicative0());
    return function (dictMonadState) {
        return {
            entry: _parseResult,
            fetch: new Cache.Fetch(bind(Cache.subscribe(dictMonadState)(_parseResult)(tablatureTextCache1(dictMonadState)))(function (tablatureText) {
                return pure(TablatureParser.tryParseTablature(tablatureText));
            }))
        };
    };
};
var tablatureTitleCache = function (dictMonadAff) {
    var Monad0 = (dictMonadAff.MonadEffect0()).Monad0();
    var bind = Control_Bind.bind(Monad0.Bind1());
    var parseResultCache1 = parseResultCache(dictMonadAff);
    var pure = Control_Applicative.pure(Monad0.Applicative0());
    return function (dictMonadState) {
        return {
            entry: _tablatureTitle,
            fetch: new Cache.Fetch(bind(Cache.subscribe(dictMonadState)(_tablatureTitle)(parseResultCache1(dictMonadState)))(function (parseResult) {
                return pure(TablatureDocument.getTitle(parseResult));
            }))
        };
    };
};
var _mode = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor10(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var _loading = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor11(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var _ignoreDozenalization = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor12(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var ignoreDozenalizationCache = function (dictMonadAff) {
    var Monad0 = (dictMonadAff.MonadEffect0()).Monad0();
    var bind = Control_Bind.bind(Monad0.Bind1());
    var tablatureTitleCache1 = tablatureTitleCache(dictMonadAff);
    var pure = Control_Applicative.pure(Monad0.Applicative0());
    return function (dictMonadState) {
        return {
            entry: _ignoreDozenalization,
            fetch: new Cache.Fetch(bind(Cache.subscribe(dictMonadState)(_ignoreDozenalization)(tablatureTitleCache1(dictMonadState)))(function (tablatureTitle) {
                return pure(new Data_Maybe.Just(Data_String_Regex.test(Data_String_Regex_Unsafe.unsafeRegex("dozenal")(Data_String_Regex_Flags.ignoreCase))(tablatureTitle)));
            }))
        };
    };
};
var _chordNormalizationEnabled = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor13(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var chordNormalizationEnabledCache = function (dictMonadAff) {
    return function (dictMonadState) {
        return localStorageBooleanCache("chordNormalizationEnabled")(_chordNormalizationEnabled)(dictMonadAff)(dictMonadState);
    };
};
var _chordDozenalizationEnabled = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor14(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var chordDozenalizationEnabledCache = function (dictMonadAff) {
    return function (dictMonadState) {
        return localStorageBooleanCache("chordDozenalizationEnabled")(_chordDozenalizationEnabled)(dictMonadAff)(dictMonadState);
    };
};
var rewriteResultCache = function (dictMonadAff) {
    var Monad0 = (dictMonadAff.MonadEffect0()).Monad0();
    var bind = Control_Bind.bind(Monad0.Bind1());
    var parseResultCache1 = parseResultCache(dictMonadAff);
    var tabNormalizationEnabledCache1 = tabNormalizationEnabledCache(dictMonadAff);
    var tabDozenalizationEnabledCache1 = tabDozenalizationEnabledCache(dictMonadAff);
    var chordNormalizationEnabledCache1 = chordNormalizationEnabledCache(dictMonadAff);
    var chordDozenalizationEnabledCache1 = chordDozenalizationEnabledCache(dictMonadAff);
    var ignoreDozenalizationCache1 = ignoreDozenalizationCache(dictMonadAff);
    var urlParamsCache1 = urlParamsCache(dictMonadAff);
    var pure = Control_Applicative.pure(Monad0.Applicative0());
    return function (dictMonadState) {
        var subscribe = Cache.subscribe(dictMonadState);
        var tabNormalizationEnabledCache2 = tabNormalizationEnabledCache1(dictMonadState);
        var tabDozenalizationEnabledCache2 = tabDozenalizationEnabledCache1(dictMonadState);
        var chordNormalizationEnabledCache2 = chordNormalizationEnabledCache1(dictMonadState);
        var chordDozenalizationEnabledCache2 = chordDozenalizationEnabledCache1(dictMonadState);
        var ignoreDozenalizationCache2 = ignoreDozenalizationCache1(dictMonadState);
        var urlParamsCache2 = urlParamsCache1(dictMonadState);
        return {
            entry: _rewriteResult,
            fetch: new Cache.Fetch(bind(subscribe(_rewriteResult)(parseResultCache1(dictMonadState)))(function (parseResult) {
                return bind(subscribe(_rewriteResult)(tabNormalizationEnabledCache2))(function (tabNormalizationEnabled) {
                    return bind(subscribe(_rewriteResult)(tabDozenalizationEnabledCache2))(function (tabDozenalizationEnabled) {
                        return bind(subscribe(_rewriteResult)(chordNormalizationEnabledCache2))(function (chordNormalizationEnabled) {
                            return bind(subscribe(_rewriteResult)(chordDozenalizationEnabledCache2))(function (chordDozenalizationEnabled) {
                                return bind(subscribe(_rewriteResult)(ignoreDozenalizationCache2))(function (ignoreDozenalization) {
                                    return bind(subscribe(_rewriteResult)(urlParamsCache2))(function (urlParams) {
                                        return bind(pure({
                                            dozenalizeTabs: tabDozenalizationEnabled && !ignoreDozenalization,
                                            dozenalizeChords: chordDozenalizationEnabled && !ignoreDozenalization,
                                            normalizeTabs: tabNormalizationEnabled,
                                            normalizeChords: chordNormalizationEnabled,
                                            transposition: urlParams.transposition,
                                            noteOrientation: urlParams.noteOrientation
                                        }))(function (renderingOptions) {
                                            return pure(new Data_Maybe.Just(TablatureRewriter.rewriteTablatureDocument(renderingOptions)(parseResult)));
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            }))
        };
    };
};
var _autoscrollTimer = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor15(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var _autoscrollSpeed = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor16(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var _autoscroll = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor17(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
var _activeMenu = function (dictStrong) {
    return Data_Lens_Barlow.barlow(barlowInstance(Data_Lens_Barlow_Construction.constructBarlowTConsExcla(constructBarlowTConsRecor18(dictStrong))()(dictStrong.Profunctor0())))(Data_Lens_Barlow.key);
};
export {
    ViewMode,
    EditMode,
    SearchMode,
    NoMenu,
    FileMenu,
    SettingsMenu,
    State,
    initialState,
    _mode,
    _activeMenu,
    _loading,
    _scrollTop,
    _searchPhrase,
    _searchResults,
    _autoscroll,
    _autoscrollTimer,
    _autoscrollSpeed,
    viewState,
    setState,
    overState,
    urlParamsCache,
    _urlParams,
    tablatureTextCache,
    _tablatureText,
    parseResultCache,
    _parseResult,
    rewriteResultCache,
    _rewriteResult,
    tablatureTitleCache,
    _tablatureTitle,
    localStorageBooleanCache,
    tabNormalizationEnabledCache,
    _tabNormalizationEnabled,
    tabDozenalizationEnabledCache,
    _tabDozenalizationEnabled,
    chordDozenalizationEnabledCache,
    _chordDozenalizationEnabled,
    chordNormalizationEnabledCache,
    _chordNormalizationEnabled,
    ignoreDozenalizationCache,
    _ignoreDozenalization,
    eqMode,
    eqActiveMenu,
    newtypeState_
};
