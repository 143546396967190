// Generated by purs version 0.15.7
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_MediaType from "../Data.MediaType/index.js";
import * as Data_Newtype from "../Data.Newtype/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Ordering from "../Data.Ordering/index.js";
import * as Data_Show from "../Data.Show/index.js";
var unwrap = /* #__PURE__ */ Data_Newtype.unwrap();
var show = /* #__PURE__ */ Data_Show.show(Data_MediaType.showMediaType);
var show1 = /* #__PURE__ */ Data_Show.show(Data_Show.showString);
var eq = /* #__PURE__ */ Data_Eq.eq(Data_MediaType.eqMediaType);
var compare = /* #__PURE__ */ Data_Ord.compare(Data_MediaType.ordMediaType);
var compare1 = /* #__PURE__ */ Data_Ord.compare(Data_Ord.ordString);
var Accept = /* #__PURE__ */ (function () {
    function Accept(value0) {
        this.value0 = value0;
    };
    Accept.create = function (value0) {
        return new Accept(value0);
    };
    return Accept;
})();
var ContentType = /* #__PURE__ */ (function () {
    function ContentType(value0) {
        this.value0 = value0;
    };
    ContentType.create = function (value0) {
        return new ContentType(value0);
    };
    return ContentType;
})();
var RequestHeader = /* #__PURE__ */ (function () {
    function RequestHeader(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    RequestHeader.create = function (value0) {
        return function (value1) {
            return new RequestHeader(value0, value1);
        };
    };
    return RequestHeader;
})();
var value = function (v) {
    if (v instanceof Accept) {
        return unwrap(v.value0);
    };
    if (v instanceof ContentType) {
        return unwrap(v.value0);
    };
    if (v instanceof RequestHeader) {
        return v.value1;
    };
    throw new Error("Failed pattern match at Affjax.RequestHeader (line 26, column 1 - line 26, column 33): " + [ v.constructor.name ]);
};
var showRequestHeader = {
    show: function (v) {
        if (v instanceof Accept) {
            return "(Accept " + (show(v.value0) + ")");
        };
        if (v instanceof ContentType) {
            return "(ContentType " + (show(v.value0) + ")");
        };
        if (v instanceof RequestHeader) {
            return "(RequestHeader " + (show1(v.value0) + (" " + (show1(v.value1) + ")")));
        };
        throw new Error("Failed pattern match at Affjax.RequestHeader (line 16, column 1 - line 19, column 81): " + [ v.constructor.name ]);
    }
};
var name = function (v) {
    if (v instanceof Accept) {
        return "Accept";
    };
    if (v instanceof ContentType) {
        return "Content-Type";
    };
    if (v instanceof RequestHeader) {
        return v.value0;
    };
    throw new Error("Failed pattern match at Affjax.RequestHeader (line 21, column 1 - line 21, column 32): " + [ v.constructor.name ]);
};
var eqRequestHeader = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Accept && y instanceof Accept) {
                return eq(x.value0)(y.value0);
            };
            if (x instanceof ContentType && y instanceof ContentType) {
                return eq(x.value0)(y.value0);
            };
            if (x instanceof RequestHeader && y instanceof RequestHeader) {
                return x.value0 === y.value0 && x.value1 === y.value1;
            };
            return false;
        };
    }
};
var ordRequestHeader = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Accept && y instanceof Accept) {
                return compare(x.value0)(y.value0);
            };
            if (x instanceof Accept) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Accept) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ContentType && y instanceof ContentType) {
                return compare(x.value0)(y.value0);
            };
            if (x instanceof ContentType) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ContentType) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof RequestHeader && y instanceof RequestHeader) {
                var v = compare1(x.value0)(y.value0);
                if (v instanceof Data_Ordering.LT) {
                    return Data_Ordering.LT.value;
                };
                if (v instanceof Data_Ordering.GT) {
                    return Data_Ordering.GT.value;
                };
                return compare1(x.value1)(y.value1);
            };
            throw new Error("Failed pattern match at Affjax.RequestHeader (line 0, column 0 - line 0, column 0): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqRequestHeader;
    }
};
export {
    Accept,
    ContentType,
    RequestHeader,
    name,
    value,
    eqRequestHeader,
    ordRequestHeader,
    showRequestHeader
};
