// Generated by purs version 0.15.7
import * as Control_Category from "../Control.Category/index.js";
import * as Data_Eq from "../Data.Eq/index.js";
import * as Data_Function from "../Data.Function/index.js";
import * as Data_Functor from "../Data.Functor/index.js";
import * as Data_HeytingAlgebra from "../Data.HeytingAlgebra/index.js";
import * as Data_Int from "../Data.Int/index.js";
import * as Data_Lens_Fold from "../Data.Lens.Fold/index.js";
import * as Data_Lens_Getter from "../Data.Lens.Getter/index.js";
import * as Data_Lens_Internal_Forget from "../Data.Lens.Internal.Forget/index.js";
import * as Data_Lens_Internal_Wander from "../Data.Lens.Internal.Wander/index.js";
import * as Data_Lens_Prism_Maybe from "../Data.Lens.Prism.Maybe/index.js";
import * as Data_Lens_Setter from "../Data.Lens.Setter/index.js";
import * as Data_Lens_Traversal from "../Data.Lens.Traversal/index.js";
import * as Data_List from "../Data.List/index.js";
import * as Data_List_Types from "../Data.List.Types/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Monoid_Additive from "../Data.Monoid.Additive/index.js";
import * as Data_Monoid_Disj from "../Data.Monoid.Disj/index.js";
import * as Data_Ord from "../Data.Ord/index.js";
import * as Data_Profunctor_Choice from "../Data.Profunctor.Choice/index.js";
import * as Data_Profunctor_Strong from "../Data.Profunctor.Strong/index.js";
import * as Data_Ring from "../Data.Ring/index.js";
import * as Data_Semiring from "../Data.Semiring/index.js";
import * as Data_Show from "../Data.Show/index.js";
import * as Data_String_CodeUnits from "../Data.String.CodeUnits/index.js";
import * as Data_String_Common from "../Data.String.Common/index.js";
import * as Data_String_Utils from "../Data.String.Utils/index.js";
import * as Data_Tuple from "../Data.Tuple/index.js";
import * as TablatureDocument from "../TablatureDocument/index.js";
import * as Utils from "../Utils/index.js";
var print = /* #__PURE__ */ Utils.print(TablatureDocument.printChord);
var print1 = /* #__PURE__ */ Utils.print(/* #__PURE__ */ TablatureDocument.printSpaced(TablatureDocument.printChord));
var anyOf = /* #__PURE__ */ Data_Lens_Fold.anyOf(Data_HeytingAlgebra.heytingAlgebraBoolean);
var monoidDisj = /* #__PURE__ */ Data_Monoid_Disj.monoidDisj(Data_HeytingAlgebra.heytingAlgebraBoolean);
var _TextLine = /* #__PURE__ */ TablatureDocument["_TextLine"](/* #__PURE__ */ Data_Lens_Internal_Forget.choiceForget(monoidDisj));
var traversed = /* #__PURE__ */ Data_Lens_Traversal.traversed(Data_List_Types.traversableList);
var traversed1 = /* #__PURE__ */ traversed(/* #__PURE__ */ Data_Lens_Internal_Forget.wanderForget(monoidDisj));
var monoidAdditive = /* #__PURE__ */ Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringInt);
var choiceForget = /* #__PURE__ */ Data_Lens_Internal_Forget.choiceForget(monoidAdditive);
var _TextLine1 = /* #__PURE__ */ TablatureDocument["_TextLine"](choiceForget);
var traversed2 = /* #__PURE__ */ traversed(/* #__PURE__ */ Data_Lens_Internal_Forget.wanderForget(monoidAdditive));
var filtered = /* #__PURE__ */ Data_Lens_Fold.filtered(choiceForget);
var _TextLine2 = /* #__PURE__ */ TablatureDocument["_TextLine"](Data_Profunctor_Choice.choiceFn);
var traversed3 = /* #__PURE__ */ traversed(Data_Lens_Internal_Wander.wanderFunction);
var map = /* #__PURE__ */ Data_Functor.map(Data_List_Types.functorList);
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showInt);
var _lowercase = /* #__PURE__ */ TablatureDocument["_lowercase"](Data_Profunctor_Strong.strongFn);
var _mod = /* #__PURE__ */ TablatureDocument["_mod"](Data_Lens_Internal_Forget.strongForget);
var _primitive = /* #__PURE__ */ TablatureDocument["_primitive"](Data_Lens_Internal_Forget.strongForget);
var _primitive1 = /* #__PURE__ */ TablatureDocument["_primitive"](Data_Profunctor_Strong.strongFn);
var _mod1 = /* #__PURE__ */ TablatureDocument["_mod"](Data_Profunctor_Strong.strongFn);
var succ$prime = /* #__PURE__ */ Utils["succ$prime"](TablatureDocument.cyclicEnumNoteLetterPrimi);
var eq1 = /* #__PURE__ */ Data_Eq.eq(TablatureDocument.eqNoteLetterPrimitive);
var pred$prime = /* #__PURE__ */ Utils["pred$prime"](TablatureDocument.cyclicEnumNoteLetterPrimi);
var applyUntilIdempotent = /* #__PURE__ */ Utils.applyUntilIdempotent(TablatureDocument.eqNote);
var identity = /* #__PURE__ */ Control_Category.identity(Control_Category.categoryFn);
var abs = /* #__PURE__ */ Data_Ord.abs(Data_Ord.ordInt)(Data_Ring.ringInt);
var _TablatureLine = /* #__PURE__ */ TablatureDocument["_TablatureLine"](Data_Profunctor_Choice.choiceFn);
var _Tuning = /* #__PURE__ */ TablatureDocument["_Tuning"](Data_Profunctor_Choice.choiceFn);
var _TextLineChord = /* #__PURE__ */ TablatureDocument["_TextLineChord"](Data_Profunctor_Choice.choiceFn);
var _ChordLine = /* #__PURE__ */ TablatureDocument["_ChordLine"](Data_Profunctor_Choice.choiceFn);
var _ChordLineChord = /* #__PURE__ */ TablatureDocument["_ChordLineChord"](Data_Profunctor_Choice.choiceFn);
var _root = /* #__PURE__ */ TablatureDocument["_root"](Data_Profunctor_Strong.strongFn);
var _bass = /* #__PURE__ */ TablatureDocument["_bass"](Data_Profunctor_Strong.strongFn);
var _Just = /* #__PURE__ */ Data_Lens_Prism_Maybe["_Just"](Data_Profunctor_Choice.choiceFn);
var notEq = /* #__PURE__ */ Data_Eq.notEq(/* #__PURE__ */ Data_Maybe.eqMaybe(Data_Eq.eqChar));
var Flat = /* #__PURE__ */ (function () {
    function Flat() {

    };
    Flat.value = new Flat();
    return Flat;
})();
var Sharp = /* #__PURE__ */ (function () {
    function Sharp() {

    };
    Sharp.value = new Sharp();
    return Sharp;
})();
var Default = /* #__PURE__ */ (function () {
    function Default() {

    };
    Default.value = new Default();
    return Default;
})();
var eqNoteOrientation = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Flat && y instanceof Flat) {
                return true;
            };
            if (x instanceof Sharp && y instanceof Sharp) {
                return true;
            };
            if (x instanceof Default && y instanceof Default) {
                return true;
            };
            return false;
        };
    }
};
var revertFalsePositiveChords = /* #__PURE__ */ (function () {
    var isPotentiallyAmbiguousChord = function (v) {
        if (v instanceof TablatureDocument.TextLineChord) {
            return Utils.unsafeTestRegex("^([Aa][Mm]?|[DdGg]o|[a-z]|Bob)$")(print(v.value0.elem));
        };
        return false;
    };
    var isUnambiguousChord = function (elem) {
        if (elem instanceof TablatureDocument.TextLineChord) {
            return !isPotentiallyAmbiguousChord(elem);
        };
        return false;
    };
    var rewriteElement = function (v) {
        if (v instanceof TablatureDocument.TextLineChord) {
            var $89 = isPotentiallyAmbiguousChord(v);
            if ($89) {
                return new TablatureDocument.Text(print1(v.value0));
            };
            return v;
        };
        return v;
    };
    var isChordLegend = function (v) {
        if (v instanceof TablatureDocument.ChordLegend) {
            return true;
        };
        return false;
    };
    var containsChordLegend = function (line) {
        return anyOf(function ($140) {
            return _TextLine(traversed1($140));
        })(isChordLegend)(line);
    };
    var containsAtLeastOneUnambiguousChord = function (line) {
        return anyOf(function ($141) {
            return _TextLine(traversed1($141));
        })(isUnambiguousChord)(line);
    };
    var containsAtLeastFourAmbiguousChords = function (line) {
        return Data_Lens_Fold.lengthOf((function () {
            var $142 = filtered(isPotentiallyAmbiguousChord);
            return function ($143) {
                return _TextLine1(traversed2($142($143)));
            };
        })())(line) > 3;
    };
    var rewriteLine = function (line) {
        var $93 = containsChordLegend(line) || (containsAtLeastFourAmbiguousChords(line) || containsAtLeastOneUnambiguousChord(line));
        if ($93) {
            return line;
        };
        return Data_Lens_Setter.over(function ($144) {
            return _TextLine2(traversed3($144));
        })(rewriteElement)(line);
    };
    return map(rewriteLine);
})();
var liftMappingSpaced = function (dictPrint) {
    var print2 = Utils.print(dictPrint);
    return function (mapping) {
        return function (v) {
            var newElem = mapping(v.elem);
            var newSuffix = (v.spaceSuffix + Data_String_CodeUnits.length(print2(v.elem)) | 0) - Data_String_CodeUnits.length(print2(newElem)) | 0;
            return {
                elem: newElem,
                spaceSuffix: newSuffix
            };
        };
    };
};
var liftMappingSpaced1 = /* #__PURE__ */ liftMappingSpaced(TablatureDocument.printNote);
var liftMappingSpaced2 = /* #__PURE__ */ liftMappingSpaced(TablatureDocument.printChord);
var fixTimeLine = function (settings) {
    return function (doc) {
        var rewriteTablatureLineElem = function (v) {
            if (v instanceof TablatureDocument.TimelineConnection) {
                return new TablatureDocument.TimelineConnection(Data_String_Common.replaceAll("\u2014")("-")(Data_String_Common.replaceAll(" ")("-")(v.value0)));
            };
            if (v instanceof TablatureDocument.TimelineSep) {
                return new TablatureDocument.TimelineSep(Data_String_Common.replaceAll("[")("|")(Data_String_Common.replaceAll("]")("|")(v.value0)));
            };
            return v;
        };
        var rewriteLine = function (v) {
            if (v instanceof TablatureDocument.TablatureLine) {
                return new TablatureDocument.TablatureLine(map(rewriteTablatureLineElem)(v.value0));
            };
            return v;
        };
        var $101 = !settings.normalizeTabs;
        if ($101) {
            return doc;
        };
        return map(rewriteLine)(doc);
    };
};
var dozenalizeFrets = function (settings) {
    return function (doc) {
        var toDozenalString = function (s) {
            var v = Data_Int.fromString(s);
            if (v instanceof Data_Maybe.Nothing) {
                return s;
            };
            if (v instanceof Data_Maybe.Just) {
                var dozenal = Data_Maybe.fromMaybe(Data_Int.decimal)(Data_Int.radix(12));
                var $103 = v.value0 > 25;
                if ($103) {
                    return show(v.value0);
                };
                return Data_String_Common.replaceAll("b")("\u218b")(Data_String_Common.replaceAll("a")("\u218a")(Data_Int.toStringAs(dozenal)(v.value0)));
            };
            throw new Error("Failed pattern match at TablatureRewriter (line 259, column 5 - line 268, column 47): " + [ v.constructor.name ]);
        };
        var rewriteTablatureLineElems = function (elems) {
            return Utils.foreach(0)(elems)(function (pendingDashes) {
                return function (elem) {
                    if (elem instanceof TablatureDocument.TimelineConnection) {
                        return new Data_Tuple.Tuple(0, new TablatureDocument.TimelineConnection(Data_Maybe.fromMaybe("")(Data_String_Utils.repeat(pendingDashes)("-")) + elem.value0));
                    };
                    if (elem instanceof TablatureDocument.Fret) {
                        var fretString = toDozenalString(elem.value0);
                        return Data_Function.flip(Data_Tuple.Tuple.create)(new TablatureDocument.Fret(fretString))((function () {
                            var $107 = fretString === "\u218a" || fretString === "\u218b";
                            if ($107) {
                                return pendingDashes + 1 | 0;
                            };
                            return pendingDashes;
                        })());
                    };
                    return new Data_Tuple.Tuple(pendingDashes, elem);
                };
            });
        };
        var rewriteLine = function (v) {
            if (v instanceof TablatureDocument.TablatureLine) {
                return new TablatureDocument.TablatureLine(rewriteTablatureLineElems(v.value0));
            };
            return v;
        };
        var $111 = !settings.dozenalizeTabs;
        if ($111) {
            return doc;
        };
        return map(rewriteLine)(doc);
    };
};
var canonicalizeNote = function (settings) {
    var toUpperCase = function (note) {
        return Data_Lens_Setter.set(_lowercase)(false)(note);
    };
    var toPreferredOrientation = function (note) {
        if (settings.noteOrientation instanceof Default) {
            var v = Data_Lens_Getter.view(_mod)(note);
            if (v === "#") {
                var v1 = Data_Lens_Getter.view(_primitive)(note);
                if (v1 instanceof TablatureDocument.A) {
                    return Data_Lens_Setter.set(_primitive1)(TablatureDocument.B.value)(Data_Lens_Setter.set(_mod1)("b")(note));
                };
                if (v1 instanceof TablatureDocument.D) {
                    return Data_Lens_Setter.set(_primitive1)(TablatureDocument.E.value)(Data_Lens_Setter.set(_mod1)("b")(note));
                };
                if (v1 instanceof TablatureDocument.G) {
                    return Data_Lens_Setter.set(_primitive1)(TablatureDocument.A.value)(Data_Lens_Setter.set(_mod1)("b")(note));
                };
                return note;
            };
            if (v === "b") {
                var v1 = Data_Lens_Getter.view(_primitive)(note);
                if (v1 instanceof TablatureDocument.D) {
                    return Data_Lens_Setter.set(_primitive1)(TablatureDocument.C.value)(Data_Lens_Setter.set(_mod1)("#")(note));
                };
                if (v1 instanceof TablatureDocument.G) {
                    return Data_Lens_Setter.set(_primitive1)(TablatureDocument.F.value)(Data_Lens_Setter.set(_mod1)("#")(note));
                };
                return note;
            };
            return note;
        };
        if (settings.noteOrientation instanceof Flat) {
            var v = Data_Lens_Getter.view(_mod)(note);
            if (v === "#") {
                var v1 = Data_Lens_Getter.view(_primitive)(note);
                if (v1 instanceof TablatureDocument.A) {
                    return Data_Lens_Setter.set(_primitive1)(TablatureDocument.B.value)(Data_Lens_Setter.set(_mod1)("b")(note));
                };
                if (v1 instanceof TablatureDocument.C) {
                    return Data_Lens_Setter.set(_primitive1)(TablatureDocument.D.value)(Data_Lens_Setter.set(_mod1)("b")(note));
                };
                if (v1 instanceof TablatureDocument.D) {
                    return Data_Lens_Setter.set(_primitive1)(TablatureDocument.E.value)(Data_Lens_Setter.set(_mod1)("b")(note));
                };
                if (v1 instanceof TablatureDocument.F) {
                    return Data_Lens_Setter.set(_primitive1)(TablatureDocument.G.value)(Data_Lens_Setter.set(_mod1)("b")(note));
                };
                if (v1 instanceof TablatureDocument.G) {
                    return Data_Lens_Setter.set(_primitive1)(TablatureDocument.A.value)(Data_Lens_Setter.set(_mod1)("b")(note));
                };
                return note;
            };
            return note;
        };
        if (settings.noteOrientation instanceof Sharp) {
            var v = Data_Lens_Getter.view(_mod)(note);
            if (v === "b") {
                var v1 = Data_Lens_Getter.view(_primitive)(note);
                if (v1 instanceof TablatureDocument.A) {
                    return Data_Lens_Setter.set(_primitive1)(TablatureDocument.G.value)(Data_Lens_Setter.set(_mod1)("#")(note));
                };
                if (v1 instanceof TablatureDocument.B) {
                    return Data_Lens_Setter.set(_primitive1)(TablatureDocument.A.value)(Data_Lens_Setter.set(_mod1)("#")(note));
                };
                if (v1 instanceof TablatureDocument.D) {
                    return Data_Lens_Setter.set(_primitive1)(TablatureDocument.C.value)(Data_Lens_Setter.set(_mod1)("#")(note));
                };
                if (v1 instanceof TablatureDocument.E) {
                    return Data_Lens_Setter.set(_primitive1)(TablatureDocument.D.value)(Data_Lens_Setter.set(_mod1)("#")(note));
                };
                if (v1 instanceof TablatureDocument.G) {
                    return Data_Lens_Setter.set(_primitive1)(TablatureDocument.F.value)(Data_Lens_Setter.set(_mod1)("#")(note));
                };
                return note;
            };
            return note;
        };
        throw new Error("Failed pattern match at TablatureRewriter (line 147, column 5 - line 179, column 20): " + [ settings.noteOrientation.constructor.name ]);
    };
    var reduceSharps = function (note) {
        var substitute = function (p) {
            var v = Data_String_CodeUnits.stripPrefix(p)(Data_Lens_Getter.view(_mod)(note));
            if (v instanceof Data_Maybe.Nothing) {
                return note;
            };
            if (v instanceof Data_Maybe.Just) {
                return Data_Lens_Setter.over(_primitive1)(succ$prime)(Data_Lens_Setter.set(_mod1)(v.value0)(note));
            };
            throw new Error("Failed pattern match at TablatureRewriter (line 137, column 20 - line 139, column 68): " + [ v.constructor.name ]);
        };
        var $122 = eq1(Data_Lens_Getter.view(_primitive)(note))(TablatureDocument.B.value) || eq1(Data_Lens_Getter.view(_primitive)(note))(TablatureDocument.E.value);
        if ($122) {
            return substitute("#");
        };
        return substitute("##");
    };
    var reduceFlats = function (note) {
        var substitute = function (p) {
            var v = Data_String_CodeUnits.stripPrefix(p)(Data_Lens_Getter.view(_mod)(note));
            if (v instanceof Data_Maybe.Nothing) {
                return note;
            };
            if (v instanceof Data_Maybe.Just) {
                return Data_Lens_Setter.over(_primitive1)(pred$prime)(Data_Lens_Setter.set(_mod1)(v.value0)(note));
            };
            throw new Error("Failed pattern match at TablatureRewriter (line 143, column 20 - line 145, column 68): " + [ v.constructor.name ]);
        };
        var $125 = eq1(Data_Lens_Getter.view(_primitive)(note))(TablatureDocument.C.value) || eq1(Data_Lens_Getter.view(_primitive)(note))(TablatureDocument.F.value);
        if ($125) {
            return substitute("b");
        };
        return substitute("bb");
    };
    var collapseRedundants = function (note) {
        return Data_Lens_Setter.over(_mod1)((function () {
            var $145 = Data_String_Common.replace("b#")("");
            var $146 = Data_String_Common.replace("#b")("");
            return function ($147) {
                return $145($146($147));
            };
        })())(note);
    };
    var $148 = applyUntilIdempotent(reduceFlats);
    var $149 = applyUntilIdempotent(reduceSharps);
    var $150 = applyUntilIdempotent(collapseRedundants);
    return function ($151) {
        return toUpperCase(toPreferredOrientation($148($149($150($151)))));
    };
};
var transposeNote = function (settings) {
    var appendSuffix = function (suffix) {
        return Data_Lens_Setter.over(_mod1)(function (v) {
            return v + suffix;
        });
    };
    if (settings.transposition === 0) {
        return identity;
    };
    if (settings.transposition > 0) {
        var $152 = canonicalizeNote(settings);
        var $153 = appendSuffix(Data_Maybe.fromMaybe("")(Data_String_Utils.repeat(settings.transposition)("#")));
        return function ($154) {
            return $152($153($154));
        };
    };
    var $155 = canonicalizeNote(settings);
    var $156 = appendSuffix(Data_Maybe.fromMaybe("")(Data_String_Utils.repeat(abs(settings.transposition))("b")));
    return function ($157) {
        return $155($156($157));
    };
};
var transposeTuning = function (settings) {
    var noteMapping = (function () {
        var $158 = (function () {
            if (settings.normalizeChords) {
                return canonicalizeNote(settings);
            };
            return identity;
        })();
        var $159 = transposeNote(settings);
        return function ($160) {
            return $158($159($160));
        };
    })();
    var rewriteLine = Data_Lens_Setter.over(function ($161) {
        return _TablatureLine(traversed3(_Tuning($161)));
    })(liftMappingSpaced1(noteMapping));
    return map(rewriteLine);
};
var applyChordMapping = function (chordMapping) {
    var mapping = liftMappingSpaced2(chordMapping);
    var rewriteChordsInChordLine = Data_Lens_Setter.over(function ($162) {
        return _TextLine2(traversed3(_TextLineChord($162)));
    })(mapping);
    var rewriteChordsInTextLine = Data_Lens_Setter.over(function ($163) {
        return _ChordLine(traversed3(_ChordLineChord($163)));
    })(mapping);
    return map(function ($164) {
        return rewriteChordsInChordLine(rewriteChordsInTextLine($164));
    });
};
var dozenalizeChords = function (settings) {
    return function (doc) {
        var dozenalize = (function () {
            var $165 = Data_String_Common.replaceAll("13")("11");
            var $166 = Data_String_Common.replaceAll("11")("\u218b");
            return function ($167) {
                return $165($166($167));
            };
        })();
        var rewriteChord = function (v) {
            var newType = dozenalize(v.type);
            var newMods = map(function (v1) {
                return {
                    pre: v1.pre,
                    interval: dozenalize(v1.interval),
                    post: v1.post
                };
            })(v.mods);
            return {
                root: v.root,
                type: newType,
                mods: newMods,
                bass: v.bass
            };
        };
        var $130 = !settings.dozenalizeChords;
        if ($130) {
            return doc;
        };
        return applyChordMapping(rewriteChord)(doc);
    };
};
var transposeChords = function (settings) {
    var noteMapping = (function () {
        var $168 = (function () {
            if (settings.normalizeChords) {
                return canonicalizeNote(settings);
            };
            return identity;
        })();
        var $169 = transposeNote(settings);
        return function ($170) {
            return $168($169($170));
        };
    })();
    var chordMapping = (function () {
        var $171 = Data_Lens_Setter.over(function ($174) {
            return _bass(_Just($174));
        })(noteMapping);
        var $172 = Data_Lens_Setter.over(_root)(noteMapping);
        return function ($173) {
            return $171($172($173));
        };
    })();
    return applyChordMapping(chordMapping);
};
var addMissingClosingPipe = function (settings) {
    return function (doc) {
        var rewriteLastTimelinePiece = function (string) {
            var $132 = notEq(Data_String_CodeUnits.charAt(Data_String_CodeUnits.length(string) - 1 | 0)(string))(new Data_Maybe.Just("|"));
            if ($132) {
                return string + "|";
            };
            return string;
        };
        var rewriteTablatureLine = function (elems) {
            return Data_List.reverse(Utils.foreach(false)(Data_List.reverse(elems))(function (done) {
                return function (elem) {
                    if (elem instanceof TablatureDocument.TimelineSep) {
                        return new Data_Tuple.Tuple(true, elem);
                    };
                    if (elem instanceof TablatureDocument.TimelineConnection) {
                        return new Data_Tuple.Tuple(true, (function () {
                            if (done) {
                                return elem;
                            };
                            return new TablatureDocument.TimelineSep(rewriteLastTimelinePiece(elem.value0));
                        })());
                    };
                    return new Data_Tuple.Tuple(done, elem);
                };
            }));
        };
        var rewriteLine = function (v) {
            if (v instanceof TablatureDocument.TablatureLine) {
                return new TablatureDocument.TablatureLine(rewriteTablatureLine(v.value0));
            };
            return v;
        };
        var $139 = !settings.normalizeTabs;
        if ($139) {
            return doc;
        };
        return map(rewriteLine)(doc);
    };
};
var rewriteTablatureDocument = function (settings) {
    var $175 = transposeTuning(settings);
    var $176 = transposeChords(settings);
    var $177 = dozenalizeFrets(settings);
    var $178 = dozenalizeChords(settings);
    var $179 = addMissingClosingPipe(settings);
    var $180 = fixTimeLine(settings);
    return function ($181) {
        return $175($176($177($178($179($180(revertFalsePositiveChords($181)))))));
    };
};
export {
    Flat,
    Sharp,
    Default,
    rewriteTablatureDocument,
    revertFalsePositiveChords,
    liftMappingSpaced,
    applyChordMapping,
    transposeChords,
    transposeTuning,
    transposeNote,
    canonicalizeNote,
    fixTimeLine,
    addMissingClosingPipe,
    dozenalizeChords,
    dozenalizeFrets,
    eqNoteOrientation
};
