// Generated by purs version 0.15.7
import * as Data_Argonaut_Core from "../Data.Argonaut.Core/index.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Foreign_Object from "../Foreign.Object/index.js";
var string = function (json) {
    return Data_Argonaut_Core.caseJsonString(Data_Maybe.Nothing.value)(Data_Maybe.Just.create)(json);
};
var number = function (json) {
    return Data_Argonaut_Core.caseJsonNumber(Data_Maybe.Nothing.value)(Data_Maybe.Just.create)(json);
};
var child = function (name) {
    return function (json) {
        return Data_Argonaut_Core.caseJsonObject(Data_Maybe.Nothing.value)(Foreign_Object.lookup(name))(json);
    };
};
var array = function (json) {
    return Data_Argonaut_Core.caseJsonArray(Data_Maybe.Nothing.value)(Data_Maybe.Just.create)(json);
};
export {
    child,
    array,
    string,
    number
};
