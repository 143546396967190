// Generated by purs version 0.15.7
import * as $foreign from "./foreign.js";
import * as Data_Maybe from "../Data.Maybe/index.js";
import * as Data_Show from "../Data.Show/index.js";
var show = /* #__PURE__ */ Data_Show.show(Data_Show.showBoolean);
var setLocalStorageBoolean = function (key) {
    return function (value) {
        return $foreign.setLocalStorage(key)(show(value));
    };
};
var getLocalStorageBoolean = function (key) {
    return function __do() {
        var result = $foreign.getLocalStorage(key)();
        if (result === "true") {
            return new Data_Maybe.Just(true);
        };
        if (result === "false") {
            return new Data_Maybe.Just(false);
        };
        return Data_Maybe.Nothing.value;
    };
};
var getLocalStorageBooleanWithDefault = function (key) {
    return function ($$default) {
        return function __do() {
            var result = getLocalStorageBoolean(key)();
            if (result instanceof Data_Maybe.Nothing) {
                return $$default;
            };
            if (result instanceof Data_Maybe.Just) {
                return result.value0;
            };
            throw new Error("Failed pattern match at LocalStorage (line 25, column 3 - line 27, column 27): " + [ result.constructor.name ]);
        };
    };
};
export {
    setLocalStorage,
    getLocalStorage
} from "./foreign.js";
export {
    getLocalStorageBoolean,
    setLocalStorageBoolean,
    getLocalStorageBooleanWithDefault
};
